export const states = {
  notPaid: 'notPaid',
  active: 'active',
  expired: 'expired'
};

export const subscriptionTypes = {
  whatsapp: 'whatsapp',
  waba: 'waba',
  instagram: 'instagram',
  tgapi: 'tgapi',
  telegram: 'telegram',
  vk: 'vk',
  avito: 'avito',
  viber: 'viber'
};

export const subscriptionPaymentsTypes = {
  paidInvalid: 'paidInvalid',
  cashless: 'cashless',
  promissed: 'promissed',
  processing: 'processing',
  stripe: 'stripe',
  deleted: 'deleted',
  partner: 'partner',
  refund: 'refund',
  invalid: 'invalid',
  bonus: 'bonus',
  card: 'card'
};

export const subscriptionTariffs = {
  start: 'start',
  pro: 'pro',
  max: 'max',
  yc_pro: 'yc_pro',
  yc_max: 'yc_max',
  waba: 'waba',
  yc_waba: 'yc_waba',
  free: 'free'
};

export const subscriptionsToAddTypes = [
  subscriptionTypes.whatsapp,
  subscriptionTypes.waba,
  subscriptionTypes.instagram,
  subscriptionTypes.tgapi,
  subscriptionTypes.telegram,
  subscriptionTypes.vk,
  subscriptionTypes.avito,
  subscriptionTypes.viber
];
