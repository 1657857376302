import Vue from 'vue';
import VueI18n from 'vue-i18n';
import dayjs from 'dayjs';

Vue.use(VueI18n);

export default async ({ app, $vuetify }) => {
  const loadLocaleMessages = async (locale) => {
    const messages = await import(/* webpackChunkName: "locale-[request]" */ `~/i18n/${locale}`);
    return messages.default;
  };

  const setLocale = async (locale) => {
    if (!Object.keys(app.i18n.messages).includes(locale)) {
      const messages = await loadLocaleMessages(locale);
      app.i18n.setLocaleMessage(locale, messages);
    }
    app.i18n.locale = locale;
    $vuetify.lang.current = locale;
    dayjs.locale(locale);
    return locale;
  };

  const userLang = (navigator.language || navigator.userLanguage)?.match(/^(ru|es|pt)-?/)?.[1];
  const locale = app.$cookies.get('pll_language', { domain: 'wazzup24.com' }) || userLang || 'en';

  const messages = {
    [locale]: await loadLocaleMessages(locale)
  };

  const pluralizationRules = {
    ru(choice, choicesLength) {
      if (choice === 0) {
        return 0;
      }
      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;
      if (choicesLength < 4) {
        return !teen && endsWithOne ? 1 : 2;
      }
      if (!teen && endsWithOne) {
        return 1;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
      }
      return choicesLength < 4 ? 2 : 3;
    }
  };

  const locales = [
    {
      code: 'ru',
      text: 'Русский (RU)'
    },
    {
      code: 'en',
      text: 'English (EN)'
    },
    {
      code: 'es',
      text: 'Español (ES)'
    },
    {
      code: 'pt',
      text: 'Português (PT)'
    }
  ];

  app.i18n = new VueI18n({
    locale,
    fallbackLocale: 'en',
    pluralizationRules,
    messages
  });

  Object.assign(app.i18n, { locales, setLocale, loadLocaleMessages });

  $vuetify.lang.current = locale;
};
