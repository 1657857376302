import { render, staticRenderFns } from "./ChatHeader.vue?vue&type=template&id=7beda320&scoped=true&"
import script from "./ChatHeader.vue?vue&type=script&lang=js&"
export * from "./ChatHeader.vue?vue&type=script&lang=js&"
import style0 from "./ChatHeader.vue?vue&type=style&index=0&id=7beda320&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7beda320",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseLink: require('/builds/wazzup/front/components/base/BaseLink.vue').default,HeaderDeals: require('/builds/wazzup/front/components/chat/chatHeader/HeaderDeals.vue').default,BaseHelpIcon: require('/builds/wazzup/front/components/base/BaseHelpIcon.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VDivider,VIcon})
