export const convertCurrencies = (sum, currency) => {
  return currency === 'RUR' ? (sum / 40).toFixed(2) : sum.toFixed(2);
};

export const getCurrencySign = (currency) => {
  const signs = {
    USD: '$',
    RUR: '₽',
    EUR: '€',
    KZT: '₸'
  };

  return signs[currency];
};

export const getFormattedSum = ({ sum = 0, currency, locale }) => {
  try {
    const isoCurrency = currency === 'RUR' ? 'RUB' : currency;
    // на португальском некорректное редактирование, исправляем на испанский:
    if (locale === 'pt') locale = 'es';
    if (currency === 'KZT') locale = 'ru-KZ';

    return sum.toLocaleString(locale, {
      style: 'currency',
      currency: isoCurrency,
      minimumFractionDigits: 0
    });
  } catch (err) {
    console.log(err);
    return '';
  }
};
