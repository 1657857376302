import BaseService from '~/services/BaseService';

export default class SubscriptionsService extends BaseService {
  async getSubscriptions() {
    const result = await this.$apiV2.$get('/subscriptions');
    return result;
  }

  async addSubscription(subscription) {
    const result = await this.$apiV2.$post('/subscriptions', subscription);
    return result;
  }

  async editSubscription({ subscriptionId, ...subscriptionData }) {
    const result = await this.$apiV2.$patch(`/subscriptions/${subscriptionId}`, subscriptionData);
    return result;
  }

  async renameSubscription({ subscriptionId, name }) {
    const result = await this.$apiV2.$patch(`/subscriptions/rename/${subscriptionId}`, { name });
    return result;
  }

  async prolongSubscription({ subscriptionId, period, sum }) {
    const result = await this.$apiV2.$patch(`/subscriptions/renewal/${subscriptionId}`, { period, sum });
    return result;
  }

  async replenishTemplateMessages({ subscriptionId, templateMessagesQuantity }) {
    const result = await this.$apiV2.$post(`/subscriptions/templateMessages/${subscriptionId}`, {
      templateMessagesQuantity
    });
    return result;
  }

  async replenishTemplateBalance({ subscriptionId, balanceTopup }) {
    const result = await this.$apiV2.$post(`/subscriptions/balance/${subscriptionId}`, {
      balanceTopup
    });
    return result;
  }

  async manageChannels({ subscriptionId, channels }) {
    const result = await this.$apiV2.$patch(`/subscriptions/channels/${subscriptionId}`, { channels });
    return result;
  }

  async disableAutoRenewal(subscriptionId) {
    const result = await this.$apiV2.$patch(`/subscriptions/disableAutoRenewal/${subscriptionId}`);
    return result;
  }

  async deleteSubscription(subscriptionId) {
    const result = await this.$apiV2.$delete(`/subscriptions/${subscriptionId}`);
    return result;
  }

  async getSubscriptionsConstants() {
    const result = await this.$apiV2.$get('/billing/tariffConstants');
    return result;
  }
}
