import { templateTagsTypes } from '~/enums/templates';

export default {
  async getWazzupTags({ commit }) {
    const tags = await this.$services.templates.getTemplateTags(templateTagsTypes.wazzup);
    commit('SET_WAZZUP_TAGS', tags);
  },

  async getWabaTags({ commit }) {
    const tags = await this.$services.templates.getTemplateTags(templateTagsTypes.waba);
    commit('SET_WABA_TAGS', tags);
  },

  setAutoTemplate({ commit }, template) {
    commit('SET_AUTO_TEMPLATE', template);
  }
};
