//
//
//
//
//
//

export default {
  name: 'BaseLink',

  props: {
    to: {
      type: String,
      default: ''
    },
    href: {
      type: String,
      default: ''
    }
  },

  computed: {
    linkComponent() {
      return this.to ? 'nuxt-link' : 'a';
    },

    linkProps() {
      return { class: this.class, ...(this.to ? { to: this.to } : { href: this.href }) };
    }
  }
};
