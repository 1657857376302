//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions, mapGetters } from 'vuex';
import { intergationTypes } from '~/enums/integrations';
import { deepCopy } from '~/utils/common';

export default {
  name: 'AppRatingDialog',

  props: {
    value: {
      type: Boolean
    }
  },

  data() {
    return {
      success: false,
      activeIndex: 0,
      text: '',
      loading: true,
      sending: false
    };
  },

  computed: {
    ...mapState('integrations', ['integrationInfo']),
    ...mapGetters('auth', ['frontendData']),

    goodRating() {
      return [9, 10].includes(this.activeIndex);
    },

    disabled() {
      if (this.sending) return true;
      if (this.goodRating) {
        return false;
      } else if (this.activeIndex && this.text) {
        return false;
      }
      return true;
    },

    textareaLabel() {
      const index = this.activeIndex;
      if (index > 0 && index < 6) return this.texts.labelBad;
      if (index > 5 && index < 9) return this.texts.labelNormal;
      return this.texts.labelGood;
    },

    crmText() {
      const { amocrm, bitrix, zoho, hubspot, pipedrive } = intergationTypes;
      if ([amocrm, bitrix, zoho, hubspot, pipedrive].includes(this.integrationInfo?.type)) {
        let link = '';
        if (this.integrationInfo.type === amocrm) {
          link = `${this.integrationInfo.domain}/amo-market/#amo_wazzup`;
        } else if (this.integrationInfo.type === bitrix) {
          link = `${this.integrationInfo.domain}/market/detail/wazzup.app/?from=search`;
        } else if (this.integrationInfo.type === zoho) {
          link = 'https://marketplace.zoho.com/vendor/wazzup';
        } else if (this.integrationInfo.type === hubspot) {
          const id = this.integrationInfo.domain.match(/\/(\d+)/);
          link = id[1]
            ? `https://app.hubspot.com/ecosystem/${id[1]}/marketplace/apps/marketing/live-chat/wazzup-216293`
            : 'https://app.hubspot.com/';
        } else {
          link =
            'https://www.pipedrive.com/en/marketplace/app/wazzup-whats-app-instagram-and-telegram/8d137dd6cec902a8#user-reviews';
        }
        return this.$t('settings.ratingDialog.crmText', { link });
      } else {
        return this.texts.crmTextWithoutLink;
      }
    },

    texts() {
      return {
        successTitle: this.$t('settings.ratingDialog.successTitle'),
        successText: this.$t('settings.ratingDialog.successText'),
        close: this.$t('common.close'),
        title: this.$t('settings.ratingDialog.title'),
        hate: this.$t('settings.ratingDialog.hate'),
        love: this.$t('settings.ratingDialog.love'),
        thankYou: this.$t('settings.ratingDialog.thankYou'),
        labelBad: this.$t('settings.ratingDialog.labelBad'),
        labelNormal: this.$t('settings.ratingDialog.labelNormal'),
        labelGood: this.$t('settings.ratingDialog.labelGood'),
        crmTextWithoutLink: this.$t('settings.ratingDialog.crmTextWithoutLink')
      };
    },

    model: {
      set(v) {
        this.$emit('input', v);
      },
      get() {
        return this.value;
      }
    },

    dialogWidth() {
      return this.success ? '560px' : '580px';
    }
  },

  async mounted() {
    try {
      await this.getIntegration();
      this.loading = false;
    } catch (error) {
      console.log(error);
      this.model = false;
    }
  },

  methods: {
    ...mapActions('integrations', ['getIntegration']),
    ...mapActions('auth', ['getCurrentAccount']),

    pickedRating(index) {
      this.activeIndex = index;
    },

    itemClass(index) {
      if (index === 1) return 'rating-item-first';
      if (index > 1 && index < 6) return 'rating-item-red';
      if (index > 5 && index < 9) return 'rating-item-orange';
      if (index === 9) return 'rating-item-green';
      if (index === 10) return 'rating-item-last';
    },

    closeClicked() {
      this.model = false;
    },

    async sendRating() {
      this.sending = true;
      try {
        await this.$services.auth.sendRating({ rating: this.activeIndex, text: this.text });
        const frontendData = deepCopy(this.frontendData);
        frontendData.ratingDialog.isLastActionConfirm = true;
        await this.$services.user.setFrontendData(frontendData);
        await this.getCurrentAccount();
        this.success = true;
      } catch (error) {
        console.log(error);
      }
      this.sending = false;
    }
  }
};
