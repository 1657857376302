export default {
  async getCurrentAccountClients({ commit }) {
    try {
      const resultClients = await this.$services.clients.getClientsNew();
      const { data } = resultClients;
      if (data) {
        commit('SET_CLIENTS', data);
      }
    } catch (err) {
      console.log(err);
    }
  }
};
