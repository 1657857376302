export default {
  SET_INTEGRATION_INFO(state, integrationInfo) {
    state.integrationInfo = integrationInfo || null;
  },

  SET_INTEGRATION_OPTIONS(state, integrationOptions) {
    state.integrationOptions = integrationOptions || null;
  },

  SET_APPLICATIONS(state, applications) {
    state.applications = applications;
  }
};
