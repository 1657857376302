//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mdiClose, mdiContentCopy, mdiCheckCircleOutline, mdiInformationOutline, mdiAlertOutline } from '@mdi/js';

export default {
  name: 'BaseSnackbar',

  props: {
    value: Boolean,
    type: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    timeout: {
      type: Number,
      default: 5000
    },
    description: {
      type: String,
      default: ''
    },
    copyable: {
      type: Boolean
    },
    copyData: {
      type: Object,
      default: null
    }
  },

  computed: {
    icons() {
      return {
        mdiClose,
        mdiContentCopy
      };
    },

    smallWidth() {
      return this.$vuetify.breakpoint.width < 481;
    },

    showed: {
      get() {
        return this.value;
      },
      set(v) {
        return this.$emit('input', v);
      }
    },

    color() {
      const { type } = this;
      return type === 'success'
        ? 'green'
        : type === 'error'
        ? 'red darken-2'
        : type === 'warning'
        ? 'amber darken-4'
        : 'grey darken-3';
    },

    icon() {
      if (this.smallWidth) return '';
      const { type } = this;
      return type === 'success'
        ? mdiCheckCircleOutline
        : type === 'error'
        ? mdiInformationOutline
        : type === 'warning'
        ? mdiAlertOutline
        : '';
    },

    copyButtonProps() {
      return this.smallWidth
        ? {
            text: true,
            small: true,
            content: this.$t('common.copy')
          }
        : {
            icon: true,
            width: 36,
            height: 36
          };
    },

    copyText() {
      if (this.copyData) {
        return JSON.stringify(this.copyData, null, 4);
      }
      return `${this.message}\n${this.description}`;
    }
  },

  methods: {
    show() {
      this.showed = true;
    },

    close() {
      this.showed = false;
    }
  }
};
