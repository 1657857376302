import { sentryLog } from '~/utils/common';

export const showErrorNotification = (ctx, error) => {
  const notify = ctx.$notify;
  const i18n = ctx.app?.i18n ?? ctx.$i18n;

  const supportErrorCode = error.response?.data?.supportErrorCode;
  const errors = error.response?.data?.errors;
  const statusCode = error.response?.status;
  const errorMessages = i18n.messages[i18n.locale].errors;
  const propsForNotify = {
    type: 'error',
    copyable: Boolean(supportErrorCode),
    description: supportErrorCode && i18n.t('common.errorCode', { supportErrorCode })
  };

  if (errors?.length) {
    errors.forEach((error) => {
      notify(errorMessages[error.code] || error.code, propsForNotify);

      if (error.code === 'VALIDATION_ERROR') {
        sentryLog(ctx, new Error('VALIDATION_ERROR'), { error });
      }
    });
  } else if (statusCode) {
    notify(errorMessages.statusCodes[statusCode] || i18n.t('common.errorStatusCode', { statusCode }), propsForNotify);
  } else {
    sentryLog(ctx, 'Backend error', { error });

    try {
      if (error.message === 'Network Error' || error.message.includes('timeout')) {
        const config = error?.config;
        const type = error.message === 'Network Error' ? 'Network Error' : 'Timeout Error';
        const message = error.message === 'Network Error' ? errorMessages.networkError : errorMessages.timeoutExceeded;
        const params =
          config?.params && typeof config?.params === 'string' ? JSON.parse(config?.params) : config?.params;
        const body = config?.data && typeof config?.data === 'string' ? JSON.parse(config?.data) : config?.data;

        const copyData = {
          type,
          method: config?.method,
          url: `${config?.baseURL}${config?.url}`,
          params,
          body,
          time: Date.now()
        };

        notify(message, {
          type: 'error',
          copyable: true,
          copyData
        });
      }
    } catch (error) {
      console.log(error);
      notify(errorMessages.oops, propsForNotify);
    }
  }
};
