import { render, staticRenderFns } from "./AppChatDrawer.vue?vue&type=template&id=10396025&scoped=true&"
import script from "./AppChatDrawer.vue?vue&type=script&lang=js&"
export * from "./AppChatDrawer.vue?vue&type=script&lang=js&"
import style0 from "./AppChatDrawer.vue?vue&type=style&index=0&id=10396025&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10396025",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VNavigationDrawer})
