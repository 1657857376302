import BaseService from '~/services/BaseService';

export default class ContactsService extends BaseService {
  async getContacts(params = {}) {
    const { limit = 50, offset = 0, search = null, channelsFilter = [] } = params;
    const result = await this.$apiV2.$get('/chats', {
      params: {
        limit,
        offset,
        ...(search ? { name: search } : {}),
        ...(channelsFilter ? { filterChannels: channelsFilter.join(',') } : {})
      }
    });
    return result;
  }

  async getContact(params) {
    const result = await this.$apiV2.$get('/chats', { params });
    return result;
  }

  async addChat(params) {
    const result = await this.$apiV2.$post('/chats', params, { ignoreNotify: true });
    return result.data;
  }

  async addContact(params) {
    const result = await this.$apiV2.$post('/contacts', params);
    return result;
  }
}
