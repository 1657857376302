import Vue from 'vue';
import jwt from 'jsonwebtoken';
import * as MESSAGES from '~/enums/messages';
import { unansweredTypes } from '~/enums/chats';

const sortByLastMessageDatetime = (a, b) => {
  if (+a.lastMessage.datetime > +b.lastMessage.datetime) return -1;
  if (+a.lastMessage.datetime < +b.lastMessage.datetime) return 1;
  return 0;
};

const sortByDatetime = (a, b) => {
  const aDateTime = new Date(a.datetime).getTime();
  const bDateTime = new Date(b.datetime).getTime();
  if (aDateTime > bDateTime) return -1;
  if (aDateTime < bDateTime) return 1;
  return 0;
};

const sortContacts = (contacts, that) => {
  // достаем все нужные getters
  const isIntegrationToken = that.getters['auth/isIntegrationToken'];

  // проходимся по всем контактам
  contacts.forEach((contact) => {
    const chatsRedUnanswered = [];
    const chatsGreyUnanswered = [];
    const chatsOthers = [];
    const chatsDeleted = [];

    contact.chats.forEach((chat) => {
      // сортируем чаты внутри контакта по типам неотвеченных и по признаку удаленного канала
      if (chat.unanswered) {
        if (chat.unansweredType === unansweredTypes.red) {
          chatsRedUnanswered.push(chat);
        } else if (chat.unansweredType === unansweredTypes.grey) {
          chatsGreyUnanswered.push(chat);
        }
      } else if (chat.isChannelDeleted) {
        chatsDeleted.push(chat);
      } else {
        chatsOthers.push(chat);
      }
    });

    chatsRedUnanswered.sort(sortByDatetime);
    chatsGreyUnanswered.sort(sortByDatetime);
    chatsOthers.sort(sortByDatetime);
    chatsDeleted.sort(sortByDatetime);

    // собираем отсортированный список чатов
    contact.chats = chatsRedUnanswered.concat(chatsGreyUnanswered, chatsOthers, chatsDeleted);
    // определяем unansweredType для контакта
    if (contact.chats.some(({ unanswered }) => !!unanswered)) {
      contact.unansweredType =
        contact.chats[0]?.unansweredType === unansweredTypes.red || !isIntegrationToken
          ? unansweredTypes.red
          : unansweredTypes.grey;
    }
  });

  // сортируем контакты по типам неотвеченных
  const contactsWithRedUnanswered = [];
  const contactsWithGreyUnanswered = [];
  const contactsWithoutUnansweredWithMessages = [];
  const contactsWithoutUnansweredWithoutMessages = [];
  const contactsDeletedWithMessages = [];
  const contactsDeletedWithoutMessages = [];
  const contactsEmpty = [];

  contacts.forEach((contact) => {
    if (!contact.unanswered) {
      if (!contact.chats.length) {
        contactsEmpty.push(contact);
      } else if (contact.chats.every((chat) => chat.isChannelDeleted)) {
        if (contact.lastMessage.id) {
          contactsDeletedWithMessages.push(contact);
        } else {
          contactsDeletedWithoutMessages.push(contact);
        }
      } else if (contact.lastMessage.id) {
        contactsWithoutUnansweredWithMessages.push(contact);
      } else {
        contactsWithoutUnansweredWithoutMessages.push(contact);
      }
    } else if (contact.unansweredType === unansweredTypes.red) {
      contactsWithRedUnanswered.push(contact);
    } else {
      contactsWithGreyUnanswered.push(contact);
    }
  });

  // сортируем контакты по времени последнего сообщения
  contactsWithRedUnanswered.sort(sortByLastMessageDatetime);
  contactsWithGreyUnanswered.sort(sortByLastMessageDatetime);
  contactsWithoutUnansweredWithMessages.sort(sortByLastMessageDatetime);
  contactsWithoutUnansweredWithoutMessages.sort(sortByLastMessageDatetime);
  contactsDeletedWithMessages.sort(sortByLastMessageDatetime);
  contactsDeletedWithoutMessages.sort(sortByLastMessageDatetime);
  contactsEmpty.sort(sortByLastMessageDatetime);

  // собираем отсортированный список контактов
  return contactsWithRedUnanswered.concat(
    contactsWithGreyUnanswered,
    contactsWithoutUnansweredWithMessages,
    contactsWithoutUnansweredWithoutMessages,
    contactsDeletedWithMessages,
    contactsDeletedWithoutMessages,
    contactsEmpty
  );
};

const sortMessages = (messages) => {
  messages.sort(sortByDatetime);
};

export default {
  SET_CONTACTS(state, contacts) {
    contacts.forEach((contact) => {
      const result = state.contacts.find((item) => item?.contactId === contact.contactId);
      if (result) {
        Object.keys(result).forEach((key) => {
          result[key] = contact[key];
        });
      } else {
        state.contacts.push(contact);
      }
    });
    state.contacts = sortContacts(state.contacts, this);
  },

  SET_CONTACT(state, contact) {
    if (!contact || !Object.keys(contact).length) return;
    const result = state.contacts.find((item) => {
      return item.chatType === contact.chatType && item.chatId === contact.chatId;
    });
    if (result) {
      Object.keys(result).forEach((key) => {
        result[key] = contact[key];
      });
    } else {
      state.contacts.unshift(contact);
    }
    state.contacts = sortContacts(state.contacts, this);
  },

  CLEAR_CONTACTS(state) {
    state.contacts = [];
  },

  SET_CONTACTS_CLEAR(state, contacts) {
    state.contacts = sortContacts(contacts, this);
    state.contactsLoaded = false;
  },

  SET_CONTACTS_LOADED(state, value) {
    state.contactsLoaded = value;
  },

  SET_CURRENT_CHAT(state, chat) {
    state.currentChat = chat || {};
  },

  SET_MESSAGES(state, messages) {
    const { currentChat } = this.state.chat;
    const { chatId, chatType, channelId } = currentChat;
    messages.forEach((message) => {
      const oldMessage = state.messages.find((item) => item.id === message.id);
      if (oldMessage) {
        Object.keys(message).forEach((key) => {
          oldMessage[key] = message[key];
        });
      } else if (
        message.chatId === chatId &&
        message.chatType === chatType &&
        (!channelId || (channelId && message.channelId === channelId))
      ) {
        state.messages.push(message);
      }
    });
    sortMessages(state.messages);
  },

  SET_MESSAGE(state, data) {
    // если не в чатах не обрабатываем события inputMessage и outputMessage
    if (!this.$router.currentRoute.path.includes('chat')) return;

    const { currentChat } = this.state.chat;
    const { chatId, chatType, channelId } = currentChat;
    const { message, chatUnanswered, previewText, contact } = data?.data || {};

    if (!message) return;

    if (chatId === data.chatId && chatType === data.chatType && channelId === data.channelId) {
      const oldMessage = state.messages.find((item) => item.id === message.id);
      if (oldMessage) {
        Object.keys(message).forEach((key) => {
          oldMessage[key] = message[key];
        });
      } else {
        state.messages.push(message);
      }
      sortMessages(state.messages);
      state.currentChat.unanswered = +chatUnanswered;
    }

    let editingContact = state.contacts.find((item) => {
      return item.chatType === data.chatType && item.chatId === data.chatId;
    });

    if (contact && !editingContact) {
      this.dispatch('chat/setContact', contact);
      editingContact = state.contacts.find((item) => {
        return item.chatType === data.chatType && item.chatId === data.chatId;
      });
    }

    if (editingContact) {
      const messageText = message.type === MESSAGES.TYPE_NEWS ? previewText : message.text;
      editingContact.lastMessage = {
        datetime: message.datetime,
        id: message.id,
        incoming: message.incoming,
        text: messageText,
        type: message.type,
        status: message.status
      };
      const editingChat = editingContact.chats.find((chat) => {
        return chat.channelId === data.channelId;
      });
      if (editingChat) {
        editingChat.datetime = message.datetime;
        editingChat.unanswered = +chatUnanswered;
        editingContact.unanswered = editingContact.chats.reduce((acc, chat) => {
          acc += +chat.unanswered;
          return acc;
        }, 0);
      } else {
        this.dispatch('chat/getContact', { chatType: data.chatType, chatId: data.chatId });
      }
    } else {
      if (state.contactSearchText?.length) return;
      if (this.state.auth.iFrameToken) {
        const { scope } = jwt.decode(this.state.auth.iFrameToken).integration;
        if (scope === 'card') {
          return;
        }
      }
      this.dispatch('chat/getContact', { chatType: data.chatType, chatId: data.chatId });
    }
    state.contacts = sortContacts(state.contacts, this);
  },

  SET_STT_MESSAGE(state, data) {
    const { message } = data?.data || {};

    if (!message) return;

    const oldMessage = state.messages.find((item) => item.id === message.id);
    Vue.set(oldMessage, 'isStt', message.isStt);
    Vue.set(oldMessage, 'text', message.text);
    sortMessages(state.messages);
  },

  CLEAR_MESSAGES(state) {
    state.messages = [];
  },

  UPDATE_MESSAGE_STATUS(state, data) {
    const { currentChat } = this.state.chat;
    const { chatId, chatType, channelId } = currentChat;
    const { message } = data.data;

    if (!message) return;

    if (data.chatType === chatType && data.chatId === chatId && data.channelId === channelId) {
      const messageIndex = state.messages.findIndex((item) => item.id === message.id);

      if (messageIndex === -1) return;

      // Когда ws-событие приходит со status: 98, то обновляем сообщение полностью.
      // При любом другом статусе в ws-событии поле с обьектом message отсутствует.
      if (message.status === MESSAGES.STATUS_EDITED) {
        Vue.set(state.messages, messageIndex, message);
      } else {
        Vue.set(state.messages[messageIndex], 'status', message.status);
        Vue.set(state.messages[messageIndex], 'datetime', message.datetime);
        Vue.set(state.messages[messageIndex], 'timestampMsg', message.timestampMsg);
      }
    }

    const contact = state.contacts.find((item) => item.chatId === data.chatId && item.chatType === data.chatType);

    if (contact && contact.lastMessage.id === message.id) {
      Vue.set(contact.lastMessage, 'status', message.status);

      if (message.status === MESSAGES.STATUS_EDITED) {
        Vue.set(contact, 'lastMessage', message);
      }
    }
  },

  SET_WAZZUP_TEMPLATES(state, templates) {
    state.wazzupTemplates = templates;
  },

  SET_WABA_TEMPLATES(state, { templates }) {
    state.wabaTemplates = templates;
  },

  CLEAR_UNANSWERED(state, data) {
    const { currentChat } = state;
    const { chatId, chatType, channelId } = currentChat;
    if (data.chatType === chatType && data.chatId === chatId && data.channelId === channelId) {
      state.currentChat.unanswered = 0;
    }
    // находим контакт, чат. Уменьшаем счетчик неотвеченных контакта на счетчик неотвеченных чата. И обнуляем счетчик чата. Сортируем контакты
    const contact = state.contacts.find((contact) => {
      return contact.chats.find((chat) => {
        return chat.chatId === data.chatId && chat.chatType === data.chatType && chat.channelId === data.channelId;
      });
    });

    if (!contact) return;
    const chat = contact.chats.find(
      (chat) => chat.chatId === data.chatId && chat.chatType === data.chatType && chat.channelId === data.channelId
    );
    if (!chat) return;
    contact.unanswered = contact.unanswered - chat.unanswered;
    chat.unanswered = 0;
    state.contacts = sortContacts(state.contacts, this);
  },

  CLEAR_ALL_UNANSWERED(state) {
    if (!state?.contacts?.length) return;
    state.contacts.forEach((contact) => {
      if (Number(contact.unanswered) > 0) contact.unanswered = 0;

      if (!contact?.chats?.length) return;
      contact.chats.forEach((chat) => {
        if (Number(chat.unanswered) > 0) chat.unanswered = 0;
      });
    });
    state.contacts = sortContacts(state.contacts, this);
  },

  SET_INTEGRATION_ENTITIES(state, data) {
    try {
      const allowCreateEntity = jwt.decode(this.state.auth.iFrameToken).integration.allowCreateEntity;
      state.integrationEntities = { ...data, allowCreateEntity };
    } catch (err) {
      console.log(err);
    }
  },

  CHAT_ACCESS_GRANTED(state, data) {
    this.dispatch('chat/getContact', { chatType: data.data.chatType, chatId: data.data.chatId });
  },

  CHAT_ACCESS_DENIED(state, data) {
    const { chatId, chatType } = data.data;
    state.contacts = state.contacts.filter((item) => !(item.chatType === chatType && item.chatId === chatId));
  },

  CREATE_VIRTUAL_CONTACT(state, contact) {
    // !state.contacts.find((item) => item.chatType === contact.chatType && item.chatId === contact.chatId) &&
    state.contacts.unshift(contact);
  },

  SET_CONTACT_SEARCH_TEXT(state, text) {
    state.contactSearchText = text;
  },

  SET_MESSAGES_SENDING(state, messagesSending) {
    state.messagesSending = messagesSending;
  },

  SET_CURRENT_CHAT_BALANCE(state, balance) {
    state.currentChatBalance = balance;
  },

  SET_MESSAGES_LOADED(state, value) {
    state.messagesLoaded = value;
  },

  SET_ONCE_SCROLLED(state) {
    state.onceScrolled = true;
  },

  SET_STT_EVENT(state, event) {
    state.sttEvent = event;
  },

  SET_CHANNELS_FILTER(state, channelsFilter) {
    state.channelsFilter = channelsFilter;
  }
};
