export default ({ app }, inject) => {
  const notificationsCountBadge = {
    setNotificationCount: (unreadCount) => {
      if ('setAppBadge' in navigator) {
        navigator.setAppBadge(unreadCount).catch((error) => {
          console.log('Cant set unread count', error);
        });
      }
    },

    clearNotificationCount: () => {
      if ('clearAppBadge' in navigator) {
        navigator.clearAppBadge().catch((error) => {
          console.log('Cant clear unread count', error);
        });
      }
    }
  };
  inject('notificationsCountBadge', notificationsCountBadge);
};
