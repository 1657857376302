export const intergationTypes = {
  amocrm: 'amocrm_v3',
  kommo: 'kommo',
  bitrix: 'bitrix',
  zoho: 'zoho',
  hubspot: 'hubspot',
  salesforce: 'salesforce',
  planfix: 'planfix',
  megaplan: 'megaplan',
  pipedrive: 'pipedrive',
  api_v1: 'api_v1',
  api_v2: 'api_v2',
  api_v3: 'api_v3',
  '1c': '1c',
  yclients: 'yclients'
};

export const integrationStates = {
  init: 'init',
  active: 'active',
  deleted: 'deleted',
  warning: 'warning',
  paused: 'paused',
  error: 'error'
};

export const nonConfigurableIntegrationTypes = [intergationTypes.api_v1, intergationTypes.planfix];

export const configurableIntegrationTypes = [
  intergationTypes.amocrm,
  intergationTypes.kommo,
  intergationTypes.bitrix,
  intergationTypes.api_v2,
  intergationTypes.hubspot,
  intergationTypes.zoho,
  intergationTypes.megaplan,
  intergationTypes.pipedrive,
  intergationTypes.salesforce
];

export const crmTypes = {
  2: 'api',
  3: 'amoCRM',
  4: 'Planfix',
  5: 'Bitrix 24',
  6: 'ZohoCRM',
  7: 'Salesforce',
  8: 'Hubspot',
  9: 'API',
  10: 'Wazzup App',
  11: 'API',
  12: 'Pipedrive'
};

export const userRoles = {
  manager: 'manager',
  seller: 'seller',
  auditor: 'auditor',
  managerCandidate: 'managerCandidate',
  sellerCandidate: 'sellerCandidate',
  unassigned: 'unassigned'
};

export const stateInfo = {
  init: 'init',
  getAllContacts: 'getAllContacts',
  getAllDeals: 'getAllDeals',
  complete: 'complete'
};
