//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ResizeObserver } from 'resize-observer';

export default {
  name: 'BaseInfinityLoader',

  props: {
    loading: {
      type: Boolean
    },
    direction: {
      type: String,
      default: 'bottom'
    },
    turnOff: {
      type: Boolean,
      default: false
    },
    disableScroll: {
      type: Boolean
    },
    fixHeight: {
      type: Boolean
    }
  },

  data() {
    return {
      isIntersecting: false,
      height: 0,
      observer: null
    };
  },

  watch: {
    isIntersecting(v) {
      v && this.$emit('load');
    }
  },

  mounted() {
    this.$refs.baseInfinityLoader.addEventListener('scroll', (event) => {
      if (!event.target.scrollTop) event.target.scrollTop = 1;
    });
    if (this.fixHeight) {
      const drawerSearch = document.querySelector('.drawer-search');
      this.handleResize();
      window.addEventListener('resize', this.handleResize);
      this.observer = new ResizeObserver((entries) => {
        this.handleResize();
      });
      this.observer.observe(drawerSearch);
    }
  },

  beforeDestroy() {
    if (this.fixHeight) {
      window.removeEventListener('resize', this.handleResize);
      this.observer?.disconnect();
    }
  },

  methods: {
    handleIntersect(entries, observer, isIntersecting) {
      this.isIntersecting = isIntersecting;
    },

    handleResize() {
      const { y } = this.$refs.baseInfinityLoader.getBoundingClientRect();
      this.height = window.innerHeight - y;
    }
  }
};
