import { mdiAccountGroup, mdiFacebook, mdiTools } from '@mdi/js';

export const getTransportIcon = (transport) => {
  const icons = {
    'whatsgroup': { icon: mdiAccountGroup, color: 'whatsapp' },
    'telegroup': { icon: mdiAccountGroup, color: 'blue darken-1' },
    'vkgroup': { icon: mdiAccountGroup, color: 'vk-blue' },
    'whatsapp': { icon: '$whatsapp', color: 'whatsapp' },
    'waba': { icon: '$whatsapp-api', color: 'whatsapp' },
    'wapi': { icon: '$whatsapp-api', color: 'whatsapp' },
    'instagram': { icon: '$instagram', color: 'pink' },
    'instAPI': { icon: '$instagram-api', color: 'pink' },
    'telegram': { icon: '$telegram-bot', color: 'blue lighten-1' },
    'tgapi': { icon: '$telegram-personal', color: 'blue lighten-1' },
    'vk': { icon: '$vk', color: 'vk-blue' },
    'avito': { icon: '$avito' },
    'facebook': { icon: mdiFacebook, color: 'blue darken-1' },
    'viber': { icon: '$viber', color: 'viber-purple' }
  };

  if (Object.keys(icons).includes(transport)) {
    return icons[transport];
  } else {
    return { icon: mdiTools, color: 'grey darken-1' };
  }
};
