import userflow from 'userflow.js';

export default ({ $config, store }, inject) => {
  const startUserflow = async (params = {}) => {
    const { account, iFrameToken, cabinetInIframe } = store.state.auth;
    if (account && Object.keys(account).length && !iFrameToken && !cabinetInIframe) {
      const { lang, refLinkCode, type, id, partnerRef, firstSubscriptionPaymentDate, registerAt } = account;
      const localeCode = lang === 'ru' ? 'ru-RU' : lang === 'pt' ? 'pt-BR' : lang === 'es' ? 'es-ES' : 'en-US';

      if (Date.now() - registerAt < (365 / 2) * 24 * 60 * 60 * 1000) {
        try {
          userflow.init($config.USERFLOW_TOKEN);
          await userflow.identify(String(id), {
            account_id: id,
            country: account?.details?.country,
            type,
            reflink: partnerRef || 'null',
            locale_code: localeCode,
            signed_up_at: new Date(registerAt).toISOString(),
            refLinkCode: refLinkCode || 'null',
            firstSubscriptionPaymentDate,
            ...params
          });
          await userflow.group(String(account.id).slice(0, 1));
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  inject('startUserflow', startUserflow);
};
