import BaseService from '~/services/BaseService';

export default class TemplatesService extends BaseService {
  //  Метод получения экземпляра для тех.партнера или для ЛК
  //  Причина создания: чтобы не выписывать
  //  однообразную проверку в каждом запросе
  getInjectedAxiosInstance() {
    return this.route.path.includes('tech-partner') ? this.$apiTechPartner : this.$apiV2;
  }

  //   Обычные шаблоны

  //  Получение шаблонов
  async getTemplatesWazzup(params) {
    const defaultParams = {
      order: 'order'
    };
    const result = await this.getInjectedAxiosInstance().$get('/templates', {
      params: { ...defaultParams, ...params }
    });
    return result;
  }

  //  Добавление шаблонов
  async addTemplateWazzup(params) {
    const result = await this.getInjectedAxiosInstance().$post('/templates', params);
    return result;
  }

  //  Изменение порядка шаблонов
  async reorderTemplatesWazzup(data) {
    const result = await this.getInjectedAxiosInstance().$post('/templates/reorder', data);
    return result;
  }

  //  Изменение шаблона
  async editTemplateWazzup(params) {
    const result = await this.getInjectedAxiosInstance().$patch(`/templates/${params.guid}`, params);
    return result;
  }

  //  Удаление шаблона
  async deleteTemplateWazzup(guid) {
    const result = await this.getInjectedAxiosInstance().$delete(`/templates/${guid}`);
    return result;
  }

  ///  Шаблоны WABA

  //  Получение категорий шаблонов
  async getCategoryApi() {
    const result = await this.getInjectedAxiosInstance().$get('/templates/whatsapp/categories');
    return result;
  }

  //  Получение языков шаблонов
  async getLanguagesApi() {
    const result = await this.getInjectedAxiosInstance().$get('/templates/whatsapp/languages');
    return result;
  }

  //  Получение бизнес аккаунтов
  async getBusinessAccountsApi() {
    const result = await this.getInjectedAxiosInstance().$get('/templates/whatsapp/business-accounts');
    return result;
  }

  //  Получение шаблонов
  async getTemplatesApi(params) {
    const defaultParams = {
      order: 'order'
    };
    const result = await this.getInjectedAxiosInstance().$get('/templates/whatsapp/list', {
      params: { ...defaultParams, ...params }
    });
    return result;
  }

  //  Получение шаблона по guid
  async getTemplateByGuidApi(guid) {
    const result = await this.getInjectedAxiosInstance().$get(`/templates/whatsapp/${guid}`);
    return result;
  }

  //  Добавление шаблонов
  async addTemplateApi(body, isDraft = false) {
    const result = await this.getInjectedAxiosInstance().$post(
      '/templates/whatsapp',
      { isDraft, template: body },
      { timeout: 60000 }
    );
    return result;
  }

  //  Изменение порядка шаблонов
  async reorderTemplatesApi(data) {
    const result = await this.getInjectedAxiosInstance().$post('/templates/whatsapp/reorder', data);
    return result;
  }

  //  Изменение шаблона
  async editTemplateApi(body, templateGuid, isDraft = false) {
    const result = await this.getInjectedAxiosInstance().$patch(`/templates/whatsapp/${templateGuid}`, {
      isDraft,
      template: body
    });
    return result;
  }

  //  Удаление шаблона
  async deleteTemplateApi(guid) {
    const result = await this.getInjectedAxiosInstance().$delete(`/templates/whatsapp/${guid}`);
    return result;
  }

  // обновление статусов API шаблонов
  async refreshStatuses() {
    const result = await this.getInjectedAxiosInstance().$get('/templates/whatsapp/sync_button');
    return result;
  }

  // получение списка тегов по типу шаблонов
  async getTemplateTags(type) {
    const result = await this.getInjectedAxiosInstance().$get(`/templates/${type}Tags`);
    return result;
  }

  // редактирование списка тегов по типу шаблонов
  async patchTemplateTags(type, batch) {
    const result = await this.getInjectedAxiosInstance().$patch(`/templates/${type}Tags`, { batch });
    return result;
  }

  // редактирование списка тегов шаблона
  async patchTagsInTemplate(guid, tags) {
    const result = await this.getInjectedAxiosInstance().$patch('/templates/templateTags', { guid, tags });
    return result;
  }
}
