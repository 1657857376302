import { render, staticRenderFns } from "./BaseSnackbar.vue?vue&type=template&id=6b93ccc8&scoped=true&"
import script from "./BaseSnackbar.vue?vue&type=script&lang=js&"
export * from "./BaseSnackbar.vue?vue&type=script&lang=js&"
import style0 from "./BaseSnackbar.vue?vue&type=style&index=0&id=6b93ccc8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b93ccc8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseCopyButton: require('/builds/wazzup/front/components/base/BaseCopyButton.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VBtn,VIcon,VSnackbar})
