export const componentsTypes = {
  header: 'HEADER',
  body: 'BODY',
  footer: 'FOOTER',
  buttons: 'BUTTONS'
};

export const headerTypes = {
  text: 'TEXT',
  image: 'IMAGE',
  document: 'DOCUMENT',
  video: 'VIDEO'
};

export const buttonsTypes = {
  quickReply: 'QUICK_REPLY',
  link: 'URL',
  phoneNumber: 'PHONE_NUMBER'
};

export const documentTypes = {
  pdf: 'pdf',
  excel: 'excel',
  powerpoint: 'powerpoint',
  other: 'other'
};

export const apiTemplateStatuses = {
  draft: 'draft',
  pending: 'pending',
  approved: 'approved',
  rejected: 'rejected',
  paused: 'paused',
  disabled: 'disabled',
  new: 'new'
};

export const rejectedReasons = {
  incorrect_category: 'incorrect_category',
  invalid_format: 'invalid_format',
  abusive_content: 'abusive_content',
  scam: 'scam',
  paused: 'paused',
  none: 'none'
};

export const allowedSizeByFileType = {
  image: 5,
  video: 16,
  audio: 16,
  other: 50
};

export const templateTagsTypes = {
  wazzup: 'wazzup',
  waba: 'waba'
};

export const tagItemTypes = {
  initial: 'initial',
  new: 'new'
};
