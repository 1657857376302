import BaseService from '~/services/BaseService';

export default class RequisitesService extends BaseService {
  async getCompanyRequisitesFromInn(query) {
    const result = await this.$apiV2.$get(`/requisites/companyInfo?query=${query}`, { ignoreNotify: true });
    return result;
  }

  async getRequisites() {
    const result = await this.$apiV2.$get('/requisites');
    return result;
  }

  async addCompanyRequisites(data) {
    const result = await this.$apiV2.$post('/requisites', { data });
    return result;
  }

  async deleteCompanyRequisites(id) {
    const result = await this.$apiV2.$delete(`/requisites/${id}`);
    return result;
  }

  async editCompanyRequisites({ id, data }) {
    const result = await this.$apiV2.$patch(`/requisites/${id}`, { data });
    return result;
  }
}
