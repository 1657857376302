import BaseService from '~/services/BaseService';

export default class ClientsService extends BaseService {
  async getClientsNew() {
    const result = await this.$apiV2.$get('/affiliate/clients');
    return result;
  }

  async updateClient(data) {
    const result = await this.$apiV2.$patch(`/affiliate/clients/update/${data.id}`, data);
    return result;
  }

  async addClientNew(data) {
    const result = await this.$apiV2.$post('affiliate/clients/register/email', data, { ignoreNotify: true });
    return result;
  }

  async toggleStatus(data) {
    const result = await this.$apiV2.$patch(`affiliate/clients/toggleStatus/${data}`);
    return result;
  }
}
