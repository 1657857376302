export const channelTypes = {
  whatsapp: 'whatsapp',
  wapi: 'wapi',
  instagram: 'instagram',
  instAPI: 'instAPI',
  telegram: 'telegram',
  tgapi: 'tgapi',
  vk: 'vk',
  facebook: 'facebook',
  viber: 'viber',
  avito: 'avito'
};

export const channelStatuses = {
  deleted: 'deleted',
  unregistered: 'unregistered',
  notInSubscription: 'notInSubscription',
  notEnoughMoney: 'notEnoughMoney',
  notEnoughBalance: 'notEnoughBalance',
  dialogLimitExceeded: 'dialogLimitExceeded',
  phoneUnavailable: 'phoneUnavailable',
  openelsewhere: 'openelsewhere',
  unauthorized: 'unauthorized',
  init: 'init',
  approachingDialogueLimit: 'approachingDialogueLimit',
  active: 'active',
  blocked: 'blocked',
  foreignphone: 'foreignphone',
  qridle: 'qridle',
  qr: 'qr',
  waitForPassword: 'waitForPassword',
  onModeration: 'onModeration',
  trial: 'trial',
  rejected: 'rejected',
  sync: 'sync'
};

export const logsStatuses = {
  'channelStatePhoneUnavailable': 'phoneUnavailable',
  'channelStateOpenElsewhere': 'openelsewhere',
  'channelEmptyWabaBalance': 'emptyWabaBalance',
  'channelExceededDialogsLimit': 'dialogLimitExceeded',
  'channelProxyDisabled': 'proxyDisabled',
  'channelProxyChanged': 'proxyChanged',
  'channelStateUnauthorized': 'unauthorized',
  'channelStateNotEnoughMoney': 'notEnoughMoney',
  'channelStateInit': 'init',
  'channelWithoutSubscription': 'withoutSubscription',
  'channelApproachingDialogsLimit': 'approachingDialogueLimit',
  'channelStateActive': 'active',
  'channelDeleted': 'deleted',
  'channelAdded': 'added',
  'channelQueueCleared': 'queueCleared',
  'channelOptionsChanged': 'optionsChanged',
  'channelAlmostEmptyWabaBalance': 'channelAlmostEmptyWabaBalance',
  'channelTrialEnabled': 'trialEnabled',
  'channelTrialExpired': 'channelTrialExpired',
  'channelStateRejected': 'channelStateRejected'
};

export const channelGroups = {
  whatsapp: {
    type: 'whatsapp',
    includedTypes: ['whatsapp', 'waba', 'wapi']
  },
  instagram: {
    type: 'instagram',
    includedTypes: ['instagram', 'instAPI']
  },
  telegram: {
    type: 'telegram',
    includedTypes: ['telegram', 'tgapi']
  },
  facebook: 'facebook',
  vk: 'vk',
  avito: 'avito',
  viber: 'viber'
};

export const wabaTiers = {
  0: 'TIER_0',
  1: 'TIER_1K',
  2: 'TIER_10K',
  3: 'TIER_100K'
};

export const counterComments = {
  newPeriod: 'newPeriod',
  subscriptionAdded: 'subscriptionAdded',
  trialPeriod: 'trialPeriod',
  trialLastDay: 'trialLastDay',
  tariffChanged: 'tariffChanged',
  approachingDialogueLimit: 'approachingDialogueLimit',
  exceededDialogueLimit: 'exceededDialogueLimit'
};

export const channelTypeParams = {
  [channelTypes.whatsapp]: {
    name: 'WhatsApp',
    componentAddChannelForm: 'forms-add-channel-whatsapp-web',
    availableForAdd: true,
    settings: ['options-current-subscription', 'options-name-channel', 'options-notifications'],
    group: 'whatsapp'
  },

  [channelTypes.wapi]: {
    name: 'WABA',
    componentAddChannelForm: 'forms-add-channel-whatsapp-api',
    availableForAdd: true,
    settings: [
      'options-current-subscription',
      'options-tier-of-limit',
      'options-name-channel',
      'options-notifications'
    ],
    group: 'whatsapp'
  },

  [channelTypes.instAPI]: {
    name: 'Instagram API',
    componentAddChannelForm: 'forms-add-channel-instagram-api',
    availableForAdd: true,
    settings: [
      'options-current-subscription',
      'options-name-channel',
      'options-notifications',
      'options-get-comments',
      'options-spam-filter'
    ],
    group: 'instagram'
  },

  [channelTypes.instagram]: {
    name: 'Instagram Personal',
    componentAddChannelForm: 'forms-add-channel-instagram',
    availableForAdd: false,
    settings: [
      'options-current-subscription',
      'options-name-channel',
      'options-notifications',
      'options-proxy',
      'options-get-comments',
      'options-spam-filter'
    ],
    group: 'instagram'
  },

  [channelTypes.tgapi]: {
    name: 'Telegram Personal',
    componentAddChannelForm: 'forms-add-channel-telegram-personal',
    availableForAdd: true,
    settings: ['options-current-subscription', 'options-name-channel', 'options-notifications'],
    group: 'telegram'
  },

  [channelTypes.telegram]: {
    name: 'Telegram Bot',
    componentAddChannelForm: 'forms-add-channel-telegram-bot',
    availableForAdd: true,
    settings: ['options-name-channel', 'options-notifications'],
    group: 'telegram'
  },

  [channelTypes.vk]: {
    name: 'VK',
    componentAddChannelForm: 'forms-add-channel-vk',
    availableForAdd: true,
    settings: ['options-current-subscription', 'options-name-channel', 'options-notifications']
  },

  [channelTypes.avito]: {
    name: 'Avito',
    componentAddChannelForm: 'forms-add-channel-avito',
    availableForAdd: true,
    settings: ['options-current-subscription', 'options-name-channel', 'options-notifications']
  },

  [channelTypes.facebook]: {
    name: 'Facebook',
    componentAddChannelForm: 'forms-add-channel-facebook',
    availableForAdd: false
  },

  [channelTypes.viber]: {
    name: 'Viber',
    componentAddChannelForm: 'forms-add-channel-viber',
    availableForAdd: true,
    requiredFeature: 'enableViber',
    settings: ['options-current-subscription', 'options-name-channel', 'options-notifications']
  }
};
