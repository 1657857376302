//
//
//
//
//
//

export default {
  data() {
    return {};
  },

  head() {
    return {
      meta: [{ hid: 'viewport', name: 'viewport', content: 'width=device-width, initial-scale=1' }]
    };
  }
};
