import { channelTypes } from '~/enums/channels';

export default {
  // здесь формируется объект из неудалённых каналов,
  // группированных по транспорту (заблокированные не отсеиваются)
  channelsByTransport(state) {
    const result = {};
    state.channels.forEach((item) => {
      if (!Array.isArray(result[item.transport])) result[item.transport] = [];
      if (item.deleted && !item.visible) return;
      result[item.transport].push(item);
    });

    Object.keys(result).forEach((transport) => {
      result[transport].sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      });
    });
    return result;
  },

  channelsSortedByTransport(state, getters) {
    return (sortingOrder) => {
      const sorted = sortingOrder.reduce((buff, transport) => {
        getters.channelsByTransport[transport] && buff.push(...getters.channelsByTransport[transport]);
        return buff;
      }, []);
      return sorted;
    };
  },

  nonGupshupActiveWabaChannels(state) {
    return state.channels.filter((channel) => {
      return !channel.deleted && channel.transport === channelTypes.wapi && !channel.details?.isGupshupWaba;
    });
  },

  gupshupBannerClosed(state, getters, rootState, rootGetters) {
    return !rootGetters['auth/frontendData']?.gupshupBannerClosed;
  },

  showGupshup(state, getters) {
    return getters.nonGupshupActiveWabaChannels.length && getters.gupshupBannerClosed;
  }
};
