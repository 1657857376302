export const chatTypes = {
  wazzup: 'wazzup',
  telegram: 'telegram',
  vk: 'vk',
  vkgroup: 'vkgroup',
  whatsapp: 'whatsapp',
  instagram: 'instagram',
  whatsgroup: 'whatsgroup',
  facebook: 'facebook',
  viber: 'viber',
  telegroup: 'telegroup',
  avito: 'avito'
};

export const unansweredTypes = {
  red: 'red',
  grey: 'grey'
};
