//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mdiHelpCircleOutline } from '@mdi/js';

export default {
  name: 'BaseHelpIcon',

  props: {
    tooltipText: {
      type: String,
      default: ''
    },

    tooltipProps: {
      type: Object,
      default: null
    },

    icon: {
      type: String,
      default: mdiHelpCircleOutline
    },

    size: {
      type: String,
      default: '20'
    },

    color: {
      type: String,
      default: 'grey'
    }
  },

  data() {
    return {
      show: false
    };
  },

  computed: {
    computedTooltipProps() {
      if (this.tooltipProps?.left || this.tooltipProps?.bottom || this.tooltipProps?.top)
        return { ...this.tooltipProps };

      return { right: true, ...this.tooltipProps };
    }
  },

  methods: {
    switchDisplayTooltip() {
      this.show = true;

      const handler = (e) => {
        if (!this.$refs?.tooltip?.contains(e.target)) {
          this.show = false;
          document.removeEventListener('click', handler, true);
        }
      };

      document.addEventListener('click', handler, true);
    }
  }
};
