//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  mdiAccount,
  mdiAccountMultiple,
  mdiDeleteClockOutline,
  mdiForumOutline,
  mdiPlus,
  mdiChevronDown,
  mdiFileDocumentEditOutline
} from '@mdi/js';
import { mapState } from 'vuex';
import dayjs from 'dayjs';
import { getTransportIcon } from '~/utils/getTransportIcon';
import * as MESSAGES from '~/enums/messages';
import { chatTypes } from '~/enums/chats';
import { channelTypes } from '~/enums/channels';
import { computedAvailableChannels } from '~/utils/chats';

export default {
  name: 'DrawerContactItem',

  props: {
    contact: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      hover: false,
      opened: false,
      draft: null
    };
  },

  computed: {
    ...mapState('chat', ['currentChat']),
    ...mapState('channels', ['accessibleChannels']),
    ...mapState('auth', ['account']),

    icons() {
      return {
        mdiAccount,
        mdiAccountMultiple,
        mdiDeleteClockOutline,
        mdiForumOutline,
        mdiPlus,
        mdiFileDocumentEditOutline
      };
    },

    availableChannels() {
      return computedAvailableChannels(this.accessibleChannels, this.contact.chatType);
    },

    showAddDialog() {
      const { telegram, viber, whatsapp } = chatTypes;
      return [telegram, whatsapp, viber].includes(this.contact.chatType) && this.availableChannels.length;
    },

    contactId() {
      if (this.isGroupContact) {
        return null;
      } else if ([channelTypes.whatsapp, channelTypes.viber].includes(this.contactTransport)) {
        return this.$t('chat.contacts.contactNumber', { number: `+${this.contact.chatId}` });
      } else if (this.contactTransport === channelTypes.tgapi) {
        if (this.contact.userName) {
          return this.$t('chat.contacts.contactId', { id: `@${this.contact.userName}` });
        } else if (this.contact.userPhone) {
          return this.$t('chat.contacts.contactNumber', { number: `+${this.contact.userPhone}` });
        } else {
          return null;
        }
      } else if (this.contactTransport === channelTypes.instAPI) {
        return this.$t('chat.contacts.contactId', { id: `@${this.contact.chatId}` });
      } else {
        return null;
      }
    },

    isGroupContact() {
      if (this.contact.isGroupContact) return this.contact.isGroupContact;
      if (!this.contact?.chats?.[0]?.chatType) return false;
      return [chatTypes.whatsgroup, chatTypes.telegroup].includes(this.contact.chats[0].chatType);
    },

    avatarPlaceholder() {
      return this.isGroupContact ? this.icons.mdiAccountMultiple : this.icons.mdiAccount;
    },

    contactBadgeColor() {
      const { unansweredType } = this.contact;

      return unansweredType === 'grey' ? 'grey lighten-1' : 'red';
    },

    chatBadgeColor() {
      return ({ unansweredType }) => {
        return unansweredType === 'grey' ? 'grey lighten-1' : 'red';
      };
    },

    isContactIsNews() {
      return this.contact.chatType === chatTypes.wazzup;
    },

    getNewLastMsgTxt() {
      const currentNewsTxt = this.contact.lastMessage?.text?.length ? this.contact.lastMessage.text : '';
      return currentNewsTxt;
    },

    deletedChat() {
      return (chat) => {
        return chat?.isChannelDeleted;
      };
    },

    avatarSrc() {
      try {
        return this.contact.avatarId && `${this.$config.storeBaseUrl}/${this.contact.avatarId}`;
      } catch (err) {
        return null;
      }
    },

    contactTransport() {
      const { chatType } = this.contact;
      return [chatTypes.telegram, chatTypes.telegroup].includes(chatType)
        ? channelTypes.tgapi
        : [chatTypes.whatsapp, chatTypes.whatsgroup].includes(chatType)
        ? channelTypes.whatsapp
        : chatType === chatTypes.instagram
        ? channelTypes.instAPI
        : chatType;
    },

    contactIcon() {
      return getTransportIcon(this.contactTransport);
    },

    transportIcon() {
      return (chat) => {
        const channel = this.accessibleChannels.find((channel) => channel.guid === chat.channelId);
        const transport = channel?.details?.instAPI ? channelTypes.instAPI : channel?.transport;

        return getTransportIcon(transport);
      };
    },

    secondLine() {
      return (chat) => {
        if (chat.chatType === chatTypes.avito) {
          return chat.isPrivate ? this.$t('chat.privateMessages') : chat.advert.title;
        } else {
          return null;
        }
      };
    },

    lastMsgDateTime() {
      return (datetime) => {
        if (!datetime) return '';
        const time = dayjs(datetime);
        if (time.isToday()) {
          return time.format('HH:mm');
        } else if (time.isYesterday()) {
          return this.$t('chat.listYesterday');
        } else {
          return time.format('DD.MM.YY');
        }
      };
    },

    actionIcon() {
      return this.contact.chats.length ? mdiChevronDown : mdiPlus;
    },

    lastMsgIcon() {
      if (this.draft) {
        return null;
      }
      const type = this.contact.lastMessage.type;
      if (type === 10) {
        return this.getNewsLstMsgIcon;
      }
      if ((type > 1 && type < 8) || type === 21) {
        return MESSAGES.TYPES[type].icon;
      } else {
        return null;
      }
    },

    lastMsgText() {
      if (this.draft) {
        if (this.draft.text) {
          return this.draft.text;
        } else {
          return ' ';
        }
      }

      const { lastMessage } = this.contact;

      if ([MESSAGES.TYPE_UNSUPPORTED_BY_MESSENGER, MESSAGES.TYPE_UNSUPPORTED_BY_SERVICE].includes(lastMessage.type)) {
        return lastMessage.incoming
          ? this.$t('chat.unsupportedMessage.incoming')
          : this.$t('chat.unsupportedMessage.outgoing');
      }

      return lastMessage?.text?.length
        ? lastMessage.text
        : MESSAGES.TYPES[lastMessage.type]?.text?.length
        ? this.$t(MESSAGES.TYPES[lastMessage.type]?.text)
        : '';
    },

    isThisChat() {
      const { chatId = '', chatType = '' } = this.$route.params;
      return chatId === this.contact.chatId && chatType === this.contact.chatType;
    },

    status() {
      if (this.draft) {
        return { icon: mdiFileDocumentEditOutline, color: 'grey darken-1' };
      }
      if (this.contact.lastMessage.incoming || !this.lastMsgText.length) return null;
      return MESSAGES.STATUSES[this.contact.lastMessage.status];
    }
  },

  mounted() {
    this.setDraft();
    this.$nuxt.$on('collapse-all-contacts', () => (this.opened = false));
    this.$nuxt.$on('draft-updated', () => {
      this.setDraft();
    });
    this.$nuxt.$on('go-to-active-chat-contact', () => {
      const { chatType, chatId } = this.contact;
      if (this.currentChat.chatType === chatType && this.currentChat.chatId === chatId) {
        this.$el.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest'
        });
      }
    });
  },

  beforeDestroy() {
    this.$nuxt.$off('collapse-all-contacts');
    this.$nuxt.$off('draft-updated');
  },

  methods: {
    setDraft() {
      try {
        const { chatType, chatId, chats } = this.contact;
        if (!chats.length) return;
        const draft = JSON.parse(localStorage.draft || '[]').find(
          (item) => item.chatType === chatType && item.chatId === chatId && item.channelId === chats[0]?.channelId
        );
        if (draft) {
          this.draft = draft.message;
        } else {
          this.draft = null;
        }
      } catch (err) {
        console.log(err);
      }
    },

    clickCard() {
      const { chats, chatType, chatId } = this.contact;

      !this.isThisChat && this.$nuxt.$emit('collapse-all-contacts');
      if (chats.length) {
        const channelId = chats[0].channelId;
        this.$router.push(this.$userPath(`/chat/${chatType}/${encodeURIComponent(chatId)}/${channelId}`));
      } else {
        this.$router.push(this.$userPath(`/chat/${chatType}/${encodeURIComponent(chatId)}/add`));
      }
    },

    clickActionIcon() {
      const { chats } = this.contact;
      if (!chats.length) {
        this.handleAddChat();
      }
      if (chats.length) {
        if (this.opened) {
          this.opened = false;
        } else {
          this.$nuxt.$emit('collapse-all-contacts');
          this.opened = true;
        }
      }
    },

    handleAddChat() {
      const { chatType, chatId } = this.contact;
      return this.$router.push(this.$userPath(`/chat/${chatType}/${encodeURIComponent(chatId)}/add`));
    }
  }
};
