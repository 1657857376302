import BaseService from '~/services/BaseService';

export default class ChannelsService extends BaseService {
  getInjectedAxiosInstance() {
    return this.route.path.includes('tech-partner') ? this.$apiTechPartner : this.$apiV2;
  }

  async getChannels(params) {
    const result = await this.getInjectedAxiosInstance().$get('/channels/list', { params });

    return result;
  }

  async addChannel(data, ignoreNotify = false) {
    const { transport, params } = data;
    const result = await this.getInjectedAxiosInstance().$post(`/channels/${transport}`, params, {
      timeout: 30000,
      ignoreNotify
    });
    return result;
  }

  async editChannel({ guid, transport, params }) {
    const result = await this.$apiV2.$patch(`/channels/${transport}/${guid}`, params);
    return result;
  }

  async deleteChannel(data) {
    const result = await this.$apiV2.$delete('/channels', { data });
    return result;
  }

  async reInit(guid, transport, params, ignoreNotify = false) {
    const result = await this.getInjectedAxiosInstance().$post(`/channels/${transport}/${guid}/reinit`, params, {
      ignoreNotify
    });
    return result;
  }

  async getLogs({ guid, params }) {
    const result = await this.$apiV2.$get(`/channels/logs/${guid}`, { params });
    return result;
  }

  async getUniqueChats({ guid, params }) {
    const result = await this.$apiV2.$get(`/channels/uniqueChats/${guid}`, { params });
    return result;
  }

  async reconnectChannel({ guid, transport, params }) {
    const result = await this.getInjectedAxiosInstance().$post(`/channels/${transport}/reconnect/${guid}`, params);
    return result;
  }

  async editProxy({ guid, params }) {
    const result = await this.$apiV2.$patch(`/channels/instagram/${guid}/proxy`, params, { timeout: 30000 });
    return result;
  }

  async confirmTwoFactTelegramPersonal({ guid, params }) {
    const result = await this.getInjectedAxiosInstance().$post(`/channels/tgapi/${guid}/2fa`, params, {
      ignoreNotify: true
    });
    return result;
  }

  // WABA Profile
  async getWabaProfile({ channelId }) {
    const result = await this.getInjectedAxiosInstance().$get(`/channels/waba/${channelId}/profile`, {
      ignoreNotify: true
    });
    return result;
  }

  async getWabaVerticals({ channelId }) {
    const result = await this.getInjectedAxiosInstance().$get(`/channels/waba/${channelId}/profile/verticals`);
    return result;
  }

  async editWabaProfile({ channelId, data }) {
    const result = await this.getInjectedAxiosInstance().$post(`/channels/waba/${channelId}/profile`, data);
    return result;
  }

  async editWabaProfileAvatar({ channelId, file }) {
    const result = await this.getInjectedAxiosInstance().$post(`/channels/waba/${channelId}/profile/photo`, file);
    return result;
  }

  async deleteWabaProfileAvatar({ channelId }) {
    const result = await this.getInjectedAxiosInstance().$delete(`/channels/waba/${channelId}/profile/photo`);
    return result;
  }

  // remote manage channels
  async confirmTwoFactTelegramPersonalRemote({ guid, params }) {
    const result = await this.$apiV2.$post(`/qrlink/channels/tgapi/${guid}/2fa`, params, {
      ignoreNotify: true
    });
    return result;
  }

  async addChannelRemote({ transport }) {
    const result = await this.$apiV2.$post(`/qrlink/channels/${transport}`, {}, { timeout: 30000 });
    return result;
  }

  async generateQrLink(data) {
    const result = await this.$apiV2.$post('/qrlink/generate', data);
    return result;
  }

  async checkQrToken() {
    const result = await this.$apiV2.$get('/qrlink/check', { ignoreNotify: true });
    return result;
  }

  async reInitRemote(guid, transport, params) {
    const result = await this.$apiV2.$post(`qrlink/channels/${transport}/${guid}/reinit`, params);
    return result;
  }

  async getChannelsQrlink(params) {
    const result = await this.$apiV2.$get('qrlink/channels/list', { params });
    return result;
  }

  async getGupshupFacebookLink(channelId) {
    const result = await this.getInjectedAxiosInstance().$get(`/channels/waba/migration/${channelId}/link`);
    return result;
  }

  async setGupshupWabaId(channelId, wabaId) {
    const result = await this.getInjectedAxiosInstance().$post(`/channels/waba/migration/${channelId}/new-waba`, {
      wabaId
    });
    return result;
  }

  async getGupshupOTPRequest(channelId, data) {
    const result = await this.getInjectedAxiosInstance().$post(
      `/channels/waba/migration/${channelId}/otp/request`,
      data
    );
    return result;
  }

  async getGupshupOTPSubmit(channelId, data) {
    const result = await this.getInjectedAxiosInstance().$post(
      `/channels/waba/migration/${channelId}/otp/submit`,
      data
    );
    return result;
  }
}
