export default {
  async getPartnerBalance({ commit }) {
    const { data } = await this.$services.billing.getCurrentUserBalance();
    commit('SET_PARTNER_BALANCE', data.balance);
    commit('SET_PARTNER_DISCOUNT', data.discount);
    commit('SET_PARTNER_CURRENCY', data.currency);
    commit('SET_CLIENT_BALANCE', data.balanceByAccount);
    commit('SET_CLIENT_CURRENCY', data.currencyByAccount);
    commit('SET_PARTNER_WABA_DISCOUNT', data.discountWaba);
  },

  async getRequisites({ commit }) {
    const { data } = await this.$services.requisites.getRequisites();
    commit('SET_REQUISITES', data);
  },

  async getExchangeRates({ commit }) {
    const { data } = await this.$services.billing.exchangeRates();
    commit('SET_EXCHANGE_RATES', data);
  }
};
