import { mapState, mapGetters } from 'vuex';
import { channelTypes } from '~/enums/channels';

export default {
  computed: {
    ...mapState('channels', ['channels']),
    ...mapState('chat', ['currentChat']),
    ...mapGetters('chat', ['lastIncomingMessagePastTime']),

    instagramChat() {
      const currentChannel = this.channels.find(
        (channel) => channel.transport === channelTypes.instagram && channel.guid === this.currentChat.channelId
      );
      return !!currentChannel;
    },

    instagramApi() {
      const currentChannel = this.channels.find(
        (channel) =>
          channel.transport === channelTypes.instagram &&
          channel.guid === this.currentChat.channelId &&
          channel.details.instAPI
      );
      return !!currentChannel;
    },

    instagramApiAllowWindowDays() {
      if (this.lastIncomingMessagePastTime === undefined) return undefined;
      return this.lastIncomingMessagePastTime / 1000 / 60 / 60 / 24;
    },

    instagramApiAllowWindowExpired() {
      return (
        this.instagramApi && (this.instagramApiAllowWindowDays === undefined || this.instagramApiAllowWindowDays >= 7)
      );
    }
  }
};
