export default {
  hasActiveDeals(state) {
    if (!state.integrationEntities.deals) return false;
    const activeDealsCount = state.integrationEntities.deals.reduce((acc, deal) => {
      return acc + Number(!deal.closed);
    }, 0);
    return Boolean(activeDealsCount);
  },

  chatStubCanShowed(state, getters, rootState) {
    if (rootState.auth?.chatErrorState) return true;
    if (!state.contactsLoaded || state.contactSearchText?.length) return false;
    return !state.contacts?.length;
  },

  lastIncomingMessagePastTime(state) {
    if (!state.messagesLoaded) return undefined;

    const lastIncoming = state.messages.find((message) => message.incoming);
    const now = Date.now();

    if (!lastIncoming) return undefined;

    return now - lastIncoming.datetime;
  }
};
