import * as MESSAGES from '~/enums/messages';

export default {
  async getContacts({ commit }, params = {}) {
    const { limit = 50, offset = 0, search = '', clearAll = false } = params;
    try {
      const result = await this.$services.contacts.getContacts({
        limit,
        offset,
        search,
        channelsFilter: this.state.chat.channelsFilter
      });
      if (result) {
        if (clearAll) {
          commit('SET_CONTACTS_CLEAR', result.data);
        } else {
          commit('SET_CONTACTS', result.data);
        }
        if (result.data.length < limit) commit('SET_CONTACTS_LOADED', true);
      }
    } catch (err) {
      console.log(err);
    }
  },

  async getContact({ commit }, params) {
    const result = await this.$services.contacts.getContact(params);
    commit('SET_CONTACT', result.data[0]);
    return result.data[0];
  },

  setContact({ commit }, contact) {
    commit('SET_CONTACT', contact);
  },

  clearContactsLoaded({ commit }) {
    commit('SET_CONTACTS_LOADED', false);
  },

  async getWazzupTemplates({ commit }) {
    const result = await this.$services.chats.getWazzupTemplates();
    if (result) {
      commit(
        'SET_WAZZUP_TEMPLATES',
        result.map((el) => Object.freeze(el))
      );
    }
  },

  async getWabaTemplates({ commit }, params) {
    const result = await this.$services.chats.getWabaTemplates(params);
    if (result.templates) {
      commit('SET_WABA_TEMPLATES', { templates: result.templates.map((el) => Object.freeze(el)) });
    }
  },

  clearContacts({ commit }) {
    commit('CLEAR_CONTACTS');
  },

  async getMessages({ commit }, params) {
    const direction = 'desc';
    commit('SET_MESSAGES_LOADED', false);
    const result = await this.$services.chats.getMessages({ params: { ...params, direction } });
    if (result) {
      commit('SET_MESSAGES', result.messages);
      commit('SET_MESSAGES_LOADED', true);
    }
    return result;
  },

  clearMessages({ commit }) {
    commit('CLEAR_MESSAGES');
  },

  setCurrentChat({ commit }, chat) {
    commit('SET_CURRENT_CHAT', chat);
  },

  updateMessageStatus({ commit }, data) {
    commit('UPDATE_MESSAGE_STATUS', data);
    // пришло сообщение, которое хотели отправить в забаненый канала ВАБА
    // нужно перезапросить каналы, что бы активировалась заглушка в текстовом поле
    if (data?.data?.message?.status === MESSAGES.STATUS_WABA_BANNED) {
      this.dispatch('channels/getChannels');
    }
  },

  setSttMessage({ commit }, data) {
    commit('SET_STT_MESSAGE', data);
  },

  setMessage({ commit }, data) {
    commit('SET_MESSAGE', data);

    // пришло сообщение, которое хотели отправить в забаненый канала ВАБА
    // нужно перезапросить каналы, что бы активировалась заглушка в текстовом поле
    if (data?.data?.message?.status === MESSAGES.STATUS_WABA_BANNED) {
      this.dispatch('channels/getChannels');
    }
  },

  clearUnanswered({ commit }, data) {
    commit('CLEAR_UNANSWERED', data);
  },

  clearAllUnanswered({ commit }) {
    commit('CLEAR_ALL_UNANSWERED');
  },

  chatAccessGranted({ commit }, data) {
    commit('CHAT_ACCESS_GRANTED', data);
  },

  chatAccessDenied({ commit }, data) {
    commit('CHAT_ACCESS_DENIED', data);
  },

  async getIntegrationEntities({ commit }, params) {
    const result = await this.$services.chats.getIntegrationEntities(params);
    commit('SET_INTEGRATION_ENTITIES', result);
  },

  createVirtualContact({ commit }, params) {
    commit('CREATE_VIRTUAL_CONTACT', params);
  },

  setContactSearchText({ commit }, text) {
    commit('SET_CONTACT_SEARCH_TEXT', text);
  },

  setMessagesSending({ commit }, messagesSending) {
    commit('SET_MESSAGES_SENDING', messagesSending);
  },

  async getBalanceByChannel({ commit }, channelId) {
    const balance = await this.$services.chats.getBalanceByChannel(channelId);
    commit('SET_CURRENT_CHAT_BALANCE', balance.balance);
  },

  setOnceScrolled({ commit }) {
    commit('SET_ONCE_SCROLLED');
  },

  setSttEvent({ commit }, data) {
    commit('SET_STT_EVENT', data);
  },

  setChannelsFilter({ commit }, data) {
    commit('SET_CHANNELS_FILTER', data);
  }
};
