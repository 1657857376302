import dayjs from 'dayjs';
import { channelTypes } from '~/enums/channels';
import { chatTypes } from '~/enums/chats';
import { crmTypes } from '~/enums/integrations';
import { getTransportIcon } from '~/utils/getTransportIcon';
import * as MESSAGES from '~/enums/messages';
import { filenameShorter } from '~/utils/common';
import { headerTypes } from '~/enums/templates';

export const computedAvailableChannels = (channels, chatType) => {
  if (!channels.length) return [];

  const computedChatType = chatType === chatTypes.whatsgroup ? chatTypes.whatsapp : chatType;
  const computedChannels = channels.filter((item) => {
    const computedTransport =
      item.transport === channelTypes.wapi
        ? channelTypes.whatsapp
        : item.transport === channelTypes.tgapi
        ? channelTypes.telegram
        : item.transport;

    if (computedTransport === channelTypes.instagram && item?.details?.instAPI) return false;
    if (item.transport === channelTypes.telegram) return false;

    const allowedTransportsToChatType = {
      [chatTypes.whatsapp]: [channelTypes.telegram, channelTypes.viber],
      [chatTypes.viber]: [channelTypes.telegram, channelTypes.whatsapp]
    };

    return (
      (computedTransport === computedChatType ||
        (allowedTransportsToChatType[computedChatType] &&
          allowedTransportsToChatType[computedChatType].includes(computedTransport))) &&
      !item.deleted
    );
  });

  return computedChannels
    .map(({ guid, transport, name, details: { instAPI } }) => {
      return {
        value: guid,
        text: {
          icon: getTransportIcon(instAPI ? channelTypes.instAPI : transport).icon,
          color: getTransportIcon(instAPI ? channelTypes.instAPI : transport).color,
          text: name,
          transport,
          chatType:
            transport === channelTypes.tgapi
              ? chatTypes.telegram
              : transport === channelTypes.viber
              ? chatTypes.viber
              : [channelTypes.wapi, channelTypes.whatsapp].includes(transport)
              ? chatTypes.whatsapp
              : computedChatType
        }
      };
    })
    .sort((a, b) => {
      const computedTransport =
        a.text.transport === channelTypes.wapi
          ? channelTypes.whatsapp
          : a.text.transport === channelTypes.tgapi
          ? channelTypes.telegram
          : a.text.transport;
      return computedTransport === chatTypes.whatsapp ? -1 : 1;
    });
};

export const computedMessageHeader = ({ isAllDialogs, $i18n, message }) => {
  if (!message) return '';

  const { incoming } = message;
  const { whatsapp, whatsgroup, facebook, vk, telegram, wazzup, avito, telegroup, viber } = chatTypes;
  let headerText = '';

  if (incoming) {
    headerText =
      ([whatsapp, viber].includes(message.chatType)
        ? '+'
        : [whatsgroup, facebook, telegroup].includes(message.chatType)
        ? ''
        : '@') + message.authorName;
    if (message.chatType === vk) headerText = message.authorName;
    if (message.chatType === telegram)
      headerText = message.authorName
        ? `@${message.authorName}`
        : null ?? (message.authorPhone ? `+${message.authorPhone}` : message.chatId);
    if (message.vkPost || message.post) headerText += ` • ${$i18n.messages[$i18n.locale].chat.comment}`;
    if (message.chatType === wazzup) headerText = $i18n.messages[$i18n.locale].chat.wazzup;
    if (message.chatType === avito)
      headerText =
        message.type === MESSAGES.TYPE_SYSTEM
          ? $i18n.messages[$i18n.locale].settings.channels.types.avito
          : message.authorName
          ? `${message.authorName}`
          : `ID: ${message.chatId}`;
  } else {
    headerText = `${
      message.crmType
        ? message.crmName && message.crmType === 9
          ? message.crmName
          : crmTypes[message.crmType]
        : $i18n.messages[$i18n.locale].chat.wazzup
    } • ${message.authorName}`;
  }

  if (isAllDialogs) {
    headerText = message.channelName + ' • ' + headerText;
  }

  return headerText;
};

export const computedMessage = ({
  message,
  nextMessage,
  audioFileLoaded,
  storeBaseUrl,
  typeMessageWithButtons,
  accessibleChannels,
  isAllDialogs,
  $i18n
}) => {
  if (message.dateLabel) {
    return message;
  }

  let prefix;
  if (message.quotedMessage?.type === MESSAGES.TYPE_IMAGE) {
    prefix = $i18n.messages[$i18n.locale].chat.quotedPhoto;
  }
  if (message.quotedMessage?.type === MESSAGES.TYPE_AUDIO) {
    prefix = $i18n.messages[$i18n.locale].chat.quotedAudio;
  }
  if (message.quotedMessage?.type === MESSAGES.TYPE_VIDEO) {
    prefix = $i18n.messages[$i18n.locale].chat.quotedVideo;
  }
  if (message.quotedMessage?.type === MESSAGES.TYPE_DOC) {
    prefix = filenameShorter(message.quotedMessage.filename);
  }
  if (message.quotedMessage?.type === MESSAGES.TYPE_VCARD) {
    prefix = message.quotedMessage.vcard[0].fullName;
  }
  if (message.quotedMessage?.type === MESSAGES.TYPE_GEO) {
    prefix = $i18n.messages[$i18n.locale].chat.contacts.type.geo;
  }
  if (prefix) {
    prefix = message?.quotedMessage?.text?.length ? prefix + ', ' : prefix;
  }

  const incoming = message.incoming;
  let messageText;

  if ([MESSAGES.TYPE_UNSUPPORTED_BY_MESSENGER, MESSAGES.TYPE_UNSUPPORTED_BY_SERVICE].includes(message.type)) {
    messageText = incoming
      ? $i18n.messages[$i18n.locale].chat.unsupportedMessage.incoming
      : $i18n.messages[$i18n.locale].chat.unsupportedMessage.outgoing;
  } else if (typeMessageWithButtons) {
    messageText = message?.interactive?.text;
  } else {
    messageText = message.text || '';
  }

  const statusIcon = !audioFileLoaded
    ? MESSAGES.STATUSES[0].icon
    : MESSAGES.STATUSES[message.status]?.icon || MESSAGES.STATUSES[MESSAGES.STATUS_ERROR].icon;
  const statusColor = !audioFileLoaded
    ? MESSAGES.STATUSES[0].color
    : MESSAGES.STATUSES[message.status]?.color || MESSAGES.STATUSES[MESSAGES.STATUS_ERROR].color;
  const quotedMessage = message.quotedMessage
    ? {
        title: message.quotedMessage.authorName || $i18n.messages[$i18n.locale].chat.quotedYou,
        text: message.quotedMessage.text,
        url: message.quotedMessage.url
          ? message.quotedMessage.url
          : message.quotedMessage.contentSha && message.quotedMessage.filename
          ? `${storeBaseUrl}/${message.quotedMessage.contentSha}/?filename=${encodeURIComponent(
              message.quotedMessage.filename
            )}`
          : message.quotedMessage.contentSha
          ? `${storeBaseUrl}/${message.quotedMessage.contentSha}`
          : null,
        prefix,
        type: message.quotedMessage.type,
        id: message.quotedMessage.id
      }
    : message.post
    ? {
        title: $i18n.messages[$i18n.locale].chat.publication + dayjs(message.post.timestamp).format('DD.MM.YY'),
        text: message.post.description,
        url: `${storeBaseUrl}/${message.post.previewSha1}`,
        prefix: false,
        post: message.post,
        open: true
      }
    : message.story
    ? {
        title: $i18n.messages[$i18n.locale].chat.history,
        text: '',
        url: `${storeBaseUrl}/${message.contentSha}`
      }
    : {};

  const thisMessageHeader = computedMessageHeader({ isAllDialogs, $i18n, message });
  const nextMessageHeader = computedMessageHeader({ isAllDialogs, $i18n, message: nextMessage });
  const headerText =
    thisMessageHeader !== nextMessageHeader || message.chatType !== nextMessage.chatType ? thisMessageHeader : '';
  let headerIconTransport = '';

  if (isAllDialogs) {
    const channel = accessibleChannels.find((channel) => channel.guid === message.channelId);
    const transport = channel?.details?.instAPI ? channelTypes.instAPI : channel?.transport;

    headerIconTransport = getTransportIcon(transport);
  }

  const isImage =
    message.type === MESSAGES.TYPE_IMAGE ||
    message.template?.header?.format?.toLowerCase() === headerTypes.image.toLowerCase();

  const url = message.template?.header
    ? `${storeBaseUrl}/${message.template.header.sha1}`
    : message.url || `${storeBaseUrl}/${message.contentSha}`;

  const filename = message.template?.header?.filename || message.filename || $i18n.messages[$i18n.locale].chat.filename;

  const fileUrl = `${url}/?filename=${encodeURIComponent(filename)}`;

  return {
    id: message.id,
    isStt: message.isStt,
    direction: incoming ? 'incoming' : 'outgoing',
    text: messageText,
    transport: message.chatType,
    dateTime: dayjs(message.datetime).format('HH:mm'),
    fullDate: dayjs(message.datetime).format('DD.MM.YY, HH:mm'),
    statusIcon,
    statusColor,
    status: message.status,
    headerText,
    headerIconTransport,
    type: message.type,
    url,
    filename,
    post: message.post,
    story: message.story,
    contentType: message.template ? message.template.header?.contentType || message.contentType : message.contentType,
    author: message.authorName || $i18n.messages[$i18n.locale].chat.quotedYou,
    quotedMessage,
    vcard: message.vcard,
    location: message.location,
    template: message.template,
    isImage,
    fileUrl,
    disableQuoting: message.disableQuoting
  };
};
