export default () => ({
  account: null,
  xAccountId: null,
  users: [],
  user: null,
  iFrameToken: null,
  integrationInfo: null,
  chatErrorState: null,
  accessToken: null,
  cabinetInIframe: null
});
