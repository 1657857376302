import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const codeValidator = () => (value) => {
  /* eslint-disable-next-line */
  const regex = /^\d{6}$/;

  if (!regex.test(String(value))) {
    throw new Error('errors.wrongCode');
  }
};

export const emailValidator = (text) => (value) => {
  /* eslint-disable-next-line */
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!regex.test(String(value))) {
    throw new Error(text);
  }
};

export const requiredFieldValidator = () => (value) => {
  if (!value) {
    throw new Error('errors.emptyField');
  }
};

export const numberTypeValidator = (text) => (value) => {
  const regex = /^\d+$/;
  if (!regex.test(value)) {
    throw new Error(text);
  }
};

export const confirmPasswordValidator = (password) => (value) => {
  if (value !== password) {
    throw new Error('errors.confirmPassword');
  }
};

export const phoneValidator = () => (value) => {
  const { code, iso2, number } = value;

  if (!code || !number) {
    throw new Error('errors.emptyField');
  }

  if (!parsePhoneNumberFromString(`${code}${number}`, iso2?.toUpperCase()).isValid()) {
    throw new Error('errors.incorrectPhone');
  }
};

export const stringLengthValidator = (length, type, text) => (value) => {
  if (type === 'above' && value.length > length) {
    throw new Error(text);
  }
  if (type === 'less' && value.length < length) {
    throw new Error(text);
  }
  if (type === 'equal' && value.length !== length) {
    throw new Error(text);
  }
  if (type === 'not-equal' && value.length === length) {
    throw new Error(text);
  }
};

export const numberPositiveValidator = (text) => (value) => {
  if (value <= 0) {
    throw new Error(text);
  }
};

export const URLValidator = () => (value) => {
  const regex = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-zа-я\\d]([a-zа-я\\d-]*[a-zа-я\\d])*)\\.)+[a-zа-я]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '([\\?\\#][;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator

  if (!regex.test(String(value))) {
    throw new Error('errors.notURL');
  }
};

export const stripeRequisiteTextFieldValidator = () => (value) => {
  const regex = /^[a-zĀāČčĒēĢģĪīĶķĻļŅņŠšŪūŽž0-9\s'`‘’,./-]+$/i;
  if (!regex.test(String(value))) {
    throw new Error('errors.stripeRequisiteTextFieldNotValid');
  }
};

export const validate = (form, rules) => {
  return Object.entries(rules).reduce((errors, [fieldKey, validationOrObject]) => {
    if (!Array.isArray(validationOrObject) && typeof validationOrObject === 'object') {
      errors[fieldKey] = validate(form[fieldKey], validationOrObject);
    } else if (!Array.isArray(validationOrObject)) {
      const validator = validationOrObject;
      try {
        validator(form[fieldKey]);
      } catch (err) {
        errors[fieldKey] = err.message;
      }
    } else {
      validationOrObject.forEach((validator) => {
        try {
          validator(form[fieldKey]);
        } catch (err) {
          errors[fieldKey] = err.message;
        }
      });
    }
    return errors;
  }, {});
};
