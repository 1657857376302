import DOMPurify from 'dompurify';
import { channelTypes } from '~/enums/channels';
import { timezones } from '~/enums/timezones';

export const clearPhone = (phone) => {
  let result = parseInt(phone.replace(/[^0-9]/g, '')).toString();
  if (result.length === 13 || result.length === 14) {
    if (result.startsWith('5545') && result.charAt(4) === 9 && result.length === 13) {
      // --> 55 45 9 84133141
      result = result.substr(0, 4) + result.substr(5);
    } else if (result.charAt(5) === 9 && result.length === 14) {
      // --> 55 243 9 14523685
      result = result.substr(0, 5) + result.substr(6);
    }
  } else if (result.length < 8 || result.length > 20 || (result.substr(0, 1) === '7' && result.length !== 11)) {
    result = null;
  }
  return result;
};

export const getMsFromDays = (days) => days * 24 * 60 * 60 * 1000;

export const getDaysFromMs = (ms) => ms / (24 * 60 * 60 * 1000);

export const getFormatUrl = (url) => {
  const protocol = 'https://';

  const outUrl = url.startsWith(protocol) ? url : `${protocol}${url.replace('http://', '')}`;

  return outUrl.replace(/\/$/, '');
};

export const clearPathFromAccountId = (path) => {
  return path.replace(/^\/\d{4}-\d{4}/, '');
};

export const formatAccountId = (id) => {
  return id ? `${id}`.match(/\d{1,4}/g).join('-') : '';
};

export const filenameShorter = (filename, length = 42) => {
  const halfLength = Math.floor((length - 3) / 2);
  return filename?.length > length
    ? filename.substr(0, halfLength) + '...' + filename.substr(-halfLength, halfLength)
    : filename;
};

export const getImageInfo = (file) => {
  return new Promise((resolve, reject) => {
    try {
      const image = new Image();
      const url = URL.createObjectURL(file);
      image.onload = () => {
        const result = { width: image.width, height: image.height };
        URL.revokeObjectURL(url);
        resolve(result);
      };
      image.src = url;
    } catch (err) {
      reject(err);
    }
  });
};

export const getSanitizedHTML = (data) => {
  return DOMPurify.sanitize(data);
};

export const deepCopy = (value) => {
  return JSON.parse(JSON.stringify(value));
};

export const deepEqual = (a, b) => {
  return JSON.stringify(a) === JSON.stringify(b);
};

export const sentryLog = (ctx, error, extra) => {
  if (!ctx?.$config?.env?.prod) {
    return console.log(error, extra);
  }
  ctx?.$sentry?.withScope((scope) => {
    Object.entries(extra || {}).forEach(([key, extraData]) => scope.setExtra(key, extraData));
    ctx.$sentry.captureException(error);
  });
};

export const formatChannelPhone = ({ phoneOrAccount, transport }) => {
  const { whatsapp, wapi, instagram, tgapi, telegram, avito, vk, viber } = channelTypes;

  if ([whatsapp, wapi, tgapi, viber].includes(transport)) {
    return '+' + phoneOrAccount;
  }

  if ([instagram, telegram].includes(transport)) {
    return '@' + phoneOrAccount;
  }

  if ([avito, vk].includes(transport)) {
    return 'ID: ' + phoneOrAccount;
  }

  return phoneOrAccount;
};

export const getTimezoneCode = (code) => {
  return timezones.find((timezone) => timezone.code === code).tzCode;
};
