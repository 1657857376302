//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mdiForumOutline } from '@mdi/js';
import { mapState, mapGetters } from 'vuex';
import dayjs from 'dayjs';
import { getTransportIcon } from '~/utils/getTransportIcon';
import showWAPITemplates from '~/mixins/showWAPITemplates';
import instagramApiAllowWindow from '~/mixins/instagramApiAllowWindow';
import { chatTypes } from '~/enums/chats';
import { channelTypes } from '~/enums/channels';
import { intergationTypes } from '~/enums/integrations';

export default {
  name: 'ChatHeader',

  mixins: [showWAPITemplates, instagramApiAllowWindow],

  data() {
    return {
      selectedChannel: {},
      addContactDialog: false
    };
  },

  computed: {
    // instagramApi from instagramApiAllowWindow
    // instagramApiAllowWindowDays from instagramApiAllowWindow
    ...mapState('auth', ['iFrameToken']),
    ...mapState('chat', ['currentChat']),
    ...mapState('channels', ['channels']),
    ...mapGetters('chat', ['chatStubCanShowed', 'lastIncomingMessagePastTime']),
    ...mapGetters('auth', ['decodedIFrameToken']),

    instagramApiAllowWindowTime() {
      return this.instagramApiAllowWindowDays >= 7
        ? '00:00'
        : 7 - this.instagramApiAllowWindowDays < 1
        ? dayjs(Math.floor(this.instagramApiAllowWindowDays * 24 * 60 * 60 * 1000))
            .utc()
            .format('HH:mm')
        : this.$tc('chat.days', Math.floor(7 - this.instagramApiAllowWindowDays));
    },

    deletedChat() {
      return this.currentChat?.isChannelDeleted;
    },

    instagramApiAllowWindowColor() {
      return this.instagramApiAllowWindowDays > 6 && this.instagramApiAllowWindowDays < 7
        ? 'yellow'
        : this.instagramApiAllowWindowDays <= 6
        ? 'green'
        : 'red';
    },

    icons() {
      return {
        mdiForumOutline
      };
    },

    showDeals() {
      const { amocrm, kommo, yclients } = intergationTypes;
      if (!this.isIFrame || this.decodedIFrameToken?.integration?.crm === yclients) return false;

      const { whatsapp, instagram, telegram, vk, avito, telegroup, whatsgroup, viber } = chatTypes;

      return (
        [whatsapp, instagram, telegram, vk, avito, viber].includes(this.currentChat.chatType) ||
        ([telegroup, whatsgroup].includes(this.currentChat.chatType) &&
          [amocrm, kommo].includes(this.decodedIFrameToken?.integration?.crm) &&
          this.$hasFeature('amoGroupChats'))
      );
    },

    miniWidth() {
      return this.$vuetify.breakpoint.width < 481;
    },

    showMiniInstagramTimer() {
      return this.instagramApi && this.lastIncomingMessagePastTime && this.miniWidth;
    },

    showInstagramTimer() {
      return this.instagramApi && this.lastIncomingMessagePastTime && !this.miniWidth;
    },

    isIFrame() {
      return this.iFrameToken && Object.keys(this.currentChat).length;
    },

    hasCurrentChat() {
      return !!Object.keys(this.currentChat).length;
    },

    showChannelInfo() {
      return this.currentChat.chatType !== chatTypes.wazzup;
    },

    transportIcon() {
      const channel = this.channels.find((channel) => channel.guid === this.currentChat.channelId);
      const transport = channel?.details?.instAPI ? channelTypes.instAPI : channel?.transport;

      return getTransportIcon(transport);
    },

    showHeader() {
      return this.hasCurrentChat && !this.currentChat.add && !this.chatStubCanShowed;
    },

    chatTypes() {
      return chatTypes;
    }
  },

  methods: {
    pad(number) {
      return number < 10 ? '0' + number : number;
    }
  }
};
