//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BaseConfirm',

  props: {
    title: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    confirmButton: {
      type: Object,
      default: () => ({})
    },
    cancelButton: {
      type: Object,
      default: () => ({})
    },
    additionalButton: {
      type: Object,
      default: () => ({})
    },
    width: {
      type: Number,
      default: 560
    }
  },

  data() {
    return {
      shown: false
    };
  },

  computed: {
    cancelButtonText() {
      return this.cancelButton.text;
    },

    confirmButtonText() {
      return this.confirmButton.text;
    },

    additionalButtonText() {
      return this.additionalButton.text;
    },

    cancelButtonProps() {
      return { large: true, text: true, color: 'primary', depressed: true, ...this.cancelButton.props };
    },

    confirmButtonProps() {
      return { large: true, depressed: true, color: 'primary', ...this.confirmButton.props };
    },

    additionalButtonProps() {
      return { large: true, text: true, color: 'primary', depressed: true, ...this.additionalButton.props };
    }
  },

  methods: {
    show() {
      this.shown = true;
    },

    close() {
      this.shown = false;
    }
  }
};
