export default {
  SET_PARTNER_BALANCE(state, partnerBalance) {
    state.partnerBalance = partnerBalance;
  },

  SET_PARTNER_DISCOUNT(state, partnerDiscount) {
    state.partnerDiscount = (partnerDiscount * 100).toFixed();
  },

  SET_PARTNER_WABA_DISCOUNT(state, partnerWabaDiscount) {
    state.partnerWabaDiscount = (partnerWabaDiscount * 100).toFixed();
  },

  SET_PARTNER_CURRENCY(state, partnerCurrency) {
    state.partnerCurrency = partnerCurrency;
  },

  SET_CLIENT_BALANCE(state, clientBalance) {
    state.clientBalance = clientBalance;
  },

  SET_CLIENT_CURRENCY(state, clientCurrency) {
    state.clientCurrency = clientCurrency;
  },

  SET_EXCHANGE_RATES(state, exchangeRates) {
    state.exchangeRates = exchangeRates;
  },

  SET_REQUISITES(state, requisites) {
    state.requisites = requisites;
  }
};
