import BaseService from '~/services/BaseService';

export default class ChatService extends BaseService {
  async uploadFile({ file, contentType, filename }, wrapInFromData = false, handler = () => {}) {
    let result;

    if (wrapInFromData) {
      const form = new FormData();
      form.append('file', file);

      result = await this.$apiStore.$post('/upload', form, {
        params: {
          ...(filename ? { filename } : {}),
          enctype: 'multipart/form-data'
        },
        timeout: 120000,
        onUploadProgress: handler
      });
    } else {
      result = await this.$apiStore.$post('/upload', file, {
        params: { ...(filename ? { filename } : {}) },
        timeout: 120000,
        onUploadProgress: handler,
        headers: { 'content-type': contentType }
      });
    }

    return result;
  }

  async sendMessage(data) {
    const result = await this.$apiV2.$post('/messages', data);
    return result;
  }

  async getMessages(params) {
    const result = await this.$apiV2.$get('/messages', { ...params });
    return result;
  }

  async firstTestMessage() {
    const result = await this.$apiV2.$post('/messages/firstTestMessage');
    return result;
  }

  async clearUnanswered(params) {
    const result = await this.$apiV2.$patch('/chats/clearUnanswered', { ...params });
    return result;
  }

  async clearUnansweredAll(channelId) {
    const result = await this.$apiV2.$post('/chats/clearUnansweredAll', { channelId });
    return result;
  }

  async getIntegrationEntities(params) {
    const result = await this.$apiV2.$get('/integration/entities', { params: { ...params } });
    return result;
  }

  async addDeal(params) {
    const result = await this.$apiV2.$post('/integration/entities', params);
    return result;
  }

  async getBalanceByChannel(channelId) {
    const result = await this.$apiV2.$get(`/subscriptions/getBalanceByChannel/${channelId}`);
    return result;
  }

  async getWazzupTemplates(params) {
    const result = await this.$apiV2.$get('/templates', { params: { order: 'order', ...params } });
    return result;
  }

  async getWabaTemplates(params) {
    const result = await this.$apiV2.$get('/templates/whatsapp/approvedByChannel', { params: { ...params } });
    return result;
  }

  async sttRecognize(params) {
    const result = await this.$apiV2.$post('/stt/recognize', params);
    return result;
  }

  async getTelegramTimer(channelId) {
    const result = await this.$apiV2.$get(`/chats/get-telegram-search-timer/${channelId}`);
    return result;
  }
}
