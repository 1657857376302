export default ({ app }, inject) => {
  const MAX_COUNT = 1000;
  const OVER_MAX_COUNT = 1100;

  if (!app.$config?.m) return;

  const counts = {};
  const audio = new Audio(require('~/assets/mp3/ding.mp3').default);
  let bc = null;

  const sendNotification = (title, body, icon, url) => {
    const notification = new Notification(title, { body, icon, silent: true, renotify: true, tag: title });
    notification.onshow = async () => {
      try {
        await audio.play();
      } catch {}
    };
    notification.onclick = () => {
      window.focus();
      app.router.push(url);
    };
  };

  try {
    bc = new BroadcastChannel('wazzup-web');
    bc.onmessage = (e) => {
      const { id, count } = e.data;
      if (id in counts) {
        counts[id].push({ count, me: false });
      } else {
        counts[id] = [{ count, me: false }];
      }
    };
  } catch (err) {
    console.log(err);
  }

  const showNotification = (title, body, icon, url, id) => {
    if (bc) {
      const count = document.visibilityState === 'visible' ? OVER_MAX_COUNT : Math.ceil(Math.random() * MAX_COUNT);
      if (id in counts) {
        counts[id].push({ count, me: true });
      } else {
        counts[id] = [{ count, me: true }];
      }

      bc.postMessage({
        id,
        count
      });

      setTimeout(() => {
        let max = null;
        for (let i = 0; i < counts[id].length; i++) {
          const item = counts[id][i];
          if (max === null || item.count >= max.count) max = item;
        }

        if (max.me && max.count <= MAX_COUNT) {
          bc.postMessage({
            id,
            count: OVER_MAX_COUNT
          });
          delete counts[id];
          sendNotification(title, body, icon, url);
        } else {
          delete counts[id];
        }
      }, 1000);
    } else if (document.visibilityState !== 'visible') {
      sendNotification(title, body, icon, url);
    }
  };

  inject('showNotification', showNotification);
};
