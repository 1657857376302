export default {
  SET_ADMINS(state, data) {
    // с бэкенда могут приходить удаленные и несуществующие каналы
    // поэтому аккуратно фильтруем весь хлам, что бы кнопка "сохранить" не активировалась по ошибке
    const { channels } = this.state.channels;

    data.forEach((admin) => {
      admin.notifications.channels = admin.notifications.channels.filter((channel) => {
        return !!channels.find((item) => channel.guid === item.guid && !item.deleted);
      });
    });

    state.admins = data.sort((a, b) => {
      return (a.name || '').toLowerCase() < (b.name || '').toLowerCase() ? -1 : 1;
    });
  }
};
