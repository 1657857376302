import Vue from 'vue';
import BaseConfirm from '~/components/base/BaseConfirm';

export default ({ $vuetify, app }, inject) => {
  const BaseConfirmClass = Vue.extend(BaseConfirm);
  const animationDuration = 250;

  const confirm = ({ title, content, confirmButton, cancelButton, additionalButton } = {}) => {
    return new Promise((resolve) => {
      const appElement = document.querySelector('#app');
      const { i18n } = app;
      const instance = new BaseConfirmClass({
        propsData: {
          title,
          content,
          confirmButton: {
            text: i18n.t('common.ok'),
            ...confirmButton
          },
          cancelButton: {
            text: i18n.t('common.cancel'),
            ...cancelButton
          },
          additionalButton: {
            text: i18n.t('common.apply'),
            ...additionalButton
          }
        }
      });
      instance.$vuetify = $vuetify;
      instance.$mount();

      const close = async () => {
        instance.close();
        await instance.$nextTick();
        setTimeout(() => {
          instance.$destroy();
          appElement.removeChild(instance.$el);
        }, animationDuration);
      };

      instance.$on('confirm', () => {
        resolve(true);
        close();
      });

      instance.$on('cancel', () => {
        resolve(false);
        close();
      });

      instance.$on('common', () => {
        resolve(additionalButton.data);
        close();
      });

      appElement.appendChild(instance.$el);
      instance.show();
    });
  };

  inject('confirm', confirm);
};
