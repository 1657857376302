import { channelStatuses, channelTypes } from '~/enums/channels';
import { colors } from '~/enums/colors';
import { deepCopy } from '~/utils/common';

const {
  deleted,
  onModeration,
  notInSubscription,
  notEnoughMoney,
  notEnoughBalance,
  dialogLimitExceeded,
  phoneUnavailable,
  openelsewhere,
  unauthorized,
  waitForPassword,
  init,
  approachingDialogueLimit,
  active,
  blocked,
  foreignphone,
  qridle,
  qr,
  trial,
  rejected
} = channelStatuses;

const { whatsapp, waba, wapi, instagram, tgapi, telegram, vk, avito, facebook, viber } = channelTypes;

export const getChannelStatus = (channel) => {
  const statuses = {
    [onModeration]: channel.state === onModeration && channel.transport === wapi,
    [deleted]: channel.deleted,
    [rejected]: channel.state === rejected,
    [notInSubscription]:
      (channel.state === notEnoughMoney && !channel?.details?.subscription.guid) ||
      (channel.state === blocked && !channel?.details?.subscription.guid),
    [notEnoughBalance]: channel?.details?.subscription?.balance < 0 && channel.transport === wapi,
    [unauthorized]: [qr, qridle, foreignphone, unauthorized, waitForPassword].includes(channel.state),
    [notEnoughMoney]: channel.state === notEnoughMoney,
    [dialogLimitExceeded]: channel.details.dialogLimitExceeded,
    [phoneUnavailable]: channel.state === phoneUnavailable,
    [openelsewhere]: channel.state === openelsewhere,
    [init]: channel.state === init,
    [approachingDialogueLimit]: channel.details.approachingDialogueLimit,
    [trial]: channel.state === active && channel?.details?.isTrial && !channel?.details?.subscription.guid,
    [active]: channel.state === active,
    [blocked]: channel.state === blocked && channel?.details?.subscription.guid
  };

  const channelStatus = Object.keys(statuses).find((status) => statuses[status]);

  return {
    name: channelStatus,
    textKey: `settings.channels.statuses.${channelStatus}`,
    textColor: getStatusColor(channelStatus)
  };
};

export const getStatusColor = (channelStatusName) => {
  const colorsForStatuses = {
    [colors.greylight]: [deleted],
    [colors.info]: [onModeration, init],
    [colors.warning]: [approachingDialogueLimit, trial],
    [colors.error]: [
      notInSubscription,
      notEnoughMoney,
      dialogLimitExceeded,
      phoneUnavailable,
      openelsewhere,
      unauthorized,
      blocked,
      notEnoughBalance,
      rejected
    ],
    [colors.green]: [active]
  };

  return Object.keys(colorsForStatuses).find((status) => colorsForStatuses[status].includes(channelStatusName));
};

export const getTextSubscription = (channelStatusName) => {
  const subscriptionsWithSubtext = [notEnoughMoney, notEnoughBalance, dialogLimitExceeded, approachingDialogueLimit];

  return subscriptionsWithSubtext.includes(channelStatusName)
    ? `settings.channels.subscriptionSecondaryTexts.${channelStatusName}`
    : null;
};

export const getChannelAlert = (channelStatusName, transport) => {
  const statusesWithAlert = [
    notInSubscription,
    dialogLimitExceeded,
    phoneUnavailable,
    openelsewhere,
    unauthorized,
    notEnoughMoney,
    notEnoughBalance,
    trial,
    rejected
  ];

  if (!statusesWithAlert.includes(channelStatusName)) return;

  return {
    type: getStatusColor(channelStatusName),
    textKey: `settings.channels.alerts.${
      [whatsapp, tgapi, viber].includes(transport) && channelStatusName === 'unauthorized'
        ? 'unauthorizedQR'
        : [wapi].includes(transport) && channelStatusName === 'unauthorized'
        ? 'unauthorizedWaba'
        : transport === viber && channelStatusName === openelsewhere
        ? 'openelsewhereViber'
        : transport === whatsapp && channelStatusName === openelsewhere
        ? 'openelsewhereWA'
        : channelStatusName
    }`
  };
};

export const getReconnectButtonText = (channelStatusName, transport) => {
  const buttons = {
    unauthorized: channelStatusName === unauthorized && ![whatsapp, tgapi, viber].includes(transport),
    unauthorizedQR: channelStatusName === unauthorized && [whatsapp, tgapi, viber].includes(transport),
    openelsewhere: channelStatusName === openelsewhere
  };

  const neededButton = Object.keys(buttons).find((button) => buttons[button]);

  return neededButton ? `settings.channels.channelListButtons.${neededButton}` : null;
};

export const getSortedByAlphabetTransportDeletedChannels = (channels) => {
  const channelsCopy = deepCopy(channels);

  const sortedAlphabetChannels = channelsCopy.sort((a, b) => {
    return (a?.name || '').toLowerCase() < (b?.name || '').toLowerCase() ? -1 : 1;
  });

  const sortOrderByTransport = [whatsapp, wapi, instagram, tgapi, telegram, vk, avito, viber];

  const notDeletedChannels = [].concat(
    sortOrderByTransport.reduce((accumulatorTransports, transport) => {
      return accumulatorTransports.concat(
        sortedAlphabetChannels.filter((channel) => channel.transport === transport && !channel.deleted)
      );
    }, [])
  );

  const deletedChannels = [].concat(
    sortOrderByTransport.reduce((accumulatorTransports, transport) => {
      return accumulatorTransports.concat(
        sortedAlphabetChannels.filter((channel) => channel.transport === transport && channel.deleted)
      );
    }, [])
  );

  return notDeletedChannels.concat(deletedChannels);
};

export const getSortedChannels = (channels) => {
  const channelsCopy = deepCopy(channels).map((channel) => {
    return {
      ...channel,
      gupshupUpdate:
        channel.transport === channelTypes.wapi &&
        !channel.details?.isGupshupWaba &&
        ![channelStatuses.onModeration, channelStatuses.init].includes(channel.state) &&
        !channel.deleted &&
        channel.state !== channelStatuses.init
    };
  });
  const sortedAlphabetChannels = channelsCopy.sort((a, b) => {
    return (a?.name || '').toLowerCase() < (b?.name || '').toLowerCase() ? -1 : 1;
  });

  const sortOrderByStatuses = [
    onModeration,
    phoneUnavailable,
    openelsewhere,
    rejected,
    unauthorized,
    notEnoughMoney,
    notEnoughBalance,
    dialogLimitExceeded,
    notInSubscription,
    approachingDialogueLimit,
    init,
    trial,
    active,
    blocked,
    deleted
  ];

  const sortOrderByTransport = [whatsapp, waba, wapi, instagram, tgapi, telegram, vk, avito, facebook, viber];

  const sortedByStatuses = sortOrderByStatuses.reduce((accumulatorStatuses, status) => {
    const channelsCurrentStatuses = sortedAlphabetChannels.filter((channel) => {
      return getChannelStatus(channel).name === status;
    });

    if (status === trial) {
      channelsCurrentStatuses.sort((a, b) => a.details.endOfTrial - b.details.endOfTrial);
    }

    return accumulatorStatuses.concat(
      sortOrderByTransport.reduce((accumulatorTransports, transport) => {
        return accumulatorTransports.concat(
          channelsCurrentStatuses.filter((channel) => channel.transport === transport)
        );
      }, [])
    );
  }, []);

  return sortedByStatuses.sort((channel) => {
    return channel.gupshupUpdate ? -1 : 1;
  });
};

export const getChannelsForNotifications = (channels) => {
  return deepCopy(channels)
    .sort((a, b) => {
      return (a.guid || '').toLowerCase() < (b.guid || '').toLowerCase() ? -1 : 1;
    })
    .map(({ guid, name, transport, phoneUnavailable }) => {
      return {
        guid,
        name,
        transport,
        ...(transport === 'whatsapp' ? { phoneUnavailable } : {})
      };
    });
};
