const getAutoresponders = async (commit, that) => {
  const result = await that.$services.autoresponders.getAutoresponders();
  commit('SET_AUTORESPONDERS', result);
};

export default {
  async getAutoresponders({ commit }) {
    await getAutoresponders(commit, this);
  },

  async saveAutoresponders({ commit }, data) {
    const result = await this.$services.autoresponders.saveAutoresponders(data);
    await getAutoresponders(commit, this);
    return result;
  },

  async addAutoresponder({ commit }, data) {
    const result = await this.$services.autoresponders.addAutoresponder(data);
    await getAutoresponders(commit, this);
    return result;
  },

  async deleteAutoresponder({ commit }, guid) {
    const result = await this.$services.autoresponders.deleteAutoresponder(guid);
    await getAutoresponders(commit, this);
    return result;
  },

  async renameAutoresponder({ commit }, data) {
    const result = await this.$services.autoresponders.renameAutoresponder(data);
    await getAutoresponders(commit, this);
    return result;
  }
};
