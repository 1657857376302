//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BaseDenseModalLayout',

  props: {
    subtitle: { type: Boolean },

    hideDividers: { type: Boolean },

    noActions: { type: Boolean }
  }
};
