import { render, staticRenderFns } from "./SettingsDrawerFooterMenu.vue?vue&type=template&id=9f4fe35e&scoped=true&"
import script from "./SettingsDrawerFooterMenu.vue?vue&type=script&lang=js&"
export * from "./SettingsDrawerFooterMenu.vue?vue&type=script&lang=js&"
import style0 from "./SettingsDrawerFooterMenu.vue?vue&type=style&index=0&id=9f4fe35e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9f4fe35e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SettingsDrawerDivider: require('/builds/wazzup/front/components/settings/settingsDrawer/SettingsDrawerDivider.vue').default,SettingsDrawerWidgetDialog: require('/builds/wazzup/front/components/settings/settingsDrawer/SettingsDrawerWidgetDialog.vue').default,SettingsDrawerSupportDialog: require('/builds/wazzup/front/components/settings/settingsDrawer/SettingsDrawerSupportDialog.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VDialog,VIcon,VList,VListItem,VListItemIcon,VListItemTitle,VTooltip})
