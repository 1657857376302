export const copyToClipboard = async function (that, str, options = { text: '', type: 'success', hideNotify: false }) {
  const { text, type, hideNotify } = options;
  try {
    await navigator.clipboard.writeText(str);
    if (!hideNotify) {
      that.$notify(text || that.$t('common.copied'), { type: type || 'success' });
    }
  } catch (error) {
    that.$copyModal(str);
  }
};
