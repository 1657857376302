//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from 'vuex';
import { mdiOpenInNew } from '@mdi/js';
import { iframeScopes } from '~/enums/iframe';
import { intergationTypes } from '~/enums/integrations';

export default {
  name: 'AppDrawer',

  props: {
    value: {
      type: Boolean
    },

    compactDrawer: {
      type: Boolean
    },

    promo751175: {
      type: Boolean
    }
  },

  computed: {
    ...mapState('auth', ['cabinetInIframe']),
    ...mapGetters('auth', ['decodedIFrameToken']),
    ...mapGetters('channels', ['showGupshup']),

    icons() {
      return {
        mdiOpenInNew
      };
    },

    showToSettings() {
      return (
        (this.decodedIFrameToken?.integration?.scope === iframeScopes.global &&
          this.decodedIFrameToken?.integration?.crm === intergationTypes.bitrix) ||
        this.cabinetInIframe
      );
    },

    drawer: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      }
    },

    showMiniVariant() {
      return (this.compactDrawer && this.drawer) || (!this.compactDrawer && !this.drawer);
    }
  }
};
