//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mdiClose } from '@mdi/js';
import promo751175 from '~/mixins/promo751175';

export default {
  name: 'AppPromoBanner',

  mixins: [promo751175], // promoPrice, currency

  computed: {
    icons() {
      return {
        mdiClose
      };
    }
  },

  methods: {
    hidePromo() {
      this.$emit('close');
    }
  }
};
