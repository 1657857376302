export default () => ({
  partnerBalance: null,
  partnerDiscount: 0,
  partnerWabaDiscount: 0,
  partnerCurrency: null,
  clientBalance: null,
  clientCurrency: null,
  exchangeRates: null,
  requisites: []
});
