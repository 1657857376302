import { integrationStates } from '~/enums/integrations';
import { errorStates } from '~/enums/errors';

export default async (ctx, inject) => {
  try {
    const { store, $config, $notify } = ctx;
    const i18n = ctx.app?.i18n ?? ctx.$i18n;
    const { accessToken, account } = store.state.auth;
    const { channels } = store.state.channels;
    const accountId = account?.id || store.getters['auth/decodedIFrameToken']?.accountId;
    const isTechPartner = store.getters['auth/decodedIFrameToken']?.techPartner;

    if (!accessToken || !accountId) return;

    const socket = await ctx.$nuxtSocket({
      path: '/events-sender/',
      auth: { token: accessToken },
      query: { ns: accountId },
      transports: ['websocket', 'polling'],
      reconnection: true,
      teardown: false
    });

    socket.on('disconnect', async (event) => {
      if (event === 'io server disconnect') {
        const accessTokenNew = await ctx.app.$services.auth.refresh();
        setTimeout(async () => {
          socket.auth.token = accessTokenNew;
          await socket.close();
          await socket.connect();
        }, 5000);
      }
    });

    socket.on('channelInit', (data) => {
      store.dispatch('channels/channelInit', data);
    });

    socket.on('channelUpdate', (data) => {
      store.dispatch('channels/channelUpdate', data);
    });

    if (!isTechPartner) {
      socket.on('updateAll', (data) => {
        store.dispatch('auth/setChatErrorState', null);
        store.dispatch('chat/getContacts', { clearAll: true });
        store.dispatch('auth/getCurrentAccount');
        store.dispatch('auth/getIntegrationInfo');
        store.dispatch('channels/getChannels');
      });

      socket.on('integrationsUpdateState', (data) => {
        if ([integrationStates.init, integrationStates.active].includes(data.state)) {
          store.dispatch('integrations/getIntegration');
        } else if (data.state === integrationStates.deleted && $config.m) {
          store.dispatch('auth/setChatErrorState', errorStates.integrationError);
        } else {
          store.dispatch('integrations/getIntegrationInfo');
        }
      });

      socket.on('wabaMigrationSuccessfull', (data) => {
        try {
          const channel = channels.find(({ guid }) => guid === data.channelId);
          const text = i18n.messages[i18n.locale].settings.channels.gupshup.successSnackbar.replace(
            '{name}',
            channel.name
          );
          $notify(text, { type: 'success' });
        } catch (err) {
          console.log(err);
        }
      });
    }

    inject('socket', socket);
  } catch (err) {
    console.log(err);
  }
};
