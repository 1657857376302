//
//
//
//
//
//
//
//
//
//
//

import { getCurrentLocaleCode } from '~/utils/currentLocaleCode';

export default {
  middleware: 'guest',

  head() {
    return {
      meta: [{ hid: 'viewport', name: 'viewport', content: 'width=device-width, initial-scale=1' }]
    };
  },

  computed: {
    getLogoSrc() {
      return this.$config.m ? '/web-logo.svg' : '/logo.svg';
    }
  },

  mounted() {
    const localeCode = (navigator.language || navigator.userLanguage).split('-').slice(0, 1).join('');

    this.$i18n.setLocale(getCurrentLocaleCode(localeCode));
  }
};
