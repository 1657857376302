export default {
  SET_CHANNELS(state, channels) {
    state.channels = channels;
    state.accessibleChannels = channels.filter((channel) => channel?.hasAccess);
  },

  SET_CHANNEL_INIT(state, data) {
    state.eventChannelInit = data;
  },

  CHANNEL_UPDATE(state, { eventData, actualChannelData }) {
    state.eventChannelUpdate = eventData;

    state.channels = state.channels.map((channel) => {
      if (channel?.guid === actualChannelData?.guid) {
        return actualChannelData;
      }

      return channel;
    });

    state.accessibleChannels = state.channels.filter((channel) => channel?.hasAccess);
  },

  ADD_CHANNEL(state, { eventData, actualChannelData }) {
    if (state.eventChannelUpdate?.guid !== actualChannelData?.guid) {
      state.channels.push(actualChannelData);

      if (actualChannelData?.hasAccess) {
        state.accessibleChannels.push(actualChannelData);
      }
    }

    state.eventChannelUpdate = eventData;
  },

  SET_VK_CHANNEL_ADDING_STATUS(state, status) {
    state.vkChannelAddingStatus = status;
  },

  SET_FIRST_CHANNEL_BY_ACCOUNT(state, status) {
    state.firstChannelByAccount = status;
  }
};
