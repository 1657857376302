export default () => ({
  contacts: [],
  contactSearchText: '',
  currentChat: {},
  currentChatBalance: null,
  wazzupTemplates: [],
  wabaTemplates: [],
  integrationEntities: {
    deals: [],
    companies: [],
    leads: [],
    contacts: [],
    allowCreateEntity: false
  },
  messages: [],
  messagesLoaded: false,
  contactsLoaded: false,
  messagesSending: false,
  onceScrolled: false,
  sttEvent: null,
  channelsFilter: []
});
