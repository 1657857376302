import BaseService from '~/services/BaseService';

export default class ReferralsService extends BaseService {
  async getPaymentsNew({ year, month, offset = 0, limit = 50 }) {
    const result = await this.$apiV2.$get('affiliate/clients/payments', { params: { year, month, offset, limit } });
    return result;
  }

  async getRefferalLinksNew() {
    const result = await this.$apiV2.$get('affiliate/refLinks');
    return result;
  }

  async addReferralLinkNew(data) {
    const result = await this.$apiV2.$post('affiliate/refLinks/create', data);
    return result;
  }

  async updateReferralLinkNew(data) {
    const result = await this.$apiV2.$patch(`affiliate/refLinks/update/${data.code}`, data);
    return result;
  }
}
