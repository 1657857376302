//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mdiDotsVertical, mdiMenu, mdiAccountOutline, mdiArrowLeft } from '@mdi/js';
import { mapState, mapActions, mapGetters } from 'vuex';
import { formatAccountId } from '~/utils/common';
import { accountTypes } from '~/enums/account';
import { iframeScopes } from '~/enums/iframe';
import { intergationTypes } from '~/enums/integrations';

const HEADER_HEIGHT_IN_PX = 64;
const MINIMIZED_HEADER_HEIGHT_IN_PX = 56;

export default {
  name: 'ChatDrawer',

  props: {
    value: {
      type: Boolean
    }
  },

  data() {
    return {
      searchText: '',
      searchQueryTimeout: null,
      loading: false,
      token: '',
      dialog: false
    };
  },

  computed: {
    ...mapState('auth', ['account', 'iFrameToken']),
    ...mapState('chat', ['contacts', 'contactsLoaded', 'channelsFilter']),
    ...mapGetters('chat', ['chatStubCanShowed']),
    ...mapGetters('auth', ['chatsForTechPartner', 'decodedIFrameToken', 'hasManagerRole']),

    icons() {
      return {
        mdiDotsVertical,
        mdiMenu,
        mdiAccountOutline,
        mdiArrowLeft
      };
    },

    toolbarHeight() {
      return this.$vuetify.breakpoint.width < 481 ? MINIMIZED_HEADER_HEIGHT_IN_PX : HEADER_HEIGHT_IN_PX;
    },

    showToSettings() {
      const isAdmin = this.decodedIFrameToken?.integration?.isAdmin;
      if (
        this.decodedIFrameToken?.integration?.scope === iframeScopes.global &&
        this.decodedIFrameToken?.integration?.crm === intergationTypes.bitrix &&
        isAdmin
      )
        return true;
      return !this.iFrameToken && !this.$config.m;
    },

    drawer: {
      set(v) {
        this.$emit('input', v);
      },
      get() {
        return this.value;
      }
    },

    getFormatAccountId() {
      return formatAccountId(this.account?.id);
    },

    computedSearchText() {
      return this.searchText ? this.searchText.replace(/\+|\s|\(|\)/g, '') : '';
    },

    isLogoutButtonShow() {
      return this.$config.m;
    },

    showAnalyticsLink() {
      const { scope } = this.decodedIFrameToken?.integration || {};

      return (
        (this.$config.m ||
          (scope === iframeScopes.global &&
            ![accountTypes.TECH_PARTNER_POSTPAY, accountTypes.CHILD_POSTPAY].includes(this.account?.type))) &&
        this.hasManagerRole
      );
    },

    showSearch() {
      return !(!this.loading && this.chatStubCanShowed && !this.searchText);
    }
  },

  watch: {
    async channelsFilter() {
      this.clearContactsLoaded();
      this.clearContacts();
      this.loading = true;
      await this.getContacts({ clearAll: true, search: this.searchText });
      this.loading = false;
    },
    searchText() {
      this.clearContactsLoaded();
      this.setContactSearchText(this.searchText);
      const getContacts = async () => {
        this.loading = true;
        await this.getContacts({ clearAll: true, search: this.searchText });
        this.loading = false;
      };
      clearTimeout(this.searchQueryTimeout);
      if (!this.searchText) {
        getContacts();
      } else if (this.searchText.length === 3) {
        getContacts();
      } else if (this.searchText.length > 3) {
        this.searchQueryTimeout = setTimeout(getContacts, 1000);
      } else {
        this.clearContacts();
      }
    }
  },

  mounted() {
    this.clearContactsLoaded();
  },

  beforeDestroy() {
    this.clearContacts();
    this.clearContactsLoaded();
  },

  methods: {
    ...mapActions('chat', ['getContacts', 'setContactSearchText', 'clearContactsLoaded', 'clearContacts']),

    async handleLoad() {
      if (this.loading || this.contactsLoaded) {
        return;
      }

      this.loading = true;
      if ((this.searchText && this.searchText.length > 2) || !this.searchText) {
        await this.getContacts({ search: this.searchText, offset: this.contacts.length });
      }
      this.loading = false;
    },

    copyToken() {
      this.token = this.iFrameToken || this.$cookies.get('accessToken');
      this.dialog = true;
    },

    logoutHandler() {
      this.$services.auth.logoutWeb();
    }
  }
};
