import { render, staticRenderFns } from "./AppPromoBanner.vue?vue&type=template&id=a0bf4c60&scoped=true&"
import script from "./AppPromoBanner.vue?vue&type=script&lang=js&"
export * from "./AppPromoBanner.vue?vue&type=script&lang=js&"
import style0 from "./AppPromoBanner.vue?vue&type=style&index=0&id=a0bf4c60&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a0bf4c60",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseHelpIcon: require('/builds/wazzup/front/components/base/BaseHelpIcon.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VIcon,VSpacer})
