//
//
//
//
//
//

export default {
  name: 'AppChatDrawer',

  props: {
    value: {
      type: Boolean
    }
  },

  computed: {
    drawer: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      }
    }
  }
};
