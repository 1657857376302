export default {
  computedWazzupTags(state) {
    return state.wazzupTags.map((tag) => {
      return {
        value: tag.guid,
        text: tag.name
      };
    });
  },

  computedWabaTags(state) {
    return state.wabaTags.map((tag) => {
      return {
        value: tag.guid,
        text: tag.name
      };
    });
  }
};
