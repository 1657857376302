//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import { mdiHelpCircleOutline, mdiFaceAgent, mdiChatProcessingOutline } from '@mdi/js';

export default {
  name: 'SettingsDrawerFooterMenu',

  props: {
    enableTooltips: {
      type: Boolean
    }
  },

  data() {
    return {
      showWidgetDialog: false,
      showSupportDialog: false
    };
  },

  computed: {
    ...mapState('auth', ['account']),

    approvedInterfaceLanguage() {
      return this.account?.lang === 'ru';
    },

    getFooterDrawerItems() {
      return [
        ...(this.approvedInterfaceLanguage
          ? [
              {
                onClick: () => (this.showWidgetDialog = true),
                text: this.$t('settings.titles.widget'),
                icon: mdiChatProcessingOutline
              }
            ]
          : []),
        {
          href: this.$t('settings.drawerMenuHelp.link'),
          text: this.$t('settings.drawerMenuHelp.title'),
          icon: mdiHelpCircleOutline,
          class: 'settings-drawer-footer-menu-help'
        },
        {
          onClick: () => (this.showSupportDialog = true),
          text: this.$t('settings.drawerMenuSupport.title'),
          icon: mdiFaceAgent
        }
      ];
    }
  }
};
