//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mdiPlus, mdiMenuDown, mdiMenuUp, mdiClose, mdiDotsVertical } from '@mdi/js';
import { mapState, mapActions, mapGetters } from 'vuex';
import { eventMessageTypes } from '~/enums/iframe';
import { deepCopy } from '~/utils/common';
import { intergationTypes } from '~/enums/integrations';

export default {
  name: 'HeaderDeals',

  data() {
    return {
      loading: true,
      menu: false,
      showDealTooltip: false
    };
  },

  computed: {
    ...mapState('chat', ['integrationEntities', 'currentChat']),
    ...mapGetters('chat', ['hasActiveDeals']),
    ...mapGetters('auth', ['decodedIFrameToken', 'clientTypeIs1C', 'integrationPipedrive', 'frontendData']),

    icons() {
      return {
        mdiPlus,
        mdiMenuDown,
        mdiMenuUp,
        mdiClose,
        mdiDotsVertical
      };
    },

    dealsTitle() {
      const { bitrix } = intergationTypes;
      const crms = [bitrix];
      if (crms.includes(this.decodedIFrameToken?.integration?.crm)) {
        return this.$t(`chat.deals.dealsTitle.${this.decodedIFrameToken.integration.crm}`);
      } else {
        return this.$t('chat.deals.dealsTitle.others');
      }
    },

    leadsTitle() {
      const { bitrix } = intergationTypes;
      const crms = [bitrix];
      if (crms.includes(this.decodedIFrameToken?.integration?.crm)) {
        return this.$t(`chat.deals.leadsTitle.${this.decodedIFrameToken.integration.crm}`);
      } else {
        return this.$t('chat.deals.leadsTitle.others');
      }
    },

    minimizeActivator() {
      return this.$vuetify.breakpoint.width < 481;
    },

    maxMenuHeight() {
      return this.minimizeActivator ? '396px' : '568px';
    },

    emptyMenu() {
      const { deals, companies, leads, contacts, allowCreateEntity } = this.integrationEntities;
      try {
        return !allowCreateEntity && !deals.length && !companies.lenght && !leads.length && !contacts.length;
      } catch {
        return !allowCreateEntity;
      }
    },

    showBadge() {
      return !this.integrationPipedrive && !this.hasActiveDeals;
    }
  },

  watch: {
    menu() {
      if (this.menu) {
        try {
          // eslint-disable-next-line no-undef
          ym(64950046, 'reachGoal', 'SuitBut');
        } catch (err) {
          console.log(err);
        }
      }
    }
  },

  async mounted() {
    const { chatType, chatId } = this.currentChat;
    try {
      await this.getIntegrationEntities({ chatType, chatId });
    } catch (err) {
      console.log(err);
    }
    this.loading = false;

    setTimeout(async () => {
      const frontendData = deepCopy(this.frontendData);
      if (!frontendData.onboardingDealsTooltipShown) {
        this.showDealTooltip = true;
        frontendData.onboardingDealsTooltipShown = true;
        await this.$services.user.setFrontendData(frontendData);
        await this.getCurrentAccount();
      }
    }, 1000);
  },

  methods: {
    ...mapActions('chat', ['getIntegrationEntities']),
    ...mapActions('auth', ['getCurrentAccount']),

    async createDeal() {
      const { chatType, chatId, channelId } = this.currentChat;

      if (this.decodedIFrameToken.integration?.options?.useDealsEvents) {
        const { userId, guid } = this.decodedIFrameToken.integration;

        window.parent.postMessage(
          { type: eventMessageTypes.createEntity, data: { chatType, chatId, channelId, userId, integrationId: guid } },
          '*'
        );
      } else {
        try {
          const result = await this.$services.chats.addDeal({
            chatType,
            chatId,
            channelId
          });

          window.open(result.url, '_blank');
          this.loading = true;
          await this.getIntegrationEntities({ chatType, chatId });
        } catch (err) {
          console.log(err);
        }
      }

      this.loading = false;
    },

    openEntity(entity) {
      if (this.decodedIFrameToken.integration?.options?.useDealsEvents) {
        const { chatType, chatId, channelId } = this.currentChat;
        const { userId, guid } = this.decodedIFrameToken?.integration;

        window.parent.postMessage(
          {
            type: eventMessageTypes.openEntity,
            data: { chatType, chatId, channelId, userId, integrationId: guid, entity }
          },
          '*'
        );
      } else {
        window.open(entity.link, '_blank');
      }
    }
  }
};
