import { intergationTypes } from '~/enums/integrations';

export default {
  async getIntegration({ commit }) {
    const integration = await this.$services.integrations.getIntegration();
    if (integration) {
      commit('SET_INTEGRATION_OPTIONS', integration.options);
      commit('SET_INTEGRATION_INFO', integration.info);
    }
  },

  async getIntegrationInfo({ commit }) {
    const integration = await this.$services.integrations.getIntegration();
    if (integration) {
      commit('SET_INTEGRATION_INFO', integration.info);
    }
  },

  async getApplications({ commit }) {
    const { amocrm, kommo, zoho, hubspot, megaplan, pipedrive } = intergationTypes;
    const applications = await this.$services.integrations.getApplications();
    const crmsWithBasicForms = [amocrm, kommo, zoho, hubspot, megaplan, pipedrive];

    if (applications) {
      const computedApplications = applications.map(({ name, type, logo, needDomain, helpLinks }) => {
        return {
          name,
          type,
          helpLinks,
          componentAddIntegrationForm: crmsWithBasicForms.includes(type)
            ? 'forms-base-add'
            : !Object.values(intergationTypes).includes(type)
            ? 'forms-add-custom-integration'
            : `forms-add-${name.toLowerCase()}`,
          crmLogo: logo.sha1 ? `${this.$config.storeBaseUrl}/${logo.sha1}` : '/logo-empty.svg',
          hasModalConfigurateInCrm: true,
          availableForAdd: true,
          marketplace: true,
          needDomain
        };
      });
      commit('SET_APPLICATIONS', computedApplications);
    }
  }
};
