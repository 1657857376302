import BaseService from './BaseService';

export default class IntegrationsService extends BaseService {
  async getIntegration() {
    const result = await this.$apiV2.$get('/integrations');
    return result;
  }

  async addIntegration(data) {
    const result = await this.$apiV2.$post('/integrations', data);
    return result;
  }

  async putIntegrationOptions(options) {
    const result = await this.$apiV2.$put('/integrations/options', { options });
    return result;
  }

  async deleteIntegration() {
    const result = await this.$apiV2.$delete('/integrations');
    return result;
  }

  async updateIntegrationInfo() {
    const result = await this.$apiV2.$get('/integrations/update', { timeout: 60000 });
    return result;
  }

  async getLogs({ accountId, offset, limit }) {
    const result = await this.$apiV2.$get('/eventLog/integration', { params: { offset, limit } });
    return result;
  }

  async reconnectIntegration() {
    const result = await this.$apiV2.$post('/integrations/reconnect');
    return result;
  }

  async getApplications() {
    const result = await this.$apiV2.$get('/applications');
    return result;
  }

  async getGlobalChatUrl() {
    const result = await this.$apiV2.$get('/integrations/getGlobalChatUrl');
    return result;
  }
}
