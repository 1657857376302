import { mdiCheck, mdiClose } from '@mdi/js';
import { subscriptionTypes, states } from '~/enums/subscriptions';
import { deepCopy } from '~/utils/common';

const filteredSubscriptions = (state) => {
  const promoTypes = [subscriptionTypes.whatsapp, subscriptionTypes.tgapi, subscriptionTypes.instagram];
  return state.subscriptions.filter(
    (subscription) =>
      promoTypes.includes(subscription.type) && !subscription.isFree && subscription.state !== states.notPaid
  );
};

export default {
  discountPercentageByPeriod(state) {
    return state.subscriptionsConstants?.discountPercentageByPeriod;
  },

  subscriptionTariffsByType(state) {
    const featuresKeysToArray = (featuresTextKeys, transport) => {
      const result = [];

      if (featuresTextKeys.canWriteFirst) {
        result.push({ icon: mdiCheck, color: 'primary', value: 'settings.subscriptions.tariffFeatures.canWriteFirst' });
      }

      if (featuresTextKeys.canWriteFirst === false) {
        result.push({ icon: mdiClose, color: 'error', value: 'settings.subscriptions.tariffFeatures.cantWriteFirst' });
      }

      if (!featuresTextKeys.limitedDialogsQuantity) {
        const { waba, telegram, vk } = subscriptionTypes;
        const value = `settings.subscriptions.tariffFeatures.${
          [waba, telegram, vk].includes(transport) ? 'unlimitedDialogs' : 'notLimitedDialogsQuantity'
        }`;

        result.push({
          icon: mdiCheck,
          color: 'primary',
          value
        });
      } else {
        result.push({
          icon: mdiCheck,
          color: 'primary',
          value: 'settings.subscriptions.tariffFeatures.limitedDialogsQuantity',
          quantity: featuresTextKeys.limitedDialogsQuantity
        });
      }

      if (featuresTextKeys.speechToTextAllowed) {
        const value = 'settings.subscriptions.tariffFeatures.speechToTextAllowed';
        result.push({
          icon: mdiCheck,
          color: 'primary',
          value
        });
      }

      return result;
    };

    const tariffs = deepCopy(state.subscriptionsConstants?.subscriptionTariffsByType);
    const result = {};
    Object.keys(tariffs).forEach((transport) => {
      result[transport] = tariffs[transport].map(({ value, priceByCurrency, featuresTextKeys }) => {
        return {
          value,
          priceByCurrency,
          featuresTextKeys: featuresKeysToArray(featuresTextKeys, transport),
          limitedDialogsQuantity: featuresTextKeys.limitedDialogsQuantity,
          canWriteFirst: featuresTextKeys.canWriteFirst
        };
      });
    });
    return result;
  },

  subscriptionTariffsByTypePromo(state) {
    const featuresKeysToArray = (featuresTextKeys, transport) => {
      const result = [];

      if (featuresTextKeys.canWriteFirst) {
        result.push({ icon: mdiCheck, color: 'primary', value: 'settings.subscriptions.tariffFeatures.canWriteFirst' });
      }

      if (featuresTextKeys.canWriteFirst === false) {
        result.push({ icon: mdiClose, color: 'error', value: 'settings.subscriptions.tariffFeatures.cantWriteFirst' });
      }

      if (!featuresTextKeys.limitedDialogsQuantity) {
        const { waba, telegram, vk } = subscriptionTypes;
        const value = `settings.subscriptions.tariffFeatures.${
          [waba, telegram, vk].includes(transport) ? 'unlimitedDialogs' : 'notLimitedDialogsQuantity'
        }`;

        result.push({
          icon: mdiCheck,
          color: 'primary',
          value
        });
      } else {
        result.push({
          icon: mdiCheck,
          color: 'primary',
          value: 'settings.subscriptions.tariffFeatures.limitedDialogsQuantity',
          quantity: featuresTextKeys.limitedDialogsQuantity
        });
      }

      if (featuresTextKeys.speechToTextAllowed) {
        const value = 'settings.subscriptions.tariffFeatures.speechToTextAllowed';
        result.push({
          icon: mdiCheck,
          color: 'primary',
          value
        });
      }

      return result;
    };

    const tariffs = deepCopy(state.subscriptionsConstants?.subscriptionTariffsByType);
    // модификация цен под промо акцию 751175
    if (state.subscriptionsConstants?.promoPrices751175) {
      const subscriptionsWithNewPrice = [
        subscriptionTypes.whatsapp,
        subscriptionTypes.tgapi,
        subscriptionTypes.instagram
      ];
      subscriptionsWithNewPrice.forEach((type) => {
        tariffs[type] = tariffs[type].map((item) => {
          const priceByCurrency = {
            ...item.priceByCurrency,
            KZT: state.subscriptionsConstants?.promoPrices751175.whatsapp.KZT.max,
            RUR: state.subscriptionsConstants?.promoPrices751175.whatsapp.RUR.max
          };
          return {
            ...item,
            priceByCurrency,
            actualPriceByCurrency: item.actualPriceByCurrency
          };
        });
      });
    }
    const result = {};
    Object.keys(tariffs).forEach((transport) => {
      result[transport] = tariffs[transport].map(({ value, priceByCurrency, featuresTextKeys }) => {
        return {
          value,
          priceByCurrency,
          featuresTextKeys: featuresKeysToArray(featuresTextKeys, transport),
          limitedDialogsQuantity: featuresTextKeys.limitedDialogsQuantity,
          canWriteFirst: featuresTextKeys.canWriteFirst
        };
      });
    });
    return result;
  },

  promo751175(state) {
    return !!state.subscriptionsConstants?.promoPrices751175;
  },

  showAlertPromo751175(state, getters, rootState, rootGetters) {
    return (
      !!state.subscriptionsConstants?.promoPrices751175 &&
      !rootGetters['auth/frontendData']?.promoPrices751175 &&
      !filteredSubscriptions(state).length
    );
  },

  canAddPromoSubscription(state, getters, rootState, rootGetters) {
    const integratorAccount = rootGetters['auth/onlyIntegratorAccount'];
    return (
      !!state.subscriptionsConstants?.promoPrices751175 && !filteredSubscriptions(state).length && !integratorAccount
    );
  },

  canAddPromoSubscriptionIntegrator(state, getters, rootState, rootGetters) {
    const integratorAccount = rootGetters['auth/onlyIntegratorAccount'];
    return (
      !!state.subscriptionsConstants?.promoPrices751175 && !filteredSubscriptions(state).length && integratorAccount
    );
  }
};
