//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from 'vuex';
import { errorStates } from '~/enums/errors';
import { deepCopy } from '~/utils/common';
import { intergationTypes } from '~/enums/integrations';

export default {
  name: 'DrawerContactList',

  props: {
    searchText: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean
    }
  },

  computed: {
    ...mapState('chat', ['contacts', 'channelsFilter']),
    ...mapState('channels', ['channels']),
    ...mapState('auth', ['chatErrorState']),
    ...mapGetters('auth', ['decodedIFrameToken']),
    ...mapGetters('chat', ['chatStubCanShowed']),

    contactListStub() {
      if ((this.searchText || this.channelsFilter.length) && !this.computedContacts.length) {
        return 'chat.drawerStub.contactsNotFound';
      }
      if (!this.channels.length) return 'chat.drawerStub.channelsNotFound';

      if (this.chatStubCanShowed) {
        if (this.chatErrorState === errorStates.userHasNoRole) {
          return 'chat.drawerStub.roleNotFound';
        } else if (this.chatErrorState === errorStates.integrationError) {
          return 'chat.drawerStub.integrationError';
        } else if (this.chatErrorState === errorStates.initError) {
          return 'chat.drawerStub.initError';
        } else {
          return 'chat.drawerStub.contactsNotAdded';
        }
      } else {
        return null;
      }
    },

    fixHeight() {
      return this.decodedIFrameToken?.integration?.crmName === intergationTypes['1c'];
    },

    computedContacts() {
      if (!this.channelsFilter.length) return this.contacts;
      const contacts = deepCopy(this.contacts);
      return contacts.reduce((buff, item) => {
        const chats = deepCopy(item.chats).filter(({ channelId }) => this.channelsFilter.includes(channelId));
        if (chats.length) {
          item.chats = chats;
          buff.push(item);
        }
        return buff;
      }, []);
    }
  },

  watch: {
    contacts() {
      this.checkIsEmptyChat();
    }
  },

  mounted() {
    this.checkIsEmptyChat();
  },

  methods: {
    checkIsEmptyChat() {
      const { chatType: routeChatType, routeChatId } = this.$route.params;
      if (!routeChatType && !routeChatId && this.contacts.length) {
        const contact = this.contacts[0];
        if (contact.chats.length) {
          if (contact.chats.find((chat) => chat.channelId === contact.channelId)) {
            this.$router.push(
              this.$userPath(`/chat/${contact.chatType}/${encodeURIComponent(contact.chatId)}/${contact.channelId}`)
            );
          } else {
            this.$router.push(
              this.$userPath(
                `/chat/${contact.chatType}/${encodeURIComponent(contact.chatId)}/${contact.chats[0].channelId}`
              )
            );
          }
        } else {
          this.$router.push(this.$userPath(`/chat/${contact.chatType}/${encodeURIComponent(contact.chatId)}/add`));
        }
      }
    }
  }
};
