//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations } from 'vuex';
import { mdiArrowLeft } from '@mdi/js';
import { formatAccountId } from '~/utils/common';

export default {
  name: 'SettingsHeader',

  computed: {
    ...mapState('auth', ['account']),
    ...mapState('settingsHeader', ['settingsHeader']),

    icons() {
      return {
        mdiArrowLeft
      };
    },

    linkHelp() {
      return this.$t('settings.headerButtonHelp.link');
    },

    accountId() {
      if (!this.account) return '';
      return formatAccountId(this.account.id);
    },

    title() {
      const { customTitle, title, dynamicTitlePart = '' } = this.settingsHeader;
      return customTitle || this.$t(title) + dynamicTitlePart;
    }
  },

  methods: {
    ...mapMutations('settingsHeader', ['DISABLE_COMING_BACK'])
  }
};
