//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import { deepCopy } from '~/utils/common';

export default {
  name: 'AppOnboardingDialog',

  props: {
    value: {
      type: Boolean
    }
  },

  data() {
    return {
      step: 1,
      role: null,
      otherRole: '',
      crm: null,
      otherCrm: '',
      loading: false
    };
  },

  computed: {
    ...mapState('auth', ['cabinetInIframe']),
    ...mapGetters('auth', ['frontendData']),

    model: {
      set(v) {
        this.$emit('input', v);
      },
      get() {
        return this.value;
      }
    },

    subtitle() {
      if (this.cabinetInIframe) return '';
      return this.$t(`settings.onboarding.step${this.step}of2`);
    },

    roleItems() {
      return [
        {
          label: this.$t('settings.onboarding.roleOwner'),
          value: 'owner'
        },
        {
          label: this.$t('settings.onboarding.roleDOS'),
          value: 'dos'
        },
        {
          label: this.$t('settings.onboarding.roleTechSpec'),
          value: 'techSpecialist'
        },
        {
          label: this.$t('settings.onboarding.roleIntegrator'),
          value: 'integrator'
        },
        {
          label: this.$t('settings.onboarding.roleOther'),
          value: 'other'
        }
      ];
    },

    crmItems() {
      return [
        {
          label: this.$t('settings.onboarding.crmAmo'),
          value: 'amocrm'
        },
        {
          label: this.$t('settings.onboarding.crmBitrix'),
          value: 'bitrix'
        },
        {
          label: this.$t('settings.onboarding.crmNoCrm'),
          value: 'noCrm'
        },
        {
          label: this.$t('settings.onboarding.crmOther'),
          value: 'other'
        }
      ];
    },

    roleTextFieldStyle() {
      return this.role === 'other' ? { maxHeight: 80, marginTop: 0 } : { maxHeight: 0, marginTop: 16 };
    },

    crmTextFieldStyle() {
      return this.crm === 'other' ? { maxHeight: 80, marginTop: 0 } : { maxHeight: 0, marginTop: 16 };
    },

    disableNextBtn() {
      return !this.role || (this.role === 'other' && !this.otherRole);
    },

    disableStartBtn() {
      return !this.crm || (this.crm === 'other' && !this.otherCrm);
    }
  },

  created() {
    if (this.cabinetInIframe) {
      this.crm = this.cabinetInIframe.target;
    }
  },

  methods: {
    ...mapActions('auth', ['getCurrentAccount']),

    async finish() {
      try {
        this.loading = true;
        const frontendData = deepCopy(this.frontendData);
        const finishAt = Date.now();
        const result = {
          startAt: frontendData.roleCrmOnboarding.startAt,
          finishAt,
          role: this.role === 'other' ? this.otherRole : this.role,
          crm: this.crm === 'other' ? this.otherCrm : this.crm
        };
        frontendData.roleCrmOnboarding = result;
        await this.$services.user.setFrontendData(frontendData);
        await this.getCurrentAccount();
        await this.$services.user.setRoleCrmOnboardingData(result);
        try {
          // eslint-disable-next-line no-undef
          ym(64950046, 'reachGoal', 'kval-lid');
        } catch (err) {
          console.log(err);
        }
        this.model = false;
        setTimeout(() => {
          this.$nuxt.$emit('start-help-onboarding');
        }, 400);
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    }
  }
};
