export default ({ app }, inject) => {
  inject('hasFeature', (feature) => {
    if (typeof feature !== 'string') return true;
    try {
      return app.store.state.auth.account.features[feature];
    } catch {
      return false;
    }
  });
};
