//
//
//
//
//
//
//
//
//
//

import { mdiContentCopy } from '@mdi/js';
import { copyToClipboard } from '~/utils/copyToClipboard';

export default {
  name: 'BaseCopyButton',

  props: {
    contentToCopy: {
      type: String,
      default: ''
    },
    notifyProps: {
      type: Object,
      default: () => ({})
    },
    buttonProps: {
      type: Object,
      default: () => ({})
    },
    icon: {
      type: Boolean
    },
    hideNotify: {
      type: Boolean
    }
  },

  computed: {
    icons() {
      return { mdiContentCopy };
    },

    contentText() {
      return this.buttonProps.content || this.$t('common.copy');
    }
  },

  methods: {
    copy() {
      this.$emit('click');
      const { text, type } = this.notifyProps;
      copyToClipboard(this, this.contentToCopy, {
        text,
        type,
        hideNotify: this.hideNotify
      });
    }
  }
};
