export default {
  async getSubscriptions({ commit }) {
    const subscriptions = await this.$services.subscriptions.getSubscriptions();
    if (subscriptions) {
      commit('SET_SUBSCRIPTIONS', subscriptions);
    }
  },

  async getSubscriptionsConstants({ commit }) {
    const constants = await this.$services.subscriptions.getSubscriptionsConstants();
    if (constants) {
      commit('SET_SUBSCRIPTIONS_CONSTANTS', constants.data);
    }
  }
};
