import BaseService from '~/services/BaseService';

export default class MobileService extends BaseService {
  async getUsers() {
    const result = await this.$apiV2.$get('/mobile/users');
    return result;
  }

  async updatePhone(data) {
    const result = await this.$apiV2.$post('/mobile/updatePhone', data, { ignoreNotify: true });
    return result;
  }

  async getUnansweredCount() {
    const result = await this.$apiV2.$get('/mobile/unansweredCount');
    return result;
  }

  async getMe() {
    const result = await this.$apiV2.$get('/mobile/me');
    return result;
  }
}
