import BaseService from '~/services/BaseService';

export default class AuthService extends BaseService {
  async getAutoresponders() {
    const result = await this.$apiV2.$get('/autoresponses');
    return result;
  }

  async saveAutoresponders(data) {
    const result = await this.$apiV2.$patch('/autoresponses', data);
    return result;
  }

  async addAutoresponder(data) {
    const result = await this.$apiV2.$post('/autoresponses', data);
    return result;
  }

  async deleteAutoresponder(guid) {
    const result = await this.$apiV2.$delete(`/autoresponses/${guid}`);
    return result;
  }

  async renameAutoresponder({ name, guid }) {
    const result = await this.$apiV2.$patch(`/autoresponses/${guid}`, { name });
    return result;
  }
}
