//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  layout: 'empty',

  props: {
    error: {
      type: Object,
      default: null
    }
  },

  head() {
    return {
      meta: [{ hid: 'viewport', name: 'viewport', content: 'width=1288, initial-scale=1' }]
    };
  }
};
