import { init, Identify, identify, setUserId, track } from '@amplitude/analytics-browser';
import { sentryLog } from '~/utils/common';

export default (app, inject) => {
  const amp = {
    init(apiKey) {
      if (!app.$config.env.prod) return;
      try {
        init(apiKey, undefined, { defaultTracking: false });
      } catch (error) {
        console.log(error);
        sentryLog(app, 'Amplitude init error', { error });
      }
    },
    addProperty(property, value) {
      if (!app.$config.env.prod) return;
      try {
        const idf = new Identify();
        idf.set(property, value);
        identify(idf);
      } catch (error) {
        console.log(error);
        sentryLog(app, 'Amplitude addProperty error', { error });
      }
    },
    setUserId(userId) {
      if (!app.$config.env.prod) return;
      try {
        setUserId(String(userId));
      } catch (error) {
        console.log(error);
        sentryLog(app, 'Amplitude setUserId error', { error });
      }
    },
    track(eventType, params) {
      if (!app.$config.env.prod) return;
      console.log('Event: ' + eventType, params);
      try {
        track(eventType, params);
      } catch (error) {
        console.log(error);
        sentryLog(app, 'Amplitude track error', { error });
      }
    }
  };

  inject('amp', amp);
};
