export default {
  async getChannels({ commit }) {
    const result = await this.$services.channels.getChannels();

    if (result) {
      commit('SET_CHANNELS', result.channels);
    }
  },

  channelInit({ commit }, data) {
    commit('SET_CHANNEL_INIT', data);
  },

  setVkChannelAddingStatus({ commit }, status) {
    commit('SET_VK_CHANNEL_ADDING_STATUS', status);
  },

  setFirstChannelByAccount({ commit }, status) {
    commit('SET_FIRST_CHANNEL_BY_ACCOUNT', status);
  },

  async channelUpdate({ state, commit, rootGetters }, eventData) {
    this.dispatch('channels/setFirstChannelByAccount', eventData.firstChannelByAccount);
    if (eventData.firstChannelByAccount && !state.vkChannelAddingStatus && !rootGetters['auth/childPostpay']) {
      setTimeout(() => {
        this.$router.push(this.$userPath('/settings/integrations'));
      }, 1000);
    }
    if (eventData.eventTarget === 'qrLink') {
      commit('SET_CHANNEL_INIT', eventData);
      return;
    }

    if (!eventData?.guid) return;

    const currentChannel = state.channels.find((channel) => channel?.guid === eventData.guid);
    const {
      channels: [actualChannelData]
    } = await this.$services.channels.getChannels({ channelId: eventData.guid });

    if (actualChannelData) {
      if (currentChannel) {
        commit('CHANNEL_UPDATE', { eventData, actualChannelData });
      } else {
        commit('ADD_CHANNEL', { eventData, actualChannelData });
      }
    } else {
      commit('SET_CHANNEL_INIT', eventData);
    }
  },

  sendFbqTargetIfFirstChannel({ state, rootState }, bySocket = false) {
    const channels = state.channels;
    if ((bySocket && channels.length === 1) || (!bySocket && !channels.length)) {
      try {
        // eslint-disable-next-line no-undef
        fbq('init', '454566436249499', { external_id: rootState.auth.xAccountId });
        // eslint-disable-next-line no-undef
        fbq('track', 'SubmitApplication');
      } catch (err) {
        console.log(err);
      }
    }
  }
};
