//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mdiPlus, mdiFilterVariant, mdiClose, mdiMenuDown } from '@mdi/js';
import { mapState, mapGetters, mapActions } from 'vuex';
import jwt from 'jsonwebtoken';
import { channelTypes } from '~/enums/channels';
import { deepEqual } from '~/utils/common';
import { getTransportIcon } from '~/utils/getTransportIcon';
import { getSortedByAlphabetTransportDeletedChannels } from '~/utils/channels';

export default {
  name: 'DrawerSearch',

  props: {
    value: {
      type: [String, Number],
      default: ''
    }
  },

  data() {
    return {
      addContactDialog: false,
      channelsFilterEnable: false,
      channelsMenu: false,
      channels: [],
      tempChannels: [],
      channelsSearchText: ''
    };
  },

  computed: {
    ...mapState('channels', ['accessibleChannels']),
    ...mapState('auth', ['iFrameToken', 'integrationInfo']),
    ...mapState('chat', ['channelsFilter']),
    ...mapGetters('auth', ['chatsForTechPartner']),
    ...mapGetters('channels', ['channelsByTransport']),

    icons() {
      return {
        mdiPlus,
        mdiClose,
        mdiFilterVariant,
        mdiMenuDown
      };
    },

    disableApply() {
      return deepEqual(this.tempChannels, this.channels);
    },

    channelsItemClass() {
      return (guid) => {
        return this.channels.includes(guid) ? 'active-row' : '';
      };
    },

    channelsItems() {
      return getSortedByAlphabetTransportDeletedChannels(this.accessibleChannels)
        .filter((item) => {
          if (!this.channelsSearchText) return true;
          return item.name.toLowerCase().includes(this.channelsSearchText.toLowerCase());
        })
        .map((item) => {
          const { guid, name, deleted, transport } = item;
          return {
            guid,
            name,
            deleted,
            icon: getTransportIcon(transport).icon
          };
        });
    },

    filteredChannels() {
      return this.channelsItems.filter(({ guid }) => this.channels.includes(guid)).map(({ guid }) => guid);
    },

    activeChannel() {
      return this.channelsItems.find((item) => this.channels.includes(item.guid));
    },

    searchText: {
      set(v) {
        this.$nuxt.$emit('collapse-all-contacts');
        this.$emit('input', v);
      },
      get() {
        return this.value;
      }
    },

    disableAddBtn() {
      return !this.accessibleChannels.filter((channel) =>
        [channelTypes.whatsapp, channelTypes.wapi, channelTypes.tgapi].includes(channel.transport)
      ).length;
    },

    channelsFilterColor() {
      return this.channelsFilterEnable ? 'green' : 'grey darken-1';
    },

    channelsFilterTooltip() {
      return this.$t(`chat.${this.channelsFilterEnable ? 'channelsFilterHideTooltip' : 'channelsFilterShowTooltip'}`);
    },

    showFilterBtn() {
      if (this.chatsForTechPartner || !this.$hasFeature('useChannelListFilters')) return false;
      if (this.integrationInfo?.userRoles && Object.keys(this.integrationInfo.userRoles).length === 1) return false;
      return true;
    },

    showAddBtn() {
      if (this.chatsForTechPartner) return false;
      try {
        const { integration } = jwt.decode(this.iFrameToken);
        return !integration;
      } catch {
        return true;
      }
    }
  },

  watch: {
    channelsMenu() {
      if (!this.channelsMenu) {
        this.tempChannels = this.channels;
      }
    }
  },

  methods: {
    ...mapActions('chat', ['setChannelsFilter']),

    clearChannels() {
      this.channels = [];
      this.tempChannels = [];
      this.setChannelsFilter([]);
    },

    showDialog() {
      if (!this.disableAddBtn) this.addContactDialog = true;
    },

    applyChannels() {
      this.channels = this.tempChannels;
      this.setChannelsFilter(this.filteredChannels);
      this.channelsMenu = false;
    }
  }
};
