export const iframeScopes = {
  global: 'global',
  card: 'card'
};

export const clientTypes = {
  '1c': '1c'
};

export const eventMessageTypes = {
  createEntity: 'WZ_CREATE_ENTITY',
  openEntity: 'WZ_OPEN_ENTITY',
  outputMessage: 'WZ_OUTPUT_MESSAGE'
};
