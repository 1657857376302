//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState, mapGetters } from 'vuex';
import { internationalCallingCodes } from '~/enums/internationalCallingCodes';
import { validate, phoneValidator } from '~/utils/validation';
import { chatTypes } from '~/enums/chats';
import * as MESSAGES from '~/enums/messages';

export default {
  name: 'DrawerAddWhatsappContact',

  props: {
    value: {
      type: Boolean
    }
  },

  data() {
    return {
      form: {
        name: '',
        phone: {
          code: 7,
          iso2: 'ru',
          number: ''
        }
      },
      errors: {},
      loading: false
    };
  },

  computed: {
    ...mapState('channels', ['accessibleChannels']),
    ...mapState('auth', ['account']),
    ...mapState('chat', ['contacts']),
    ...mapGetters('channels', ['channelsByTransport']),

    dialog: {
      set(v) {
        if (!v) {
          this.form.phone = {
            code: 7,
            iso2: 'ru',
            number: ''
          };
          this.form.name = '';
        }
        this.$emit('input', v);
      },
      get() {
        return this.value;
      }
    },

    countries() {
      return internationalCallingCodes;
    },

    disableAddBtn() {
      return !(this.form.name.length && this.form.phone.code && this.form.phone.number);
    }
  },

  watch: {
    'form.phone': {
      deep: true,
      handler() {
        this.errors = {};
      }
    }
  },

  methods: {
    ...mapActions('chat', ['getContact', 'createVirtualContact']),

    async addContact() {
      this.errors = validate(this.form, { phone: [phoneValidator()] });

      if (Object.keys(this.errors).length) {
        return;
      }
      this.loading = true;
      try {
        const {
          name,
          phone: { code, number }
        } = this.form;
        try {
          const chatType =
            this.channelsByTransport.whatsapp || this.channelsByTransport.wapi
              ? chatTypes.whatsapp
              : chatTypes.telegram;
          const chatId = `${code}${number}`;

          await this.$services.contacts.addContact({
            name,
            chatId,
            chatType
          });

          // Ищем контакт в сторе, если есть, показываем модалку добавления чата
          const contact = this.contacts.find((contact) => contact.chatType === chatType && contact.chatId === chatId);
          // Если контакта нет в сторе, запрашиваем его у бекенда. Если на беке есть контакт,
          // встраиваем его и показываем модалку добавления чата
          // Если контакта нет и на бекенде, то создаем виртуальный контакт и показываем модалку добавления чата
          if (!contact) {
            const contactFromBackend = await this.getContact({ chatType, chatId });
            if (!contactFromBackend) {
              this.createVirtualContact({
                avatarId: null,
                channelId: '',
                chatId,
                chatType,
                chats: [],
                contactId: '',
                contactName: name,
                lastMessage: {
                  datetime: 0,
                  id: '',
                  incoming: true,
                  text: '',
                  type: MESSAGES.TYPE_TEXT
                },
                unanswered: 0,
                virtual: true
              });
            }
          }
          setTimeout(() => {
            this.$nuxt.$emit('go-to-active-chat-contact');
          }, 1000);
          this.$router.push(this.$userPath(`/chat/whatsapp/${chatId}/add`));
        } catch (err) {
          console.log(err);
        }
        this.dialog = false;
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    }
  }
};
