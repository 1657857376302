export default (ctx, inject) => {
  const initialTransports = {
    instagram: 'instagram',
    whatsapp: 'whatsapp',
    wapi: 'whatsapp',
    telegram: 'telegram'
  };

  const transports = {
    whatsapp: {
      icon: 'whatsapp',
      color: '#25d366'
    },
    instagram: {
      icon: 'instagram',
      color: '#e4405f'
    },
    telegram: {
      icon: 'telegram',
      color: '#2ca5e0'
    }
  };
  inject('getTransportInfo', (transport) => {
    return transports[initialTransports[transport]] || {};
  });
  inject('checkTransport', (initial, transport) => {
    return initialTransports[initial] === transport;
  });
};
