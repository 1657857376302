//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import { formatAccountId } from '~/utils/common';

const HEADER_HEIGHT_IN_PX = 64;

export default {
  name: 'SettingsDrawer',

  props: {
    value: {
      type: Boolean
    },

    compactDrawer: {
      type: Boolean
    }
  },

  computed: {
    ...mapState('auth', ['account']),

    drawer: {
      set(v) {
        this.$emit('input', v);
      },
      get() {
        return this.value;
      }
    },

    getFormatAccountId() {
      return formatAccountId(this.account?.id);
    },

    headerHeight() {
      return HEADER_HEIGHT_IN_PX;
    },

    showTooltips() {
      return (this.compactDrawer && this.drawer) || (!this.compactDrawer && !this.drawer);
    }
  },

  methods: {
    clickItemInMenu() {
      if (this.compactDrawer && !this.drawer) {
        this.drawer = true;
      }
    }
  }
};
