import Vue from 'vue';
import BaseSnackbar from '~/components/base/BaseSnackbar';

export default (ctx, inject) => {
  const BaseSnackbarClass = Vue.extend(BaseSnackbar);
  const animationDuration = 250;

  const notify = (message, { type, timeout, description, copyable, copyData } = {}) => {
    const appElement = document.querySelector('#app');
    const { i18n } = ctx.app;

    if (appElement) {
      const instance = new BaseSnackbarClass({
        i18n,
        propsData: {
          message,
          type,
          timeout: type === 'error' ? -1 : timeout || 5000,
          description,
          copyable,
          copyData
        }
      });
      instance.$vuetify = ctx.$vuetify;
      instance.$mount();
      instance.$on('input', async (v) => {
        instance.$props.value = v;
        if (!v) {
          await instance.$nextTick();
          instance.$destroy();
          setTimeout(() => appElement.removeChild(instance.$el), animationDuration);
        }
      });
      appElement.appendChild(instance.$el);
      instance.show();
    }
  };

  inject('notify', notify);
};
