import { render, staticRenderFns } from "./SettingsDrawerWidgetDialog.vue?vue&type=template&id=7d36a104&scoped=true&"
import script from "./SettingsDrawerWidgetDialog.vue?vue&type=script&lang=js&"
export * from "./SettingsDrawerWidgetDialog.vue?vue&type=script&lang=js&"
import style0 from "./SettingsDrawerWidgetDialog.vue?vue&type=style&index=0&id=7d36a104&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d36a104",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseDenseModalLayout: require('/builds/wazzup/front/components/base/BaseDenseModalLayout.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VIcon,VSpacer})
