//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BaseModalLayout',

  props: {
    plainText: {
      type: Boolean
    },

    hideSpacer: {
      type: Boolean
    }
  }
};
