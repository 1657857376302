const localesCodes = {
  ru: [
    'ru',
    'be',
    'ky',
    'kk',
    'ro',
    'md',
    'uk',
    'tg',
    'tk',
    'tt',
    'uz',
    'az',
    'ab',
    'av',
    'ce',
    'cv',
    'ba',
    'kv',
    'os',
    'udm',
    'chm',
    'myv',
    'sah',
    'lv',
    'lt',
    'et',
    'hy',
    'ka'
  ],
  es: ['es', 'ca', 'gl', 'eu', 'an'],
  pt: ['pt']
};

export const getCurrentLocaleCode = (code) => {
  return localesCodes.ru.includes(code)
    ? 'ru'
    : localesCodes.es.includes(code)
    ? 'es'
    : localesCodes.pt.includes(code)
    ? 'pt'
    : 'en';
};
