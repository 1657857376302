export default {
  SET_CURRENT_ACCOUNT(state, account) {
    state.account = { ...account };
  },

  SET_X_ACCOUNT_ID(state, xAccountId) {
    state.xAccountId = xAccountId;
  },

  SET_USERS(state, users) {
    state.users = users;
  },

  SET_CURRENT_USER(state, user) {
    state.user = user;
  },

  SET_IFRAME_TOKEN(state, token) {
    state.iFrameToken = token;
  },

  SET_INTEGRATION_INFO(state, integrationInfo) {
    state.integrationInfo = integrationInfo;
  },

  SET_CHAT_ERROR_STATE(state, chatErrorState) {
    state.chatErrorState = chatErrorState;
  },

  SET_ACCESS_TOKEN(state, accessToken) {
    state.accessToken = accessToken;
  },

  SET_CABINET_IN_IFRAME(state, cabinetInIframe) {
    state.cabinetInIframe = cabinetInIframe;
  }
};
