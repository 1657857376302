export const timezones = [
  {
    code: -12,
    label: 'International date line (GMT-12:00)',
    tzCode: 'Etc/GMT+12',
    utc: '-12:00'
  },
  {
    code: -11,
    label: 'New Zealand, Niue (GMT-11:00)',
    tzCode: 'Etc/GMT+11',
    utc: '-11:00'
  },
  {
    code: -10,
    label: 'Pacific/Rarotonga (GMT-10:00)',
    tzCode: 'Pacific/Rarotonga',
    utc: '-10:00'
  },
  {
    code: -9,
    label: 'Pacific/Gambier (GMT-09:00)',
    tzCode: 'Pacific/Gambier',
    utc: '-09:00'
  },
  {
    code: -8,
    label: 'America/Los_Angeles (GMT-08:00)',
    tzCode: 'America/Los_Angeles',
    utc: '-08:00'
  },
  {
    code: -7,
    label: 'America/Denver (GMT-07:00)',
    tzCode: 'America/Denver',
    utc: '-07:00'
  },
  {
    code: -6,
    label: 'America/Chicago (GMT-06:00)',
    tzCode: 'America/Chicago',
    utc: '-06:00'
  },
  {
    code: -5,
    label: 'America/New_York (GMT-05:00)',
    tzCode: 'America/New_York',
    utc: '-05:00'
  },
  {
    code: -4,
    label: 'Atlantic/Bermuda (GMT-04:00)',
    tzCode: 'Atlantic/Bermuda',
    utc: '-04:00'
  },
  {
    code: -3,
    label: 'America/Buenos_Aires (GMT-03:00)',
    tzCode: 'America/Buenos_Aires',
    utc: '-03:00'
  },
  {
    code: -2,
    label: 'Atlantic/South_Georgia (GMT-02:00)',
    tzCode: 'Atlantic/South_Georgia',
    utc: '-02:00'
  },
  {
    code: -1,
    label: 'Atlantic/Azores (GMT-01:00)',
    tzCode: 'Atlantic/Azores',
    utc: '-01:00'
  },
  {
    code: 0,
    label: 'Europe/London (GMT+00:00)',
    tzCode: 'Europe/London',
    utc: '+00:00'
  },
  {
    code: 1,
    label: 'Europe/Paris (GMT+01:00)',
    tzCode: 'Europe/Paris',
    utc: '+01:00'
  },
  {
    code: 2,
    label: 'Europe/Kaliningrad (GMT+02:00)',
    tzCode: 'Europe/Kaliningrad',
    utc: '+02:00'
  },
  {
    code: 3,
    label: 'Europe/Moscow (GMT+03:00)',
    tzCode: 'Europe/Moscow',
    utc: '+03:00'
  },
  {
    code: 4,
    label: 'Europe/Samara (GMT+04:00)',
    tzCode: 'Europe/Samara',
    utc: '+04:00'
  },
  {
    code: 5,
    label: 'Asia/Yekaterinburg (GMT+05:00)',
    tzCode: 'Asia/Yekaterinburg',
    utc: '+05:00'
  },
  {
    code: 6,
    label: 'Asia/Omsk (GMT+06:00)',
    tzCode: 'Asia/Omsk',
    utc: '+06:00'
  },
  {
    code: 7,
    label: 'Asia/Novosibirsk (GMT+07:00)',
    tzCode: 'Asia/Novosibirsk',
    utc: '+07:00'
  },
  {
    code: 8,
    label: 'Asia/Irkutsk (GMT+08:00)',
    tzCode: 'Asia/Irkutsk',
    utc: '+08:00'
  },
  {
    code: 9,
    label: 'Asia/Yakutsk (GMT+09:00)',
    tzCode: 'Asia/Yakutsk',
    utc: '+09:00'
  },
  {
    code: 10,
    label: 'Asia/Vladivostok (GMT+10:00)',
    tzCode: 'Asia/Vladivostok',
    utc: '+10:00'
  },
  {
    code: 11,
    label: 'Asia/Magadan (GMT+12:00)',
    tzCode: 'Asia/Magadan',
    utc: '+12:00'
  },
  {
    code: 12,
    label: 'Asia/Kamchatka (GMT+12:00)',
    tzCode: 'Asia/Kamchatka',
    utc: '+12:00'
  },
  {
    code: 13,
    label: 'Pacific/Enderbury (GMT+13:00)',
    tzCode: 'Pacific/Enderbury',
    utc: '+13:00'
  },
  {
    code: 14,
    label: 'Pacific/Kiritimati (GMT+14:00)',
    tzCode: 'Pacific/Kiritimati',
    utc: '+14:00'
  }
];
