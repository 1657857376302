export default async ({ app, store, $config }) => {
  if (
    (!$config.m &&
      app.$cookies.get('accessToken') &&
      app.$cookies.get('accountId') &&
      !store.state?.auth?.iFrameToken &&
      store.state?.auth?.user?.activated &&
      !$config.cabinetInIframe) ||
    ($config.m && localStorage.getItem('managerToken') && localStorage.getItem('managerAccountId'))
  ) {
    await app.$redirect('/');
  }
};
