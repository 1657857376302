import BaseService from '~/services/BaseService';

export default class AnalyticsService extends BaseService {
  async getChartChannels(data) {
    const result = await this.$apiV2.$get('/analytics/chart/channels', data);
    return result;
  }

  async getAnalyticsUsers(data) {
    const result = await this.$apiV2.$get('/analytics/users', data);
    return result;
  }

  async getChartManagers(data) {
    const result = await this.$apiV2.$get('/analytics/chart/users', data);
    return result;
  }
}
