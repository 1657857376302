import { clearPathFromAccountId } from '~/utils/common';
import { showErrorNotification } from '~/utils/showErrorNotification';
import { errorCodes, errorStates } from '~/enums/errors';

export default (ctx, inject) => {
  const { app, $axios, $config, store } = ctx;
  const apiBaseUrl = $config.apiBaseUrl;
  const api = $axios.create({
    baseURL: `${apiBaseUrl}/api/v1`,
    timeout: 20000
  });

  const apiV2 = $axios.create({
    baseURL: `${apiBaseUrl}/api/v2`,
    timeout: 20000,
    headers: {
      'x-wazzup-webapp': true
    }
  });

  const apiAuth = $axios.create({
    baseURL: `${apiBaseUrl}/auth/api/v1`,
    timeout: 20000
  });

  const apiStore = $axios.create({
    baseURL: app.$config.storeBaseUrl
  });

  const apiTechPartner = $axios.create({
    baseURL: app.$config.techBaseUrl
  });

  const authInterceptor = (config) => {
    const accessToken =
      app.store.state.auth.iFrameToken ||
      (app.$config.cabinetInIframe &&
      (app.$cookies.get('accessTokenIframe') || localStorage.getItem('accessTokenIframe'))
        ? app.$cookies.get('accessTokenIframe') || localStorage.getItem('accessTokenIframe')
        : app.$cookies.get('accessToken'));
    if (accessToken) {
      config.headers.Authorization = accessToken;
      config.headers['X-Account-ID'] = app.store.state.auth.xAccountId;
    }
    return config;
  };

  let refreshTokenPromise = null;
  const refreshTokenInterceptor = async (error) => {
    const status = error.response ? error.response.status : 0;
    const accessToken =
      app.$config.cabinetInIframe &&
      (app.$cookies.get('accessTokenIframe') || localStorage.getItem('accessTokenIframe'))
        ? app.$cookies.get('accessTokenIframe') || localStorage.getItem('accessTokenIframe')
        : app.$cookies.get('accessToken');
    if ([401].includes(status) && accessToken) {
      const config = error.config;
      if (!refreshTokenPromise) refreshTokenPromise = app.$services.auth.refresh();
      try {
        await refreshTokenPromise;
        config.headers.Authorization =
          app.$config.cabinetInIframe &&
          (app.$cookies.get('accessTokenIframe') || localStorage.getItem('accessTokenIframe'))
            ? app.$cookies.get('accessTokenIframe') || localStorage.getItem('accessTokenIframe')
            : app.$cookies.get('accessToken');
        return $axios({ ...config });
      } catch (err) {
        await app.$services.auth.logout(true);
        const url = new URL(location.href);
        let querystring = '';
        if (app.$config.cabinetInIframe) {
          const { target, domain, state } = app.$config.cabinetInIframe;
          if (domain) querystring = `target=${target}&domain=${domain}&`;
          if (state) querystring = `target=${target}&state=${state}&`;
        }
        app.$redirect(
          `${$config.protocol}app.${$config.appDomain}${
            $config.port
          }/login/?${querystring}redirect=${clearPathFromAccountId(url.pathname)}`
        );
      } finally {
        refreshTokenPromise = null;
      }
    } else if ([401].includes(status)) {
      const { protocol, appDomain, port } = $config;
      const url = new URL(location.href);
      app.$redirect(`${protocol}app.${appDomain}${port}/login/?redirect=${clearPathFromAccountId(url.pathname)}`);
    }
    return Promise.reject(error);
  };

  const errorInterceptor = (error) => {
    const errorCode = error.response?.data?.errors?.[0]?.code;

    if (errorCode === errorCodes.noRole) {
      store.dispatch('auth/setChatErrorState', errorStates.userHasNoRole);
      throw error;
    } else if ([errorCodes.noIntegration, errorCodes.noIntegrationData, errorCodes.invalidToken].includes(errorCode)) {
      store.dispatch('auth/setChatErrorState', errorStates.integrationError);
      throw error;
    } else if (errorCode === errorCodes.noUserInfo) {
      store.dispatch('auth/setChatErrorState', errorStates.initError);
      throw error;
    }

    if (!error.config?.ignoreNotify) {
      showErrorNotification(ctx, error);
    }
    return Promise.reject(error);
  };

  api.onRequest(authInterceptor);
  apiV2.onRequest(authInterceptor);
  apiStore.onRequest(authInterceptor);
  apiTechPartner.onRequest(authInterceptor);

  api.onError(refreshTokenInterceptor);
  api.onError(errorInterceptor);
  apiAuth.onError(errorInterceptor);
  apiV2.onError(refreshTokenInterceptor);
  apiV2.onError(errorInterceptor);
  apiStore.onError(errorInterceptor);
  apiTechPartner.onError(errorInterceptor);
  apiTechPartner.onError(refreshTokenInterceptor);

  inject('api', api);
  inject('apiAuth', apiAuth);
  inject('apiV2', apiV2);
  inject('apiStore', apiStore);
  inject('apiTechPartner', apiTechPartner);
};
