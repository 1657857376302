import { mapState, mapGetters } from 'vuex';
import { getCurrencySign } from '~/utils/billing';

export default {
  computed: {
    ...mapState('auth', ['account']),
    ...mapState('subscriptions', ['subscriptionsConstants']),
    ...mapGetters('auth', ['frontendData']),

    promoPrice() {
      return this.subscriptionsConstants.promoPrices751175.whatsapp[this.account.currency].max;
    },

    currency() {
      return getCurrencySign(this.account.currency);
    }
  }
};
