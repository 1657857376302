import BaseService from '~/services/BaseService';

export default class UserService extends BaseService {
  async updateCurrentUser(data) {
    const result = await this.$api.$patch('/users/current', data, { ignoreNotify: true });
    return result;
  }

  async getUsers() {
    const result = await this.$api.$get('/users');
    return result.data;
  }

  async getCurrentAccount() {
    const result = this.route.path.includes('tech-partner')
      ? await this.$apiTechPartner.$get('/accounts/current')
      : await this.$api.$get('/accounts/current');

    return result.data;
  }

  async getCurrentUser() {
    const result = await this.$api.$get('/users/current');
    return result.data;
  }

  async getCurrentAccountNew() {
    const result = await this.$apiV2.$get('/accounts/current');
    return result;
  }

  async updateUserTimezone(data) {
    const result = await this.$apiV2.$patch('/account/timezone', data);
    return result;
  }

  async updateUserLang(data) {
    const result = await this.$apiV2.$patch('/account/lang', data);
    return result;
  }

  async updateUserEmail(data) {
    const result = await this.$apiV2.$patch('/account/email', data, { ignoreNotify: true });
    return result;
  }

  async updateUserPassword(data) {
    const result = await this.$apiV2.$patch('/account/password', data, { ignoreNotify: true });
    return result;
  }

  async getAdmins() {
    const result = await this.$apiV2.$get('/account/notifications');
    return result;
  }

  async editAdmins(data) {
    const result = await this.$apiV2.$patch('/account/notifications', data);
    return result;
  }

  async renameAdmin(data) {
    const result = await this.$apiV2.$patch('/account/notifications/rename', data);
    return result;
  }

  async deleteAdmin(guid) {
    const result = await this.$apiV2.$delete(`/account/notifications/${guid}`);
    return result;
  }

  async setFrontendData(data) {
    const result = await this.$apiV2.$post('/account/frontendData', { data: JSON.stringify(data) });
    return result;
  }

  async setRoleCrmOnboardingData(data) {
    const result = await this.$apiV2.$post('/account/roleCrmOnboarding', data);
    return result;
  }
}
