//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import {
  mdiCellphoneLink,
  mdiCellphone,
  mdiConnection,
  mdiCurrencyUsd,
  mdiHandshakeOutline,
  mdiTextBoxOutline,
  mdiCogOutline,
  mdiAlertCircleOutline,
  mdiForumOutline,
  mdiPercentOutline,
  mdiBellOutline,
  mdiPoll,
  mdiMessageArrowRightOutline
} from '@mdi/js';
import { accountTypes } from '~/enums/account';

export default {
  name: 'SettingsDrawerMainMenu',

  props: {
    enableTooltips: {
      type: Boolean
    }
  },

  computed: {
    ...mapState('auth', ['account']),
    ...mapState('integrations', ['integrationInfo']),

    icons() {
      return {
        mdiForumOutline,
        mdiAlertCircleOutline
      };
    },

    hasClientAccount() {
      const { EMPLOYEE, STANDART, PARTNER_DEMO } = accountTypes;

      return [EMPLOYEE, STANDART, PARTNER_DEMO].includes(this.account?.type);
    },

    settingsList() {
      return [
        {
          title: this.$t('settings.titles.channels'),
          icon: mdiCellphoneLink,
          notify: false,
          key: 'channels'
        },
        {
          title: this.$t('settings.titles.integrations'),
          icon: mdiConnection,
          notify: this.integrationInfo?.state === 'error' || this.integrationInfo?.state === 'paused',
          key: 'integrations'
        },
        {
          title: this.$t('settings.titles.mobile.short'),
          icon: mdiCellphone,
          notify: localStorage.mobileErrorNotification === 'true',
          key: 'mobile'
        },
        {
          title: this.$t('settings.titles.subscriptions'),
          icon: mdiCurrencyUsd,
          notify: false,
          key: 'subscriptions'
        },
        {
          title: this.$t(`settings.titles.${this.hasClientAccount ? 'client' : 'partner'}`),
          icon: this.hasClientAccount ? mdiPercentOutline : mdiHandshakeOutline,
          notify: false,
          key: 'partner'
        },
        {
          title: this.$t('settings.titles.templates'),
          icon: mdiTextBoxOutline,
          notify: false,
          key: 'templates'
        },
        {
          title: this.$t('settings.titles.notifications'),
          icon: mdiBellOutline,
          notify: false,
          key: 'notifications'
        },
        {
          title: this.$t('settings.titles.autoresponders'),
          icon: mdiMessageArrowRightOutline,
          notify: false,
          key: 'autoresponders'
        },
        {
          title: this.$t('settings.titles.analytics'),
          icon: mdiPoll,
          notify: false,
          key: 'analytics'
        },
        {
          title: this.$t('settings.titles.account'),
          icon: mdiCogOutline,
          notify: false,
          key: 'account'
        }
      ].filter((item) => !item.feature || this.$hasFeature(item.feature));
    }
  }
};
