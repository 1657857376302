export default (_, inject) => {
  const redirect = async (href, time = 7000) => {
    const wait = () =>
      new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, time);
      });
    location.href = href;
    await wait();
  };

  inject('redirect', redirect);
};
