import ContactsService from '~/services/ContactsService';
import AuthService from '~/services/AuthService';
import AnalyticsService from '~/services/AnalyticsService';
import UserService from '~/services/UserService';
import ChannelsService from '~/services/ChannelsService';
import ReferralsService from '~/services/ReferralsService';
import ChatsService from '~/services/ChatsService';
import BillingService from '~/services/BillingService';
import ClientsService from '~/services/ClientsService';
import TemplatesService from '~/services/TemplatesService';
import IntegrationsService from '~/services/IntegrationsService';
import MobileService from '~/services/MobileService';
import SubscriptionsService from '~/services/SubscriptionsService';
import RequisitesService from '~/services/RequisitesService';
import AutorespondersService from '~/services/AutorespondersService';

export default ({ app, route }, inject) => {
  const { $api, $apiV2, $apiAuth, $apiStore, $apiTechPartner, $cookies, $config } = app;

  const services = {
    auth: new AuthService({ $api, $apiAuth, $apiV2, $cookies, $config }),
    analytics: new AnalyticsService({ $apiV2 }),
    user: new UserService({ $api, $apiV2, $apiTechPartner, $cookies, $config, route }),
    contacts: new ContactsService({ $api, $apiV2 }),
    channels: new ChannelsService({ $api, $apiV2, $apiTechPartner, route }),
    referrals: new ReferralsService({ $api, $apiV2 }),
    billing: new BillingService({ $api, $apiV2 }),
    clients: new ClientsService({ $api, $apiV2 }),
    chats: new ChatsService({ $api, $apiV2, $apiStore }),
    templates: new TemplatesService({ $api, $apiV2, $apiTechPartner, route }),
    integrations: new IntegrationsService({ $api, $apiV2 }),
    mobile: new MobileService({ $apiV2 }),
    subscriptions: new SubscriptionsService({ $apiV2 }),
    requisites: new RequisitesService({ $apiV2 }),
    autoresponders: new AutorespondersService({ $apiV2 })
  };

  inject('services', services);
};
