export const accountTypes = {
  EMPLOYEE: 'employee',
  STANDART: 'standart',
  PARTNER: 'partner',
  PARTNER_DEMO: 'partner-demo',
  TECH_PARTNER: 'tech-partner',
  MEDIA_PARTNER: 'media-partner',
  TECH_PARTNER_POSTPAY: 'tech-partner-postpay',
  CHILD_POSTPAY: 'child-postpay'
};

export const accountCurrencyTypes = {
  RUR: 'RUR',
  EUR: 'EUR',
  USD: 'USD',
  KZT: 'KZT'
};

export const accountSettingsTypes = {
  timezone: 'timezone',
  lang: 'lang',
  password: 'password',
  email: 'email'
};

export const languages = [
  {
    code: 'ru',
    text: 'Русский (RU)'
  },
  {
    code: 'en',
    text: 'English (EN)'
  },
  {
    code: 'es',
    text: 'Español (ES)'
  },
  {
    code: 'pt',
    text: 'Português (PT)'
  }
];

export const CISCodes = ['RU', 'AZ', 'AM', 'BY', 'KZ', 'KG', 'MD', 'TJ', 'UZ'];
