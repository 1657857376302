export const errorStates = {
  userHasNoRole: 'userHasNoRole',
  integrationError: 'integrationError',
  initError: 'initError'
};

export const errorCodes = {
  noRole: 'USER_DOES_NOT_HAVE_ANY_ROLE',
  noIntegration: 'INTEGRATION_CAN_NOT_GET_INTEGRATION',
  noIntegrationData: 'INTEGRATION_CAN_NOT_GET_DATA',
  noUserInfo: 'MOBILE_CAN_NOT_GET_ME',
  invalidToken: 'INTEGRATION_DELETED_TOKEN'
};
