import BaseService from '~/services/BaseService';

export default class BillingService extends BaseService {
  async addPayment(data) {
    const result = await this.$apiV2.$post('/billing', data, { ignoreNotify: !!data.ignoreNotify });
    return result;
  }

  async getCurrentUserBalance() {
    const result = await this.$apiV2.$get('billing/currentUser/balance');
    return result;
  }

  async exchangeRates() {
    const result = await this.$apiV2.$get('/billing/exchangeRates');
    return result;
  }

  async deleteInvoice(id) {
    const result = await this.$apiV2.$delete(`/billing/invoice/${id}`);
    return result;
  }

  async getSubscriptionBillingHistory(data) {
    const result = await this.$apiV2.$get('/billing', data);
    return result;
  }

  async getReferalsBillingHistory({ offset = 0, limit = 50 }) {
    const result = await this.$apiV2.$get('/affiliate/billing', { params: { offset, limit } });
    return result;
  }

  async getPartnerDiscount() {
    const result = await this.$apiV2.$get('/affiliate/billing/discount');
    return result;
  }
}
