export default {
  SET_WAZZUP_TAGS(state, tags) {
    tags.sort((a, b) => (a.order > b.order ? 1 : -1));
    state.wazzupTags = tags;
  },

  SET_WABA_TAGS(state, tags) {
    tags.sort((a, b) => (a.order > b.order ? 1 : -1));
    state.wabaTags = tags;
  },

  SET_AUTO_TEMPLATE(state, template) {
    state.autoTemplate = template;
  }
};
