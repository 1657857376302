//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mdiCheckCircle } from '@mdi/js';

export default {
  name: 'SettingsDrawerWidgetDialog',

  computed: {
    icons() {
      return {
        mdiCheckCircle
      };
    }
  }
};
