import {
  mdiCheck,
  mdiCheckAll,
  mdiAlertCircleOutline,
  mdiCancel,
  mdiImageOutline,
  mdiMusic,
  mdiVideoOutline,
  mdiFileDocumentOutline,
  mdiPhoneMissed,
  mdiAccountOutline,
  mdiMapMarkerOutline
} from '@mdi/js';

export const TYPE_UNSUPPORTED_BY_SERVICE = 0;
export const TYPE_TEXT = 1;
export const TYPE_IMAGE = 2;
export const TYPE_AUDIO = 3;
export const TYPE_VIDEO = 4;
export const TYPE_DOC = 5;
export const TYPE_VCARD = 6;
export const TYPE_GEO = 7;
export const TYPE_TEMPLATE = 8;
export const TYPE_UNSUPPORTED_BY_MESSENGER = 9;
export const TYPE_NEWS = 10;
export const TYPE_SYSTEM = 11;
export const TYPE_BUTTONS = 12;
export const TYPE_MISS_AUDIO = 21;
export const TYPE_MISS_VIDEO = 22;

export const STATUS_DELETED = -2;
export const STATUS_NETWORK_PROBLEM = -1;
export const STATUS_NOT_SENT = 0;
export const STATUS_SENT = 1;
export const STATUS_DELIVERED = 2;
export const STATUS_READ = 3;
export const STATUS_BAD_CONTACT = 13;
export const STATUS_LONG_TEXT = 14;
export const STATUS_BAD_LINK = 15;
export const STATUS_MAX_SIZE = 17;
export const STATUS_SPAM = 18;
export const STATUS_INTERRUPTED = 19;
export const STATUS_NOT_SATISFY_REQUIRMENTS = 21;
export const STATUS_CANCELED = 22;
export const STATUS_BUSINESS_EXPIRED = 23;
export const STATUS_FB_DAILY_LIMIT_REACH = 24;
export const STATUS_COMMENT_DELETED = 25;
export const STATUS_TAG_NOT_ALLOWED = 26;
export const STATUS_EMPTY_WABA_BALANCE = 27;
export const STATUS_CANT_CONVERT = 30;
export const STATUS_COULDNT_MATCH_IGSID = 32;
export const STATUS_7DAY_WINDOW_CLOSED = 33;
export const STATUS_COMMENT_ANSWERED_DIRECT = 34;
export const STATUS_COMMENT_IS_INVALID = 35;
export const STATUS_COMMENT_ONLY_BE_TEXT = 36;
export const STATUS_OUTGOING_MESSAGE_DELETED = 37;
export const STATUS_YOU_CANNOT_WRITE = 40;
export const STATUS_YOU_BLOCKED_USER = 41;
export const STATUS_CLIENT_DENIED_SENDING_MESSAGES = 42;
export const STATUS_BIG_TEXT = 43;
export const STATUS_WABA_BANNED = 44;
export const STATUS_WABA_EXPERIMENT = 45;
export const STATUS_WABA_RECEIVER_INCAPABLE = 47;
export const STATUS_WABA_MESSAGE_UNDELIVERABLE = 48;
export const STATUS_TELEGRAM_SUPERGROUP_NOT_SUPPORT = 46;
export const STATUS_ERROR = 66;
export const STATUS_INCOMING_DELETED = 97;
export const STATUS_EDITED = 98;
export const STATUS_INCOMING = 99;

export const COLOR_READED = '#00acc1';
export const COLOR_BASE = '#9e9e9e';
export const COLOR_ERROR = '#d32f2f';

export const ICON_SUCCESS = mdiCheck;
export const ICON_SENT = mdiCheckAll;
export const ICON_ERROR = mdiAlertCircleOutline;
export const ICON_CANCEL = mdiCancel;
export const ICON_IMAGE = mdiImageOutline;
export const ICON_AUDIO = mdiMusic;
export const ICON_VIDEO = mdiVideoOutline;
export const ICON_DOC = mdiFileDocumentOutline;
export const ICON_MISSED_CALL = mdiPhoneMissed;
export const ICON_VCARD = mdiAccountOutline;
export const ICON_GEO = mdiMapMarkerOutline;

export const TYPES = {
  [TYPE_TEXT]: {
    icon: null,
    text: ''
  },
  [TYPE_IMAGE]: {
    icon: ICON_IMAGE,
    text: 'chat.contacts.type.image'
  },
  [TYPE_AUDIO]: {
    icon: ICON_AUDIO,
    text: 'chat.contacts.type.audio'
  },
  [TYPE_VIDEO]: {
    icon: ICON_VIDEO,
    text: 'chat.contacts.type.video'
  },
  [TYPE_DOC]: {
    icon: ICON_DOC,
    text: 'chat.contacts.type.document'
  },
  [TYPE_VCARD]: {
    icon: ICON_VCARD,
    text: 'chat.contacts.type.vcard'
  },
  [TYPE_GEO]: {
    icon: ICON_GEO,
    text: 'chat.contacts.type.geo'
  },
  [TYPE_MISS_AUDIO]: {
    icon: ICON_MISSED_CALL,
    text: 'chat.contacts.type.missedCall'
  },
  [TYPE_MISS_VIDEO]: {
    icon: ICON_MISSED_CALL,
    text: 'chat.contacts.type.missedCall'
  }
};

export const STATUSES = {
  [STATUS_DELETED]: {
    icon: ICON_CANCEL,
    color: COLOR_BASE
  },
  [STATUS_NETWORK_PROBLEM]: {
    icon: ICON_ERROR,
    color: COLOR_ERROR
  },
  [STATUS_NOT_SENT]: {
    icon: ICON_SUCCESS,
    color: COLOR_BASE
  },
  [STATUS_SENT]: {
    icon: ICON_SUCCESS,
    color: COLOR_BASE
  },
  [STATUS_DELIVERED]: {
    icon: ICON_SENT,
    color: COLOR_BASE
  },
  [STATUS_READ]: {
    icon: ICON_SENT,
    color: COLOR_READED
  },
  [STATUS_BAD_CONTACT]: {
    icon: ICON_ERROR,
    color: COLOR_ERROR
  },
  [STATUS_LONG_TEXT]: {
    icon: ICON_ERROR,
    color: COLOR_ERROR
  },
  [STATUS_BAD_LINK]: {
    icon: ICON_ERROR,
    color: COLOR_ERROR
  },
  [STATUS_MAX_SIZE]: {
    icon: ICON_ERROR,
    color: COLOR_ERROR
  },
  [STATUS_SPAM]: {
    icon: ICON_ERROR,
    color: COLOR_ERROR
  },
  [STATUS_INTERRUPTED]: {
    icon: ICON_ERROR,
    color: COLOR_ERROR
  },
  [STATUS_NOT_SATISFY_REQUIRMENTS]: {
    icon: ICON_ERROR,
    color: COLOR_ERROR
  },
  [STATUS_CANCELED]: {
    icon: ICON_ERROR,
    color: COLOR_ERROR
  },
  [STATUS_BUSINESS_EXPIRED]: {
    icon: ICON_ERROR,
    color: COLOR_ERROR
  },
  [STATUS_COMMENT_DELETED]: {
    icon: ICON_ERROR,
    color: COLOR_ERROR
  },
  [STATUS_TAG_NOT_ALLOWED]: {
    icon: ICON_ERROR,
    color: COLOR_ERROR
  },
  [STATUS_EMPTY_WABA_BALANCE]: {
    icon: ICON_ERROR,
    color: COLOR_ERROR
  },
  [STATUS_CANT_CONVERT]: {
    icon: ICON_ERROR,
    color: COLOR_ERROR
  },
  [STATUS_ERROR]: {
    icon: ICON_ERROR,
    color: COLOR_ERROR
  },
  [STATUS_INCOMING]: {
    icon: '',
    color: ''
  },
  [STATUS_COULDNT_MATCH_IGSID]: {
    icon: ICON_ERROR,
    color: COLOR_ERROR
  },
  [STATUS_7DAY_WINDOW_CLOSED]: {
    icon: ICON_ERROR,
    color: COLOR_ERROR
  },
  [STATUS_COMMENT_ANSWERED_DIRECT]: {
    icon: ICON_ERROR,
    color: COLOR_ERROR
  },
  [STATUS_COMMENT_IS_INVALID]: {
    icon: ICON_ERROR,
    color: COLOR_ERROR
  },
  [STATUS_COMMENT_ONLY_BE_TEXT]: {
    icon: ICON_ERROR,
    color: COLOR_ERROR
  },
  [STATUS_OUTGOING_MESSAGE_DELETED]: {
    icon: ICON_ERROR,
    color: COLOR_ERROR
  },
  [STATUS_CLIENT_DENIED_SENDING_MESSAGES]: {
    icon: ICON_ERROR,
    color: COLOR_ERROR
  },
  [STATUS_BIG_TEXT]: {
    icon: ICON_ERROR,
    color: COLOR_ERROR
  },
  [STATUS_WABA_BANNED]: {
    icon: ICON_ERROR,
    color: COLOR_ERROR
  },
  [STATUS_WABA_EXPERIMENT]: {
    icon: ICON_ERROR,
    color: COLOR_ERROR
  },
  [STATUS_WABA_RECEIVER_INCAPABLE]: {
    icon: ICON_ERROR,
    color: COLOR_ERROR
  },
  [STATUS_WABA_MESSAGE_UNDELIVERABLE]: {
    icon: ICON_ERROR,
    color: COLOR_ERROR
  },
  [STATUS_TELEGRAM_SUPERGROUP_NOT_SUPPORT]: {
    icon: ICON_ERROR,
    color: COLOR_ERROR
  },
  [STATUS_FB_DAILY_LIMIT_REACH]: {
    icon: ICON_ERROR,
    color: COLOR_ERROR
  },
  [STATUS_YOU_CANNOT_WRITE]: {
    icon: ICON_ERROR,
    color: COLOR_ERROR
  },
  [STATUS_YOU_BLOCKED_USER]: {
    icon: ICON_ERROR,
    color: COLOR_ERROR
  }
};
