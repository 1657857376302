//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mdiMenu } from '@mdi/js';
import { mapGetters } from 'vuex';

const HEADER_HEIGHT_IN_PX = 64;
const MINIMIZED_HEADER_HEIGHT_IN_PX = 56;

export default {
  name: 'AppHeader',

  props: {
    value: {
      type: Boolean
    }
  },

  computed: {
    ...mapGetters('chat', ['chatStubCanShowed']),

    icons() {
      return {
        mdiMenu
      };
    },

    addChat() {
      return this.$route.params.channelId === 'add';
    },

    showAppBarNavIcon() {
      return this.$route.path.includes('chat') && !this.drawer;
    },

    noContacts() {
      return this.$route.name === 'accountId-chat-chatType-chatId-channelId' && this.chatStubCanShowed;
    },

    drawer: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      }
    },

    headerHeight() {
      return this.$vuetify.breakpoint.width < 481 ? MINIMIZED_HEADER_HEIGHT_IN_PX : HEADER_HEIGHT_IN_PX;
    }
  }
};
