import { render, staticRenderFns } from "./SettingsDrawer.vue?vue&type=template&id=c941254c&scoped=true&"
import script from "./SettingsDrawer.vue?vue&type=script&lang=js&"
export * from "./SettingsDrawer.vue?vue&type=script&lang=js&"
import style0 from "./SettingsDrawer.vue?vue&type=style&index=0&id=c941254c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c941254c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SettingsDrawerMainMenu: require('/builds/wazzup/front/components/settings/settingsDrawer/SettingsDrawerMainMenu.vue').default,SettingsDrawerFooterMenu: require('/builds/wazzup/front/components/settings/settingsDrawer/SettingsDrawerFooterMenu.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VAppBarNavIcon,VToolbar})
