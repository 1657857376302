//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import { mdiClose, mdiInformationOutline } from '@mdi/js';
import { deepCopy } from '~/utils/common';

export default {
  name: 'AppGupshupBanner',

  props: {
    channels: {
      type: Array,
      required: true
    }
  },

  computed: {
    ...mapState('auth', ['account']),
    ...mapGetters('auth', ['frontendData']),

    icons() {
      return {
        mdiClose,
        mdiInformationOutline
      };
    },

    text() {
      return this.$t(`common.gupshupBanner.${this.channels.length === 1 ? 'oneChannelText' : 'severalChannelsText'}`);
    },

    sum() {
      const sums = {
        RUR: '1500 ₽',
        KZT: '9000 ₸',
        EUR: '20 €',
        USD: '20 $'
      };
      return sums[this.account.currency];
    },

    channelGuid() {
      return this.channels.length === 1 ? this.channels[0].guid : '';
    }
  },

  methods: {
    ...mapActions('auth', ['getCurrentAccount']),

    async setGupshupBannerClosed() {
      const frontendData = deepCopy(this.frontendData);
      frontendData.gupshupBannerClosed = true;
      await this.$services.user.setFrontendData(frontendData);
      await this.getCurrentAccount();
    }
  }
};
