//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mdiMenuDown } from '@mdi/js';

export default {
  name: 'BaseInputPhone',

  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    countries: {
      type: Array,
      default: () => []
    },
    errorMessages: {
      type: [Array, String],
      default: ''
    },
    autofocus: {
      type: Boolean
    },
    hint: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      form: {
        code: this.value.code,
        iso2: this.value.iso2,
        number: this.value.number
      },
      searchCountry: '',
      menu: false
    };
  },

  computed: {
    icons() {
      return {
        mdiMenuDown
      };
    },

    computedCountries() {
      return this.countries
        .filter((country) => (country.dialCode === '00' ? !this.$config.env.prod : true))
        .map((item, index) => {
          return {
            code: item.dialCode,
            name: item.name,
            key: index
          };
        })
        .filter((item) => {
          const searchCountry = this.searchCountry.replace(/\+/g, '');
          const nameInParentheses = item.name.match(/\((.*)\)/)?.[1] || '';
          return (
            item.code.startsWith(searchCountry) ||
            item.name.toLowerCase().startsWith(searchCountry.toLowerCase()) ||
            nameInParentheses.toLowerCase().startsWith(searchCountry.toLowerCase())
          );
        });
    }
  },

  watch: {
    form: {
      deep: true,
      handler() {
        const country = this.countries.find((country) => country.dialCode === String(this.form.code)) || {
          code: this.form.code,
          iso2: '',
          number: this.form.number
        };
        this.$emit('input', {
          code: String(this.form.code),
          iso2: country.iso2,
          number: this.form.number ? this.form.number.replace(/[^0-9]/g, '') : ''
        });
        this.$refs.phoneNumber.focus();
      }
    },

    value: {
      deep: true,
      handler() {
        this.form.code = this.value.code;
        this.form.number = this.value.number;
      }
    }
  },

  methods: {
    handleKeydown(e) {
      // todo: переделать так, чтобы не было проблем на мобилках
      if (
        e.key &&
        !['ArrowUp', 'ArrowDown', 'ArrowRight', 'ArrowLeft', 'Delete', 'Backspace', 'Home', 'End', 'Enter'].includes(
          e.key
        ) &&
        !/\d/.test(e.key) &&
        !(['KeyZ', 'KeyX', 'KeyC', 'KeyV'].includes(e.code) && (e.ctrlKey || e.metaKey))
      ) {
        e.preventDefault();
      }
    }
  }
};
