export default {
  async getCurrentAccount({ commit }) {
    const account = await this.$services.user.getCurrentAccount();
    commit('SET_CURRENT_ACCOUNT', account);
  },

  async getUsers({ commit }) {
    const account = await this.$services.user.getUsers();
    commit('SET_USERS', account);
  },

  async getCurrentUser({ commit }) {
    const users = await this.$services.user.getCurrentUser();
    commit('SET_CURRENT_USER', users);
  },

  setIFrameToken({ commit }, token) {
    commit('SET_IFRAME_TOKEN', token);
  },

  setXAccountId({ commit }, xAccountId) {
    commit('SET_X_ACCOUNT_ID', xAccountId);
  },

  async managerLogin({ commit }, data) {
    const result = await this.$services.auth.managerLogin(data);
    commit('SET_X_ACCOUNT_ID', result.accountId);
    return result;
  },

  async getIntegrationInfo({ commit, state }) {
    if (!state.iFrameToken) return;
    const result = await this.$services.auth.getIntegrationInfo();
    commit('SET_INTEGRATION_INFO', result.data);
    return result.data;
  },

  setChatErrorState({ commit }, chatErrorState) {
    commit('SET_CHAT_ERROR_STATE', chatErrorState);
  },

  setAccessToken({ commit }, accessToken) {
    commit('SET_ACCESS_TOKEN', accessToken);
  },

  setCabinetInIframe({ commit }, cabinetInIframe) {
    commit('SET_CABINET_IN_IFRAME', cabinetInIframe);
  }
};
