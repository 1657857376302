import { render, staticRenderFns } from "./chat.vue?vue&type=template&id=14ae8b62&"
import script from "./chat.vue?vue&type=script&lang=js&"
export * from "./chat.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ChatHeader: require('/builds/wazzup/front/components/chat/ChatHeader.vue').default,AppHeader: require('/builds/wazzup/front/components/app/AppHeader.vue').default,ChatDrawer: require('/builds/wazzup/front/components/chat/ChatDrawer.vue').default,AppChatDrawer: require('/builds/wazzup/front/components/app/AppChatDrawer.vue').default,AuthNotifyInstallForm: require('/builds/wazzup/front/components/auth/forms/AuthNotifyInstallForm.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VApp,VDialog,VMain})
