import Vue from 'vue';
import BaseCopyModal from '~/components/base/BaseCopyModal';

export default (ctx, inject) => {
  const BaseCopyModalClass = Vue.extend(BaseCopyModal);

  const copyModal = (text) => {
    const appElement = document.querySelector('#app');
    const { i18n } = ctx.app;

    if (appElement) {
      const instance = new BaseCopyModalClass({
        i18n,
        propsData: { text, value: true }
      });
      instance.$vuetify = ctx.$vuetify;
      instance.$mount();
      instance.$on('input', async (v) => {
        instance.$props.value = v;
        if (!v) {
          await instance.$nextTick();
          instance.$destroy();
          appElement.removeChild(instance.$el);
        }
      });
      appElement.appendChild(instance.$el);
    }
  };

  inject('copyModal', copyModal);
};
