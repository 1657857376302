//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BaseIntegratorFrame',

  props: {
    title: {
      type: String,
      default: ''
    }
  }
};
