export default class BaseService {
  constructor({ $api, $apiV2, $apiAuth, $apiStore, $apiTechPartner, $cookies, $config, route }) {
    this.$api = $api;
    this.$apiV2 = $apiV2;
    this.$apiAuth = $apiAuth;
    this.$apiStore = $apiStore;
    this.$apiTechPartner = $apiTechPartner;
    this.$cookies = $cookies;
    this.$config = $config;
    this.route = route;
  }
}
