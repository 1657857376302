import { render, staticRenderFns } from "./DrawerContactList.vue?vue&type=template&id=4c5ffc36&scoped=true&"
import script from "./DrawerContactList.vue?vue&type=script&lang=js&"
export * from "./DrawerContactList.vue?vue&type=script&lang=js&"
import style0 from "./DrawerContactList.vue?vue&type=style&index=0&id=4c5ffc36&lang=scss&scoped=true&"
import style1 from "./DrawerContactList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c5ffc36",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DrawerContactItem: require('/builds/wazzup/front/components/chat/chatDrawer/DrawerContactItem.vue').default,BaseInfinityLoader: require('/builds/wazzup/front/components/base/BaseInfinityLoader.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VList } from 'vuetify/lib/components/VList';
installComponents(component, {VList})
