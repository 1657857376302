import { mapState } from 'vuex';
import { channelTypes, channelStatuses } from '~/enums/channels';

export default {
  computed: {
    ...mapState('auth', ['account']),
    ...mapState('channels', ['channels']),
    ...mapState('chat', ['messages', 'currentChat', 'messagesLoaded', 'wabaTemplates']),

    showWAPITemplates() {
      if (this.channels && !this.channels.length) return false;
      if (!this.currentChat.channelId || this.currentChat.channelId === 'add' || !this.messagesLoaded) return false;
      const lastMessageTime = this.messages.find((message) => message.incoming)?.datetime || 0;
      const isPass24hourFromLastIncoming = lastMessageTime ? Date.now() - lastMessageTime > 24 * 60 * 60 * 1000 : true;

      return this.wabaChat && isPass24hourFromLastIncoming;
    },

    wabaChat() {
      const transport = this.channels.find((channel) => channel.guid === this.currentChat.channelId)?.transport;
      return transport === channelTypes.wapi;
    },

    trickyTemplates() {
      if (!this.wabaTemplates.length) return [];

      return this.wabaTemplates
        .filter(({ isTrickyTemplate }) => isTrickyTemplate)
        .sort((a, b) => b.trickyTemplatesPriority - a.trickyTemplatesPriority);
    },

    keyReplyChannel() {
      const channel = this.channels.find((channel) => channel.guid === this.currentChat.channelId);
      return Boolean(!channel?.details?.isGupshupWaba && channel.transport === channelTypes.wapi);
    },

    wabaChannelBanned() {
      const channel = this.channels.find((channel) => channel.guid === this.currentChat.channelId);
      return channel?.state === channelStatuses.rejected;
    }
  }
};
