//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState, mapGetters } from 'vuex';
import dayjs from 'dayjs';
import { channelTypes } from '~/enums/channels';
import showWAPITemplates from '~/mixins/showWAPITemplates';
import { deepEqual } from '~/utils/common';
import * as MESSAGES from '~/enums/messages';

export default {
  name: 'LayoutChat',

  mixins: [showWAPITemplates],

  middleware: 'auth',

  data() {
    return {
      drawer: false,
      deferredPrompt: null,
      isNotifyIntsallShow: false
    };
  },

  head() {
    return {
      htmlAttrs: {
        lang: this.account?.lang || 'ru',
        translate: 'no'
      },
      meta: [
        { hid: 'viewport', name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'language', content: this.langCode },
        { 'http-equiv': 'content-language', content: this.langCode }
      ]
    };
  },

  computed: {
    ...mapState('channels', ['accessibleChannels']),
    ...mapState('chat', ['contacts', 'currentChat']),
    ...mapState('auth', ['account', 'iFrameToken', 'chatErrorState', 'cabinetInIframe']),
    ...mapState('integrations', ['integrationInfo']),
    ...mapGetters('auth', ['langCode', 'decodedIFrameToken'])
  },

  watch: {
    '$route.params': {
      deep: true,
      handler() {
        if (this.iFrameToken && !this.$route.query.token) {
          this.$router.push(`${this.$route.path}?token=${this.iFrameToken}`);
        }

        const { chatType, chatId, channelId } = this.$route.params;
        if (
          chatType === this.currentChat.chatType &&
          chatId === this.currentChat.chatId &&
          channelId === this.currentChat.channelId
        ) {
          return;
        }
        this.clearMessages();
        if (chatType && chatId && !this.chatErrorState) {
          this.setChat(chatType, chatId, channelId);
        } else {
          this.setCurrentChat({});
        }
      },
      immediate: true
    },

    deferredPrompt(newValue, oldValue) {
      if (!deepEqual(newValue, oldValue)) {
        const storageDate = localStorage.getItem('pwaNotifyInstall');
        this.isNotifyIntsallShow = storageDate ? parseInt(dayjs(storageDate).fromNow()) >= 7 : true;
      }
    }
  },

  async mounted() {
    setTimeout(() => {
      this.$nuxt.$emit('go-to-active-chat-contact');
    }, 1000);
    if (this.cabinetInIframe) {
      const goToGlobalChat = async () => {
        try {
          const link = await this.$services.integrations.getGlobalChatUrl();
          window.location.href = link.url;
        } catch (err) {
          console.log(err);
        }
      };
      if (this.integrationInfo) {
        goToGlobalChat();
      } else {
        await this.getIntegration();
        if (this.integrationInfo) {
          goToGlobalChat();
        }
      }
    }
    if (!this.$vuetify.breakpoint.width <= 1052) this.drawer = true;
    try {
      await Promise.all([this.getWazzupTemplates(), this.getWazzupTags(), this.getWabaTags()]);
    } catch (err) {
      console.log(err);
    }
    if (this.$config.m) {
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault();
        this.deferredPrompt = e;
      });

      if (!this.chatErrorState) {
        try {
          const data = await this.$services.mobile.getUnansweredCount();
          const {
            unanswered: { type, counterV2 }
          } = data;
          const counter = type === 'red' ? counterV2 : 0;
          this.$notificationsCountBadge.setNotificationCount(counter);
        } catch (err) {
          console.log(err);
        }
      }
      this.$socket.on('counterUpdate', (data) => {
        const counterData = data.data.counter;
        const { type, counterV2 } = counterData;
        const counter = type === 'red' ? counterV2 : 0;
        this.$notificationsCountBadge.setNotificationCount(counter);
      });
    }

    this.$socket.on('updateTemplateTags', async () => {
      try {
        await Promise.all([this.getWazzupTemplates(), this.getWazzupTags(), this.getWabaTags()]);
      } catch (err) {
        console.log(err);
      }
    });

    this.$socket.on('updateMessage', (data) => {
      this.updateMessageStatus(data);
    });

    this.$socket.on('clearUnanswered', (data) => {
      this.clearUnanswered(data);
    });

    this.$socket.on('allUnansweredClear', () => {
      this.clearAllUnanswered();
      if (this.$config.m) {
        this.$notificationsCountBadge.clearNotificationCount();
      }
    });

    this.$socket.on('outputMessage', (data) => {
      this.setMessage(data);
    });

    this.$socket.on('inputMessage', (data) => {
      this.setMessage(data);

      if (this.$config.m && Notification.permission === 'granted') {
        const userId = this.decodedIFrameToken?.integration?.userId;
        const canShowNotification = userId && Array.isArray(data.pUsers) ? data.pUsers.includes(userId) : true;

        if (canShowNotification) {
          const { message, contactName, channelName, avatar, previewText } = data.data;
          const { chatType, chatId, channelId, type, text, filename, id } = message;
          const icon = `${this.$config.storeBaseUrl}/${avatar}`;
          const url = this.$userPath(`/chat/${chatType}/${chatId}/${channelId}`);
          const title = type === MESSAGES.TYPE_NEWS ? contactName : `${contactName} — ${channelName}`;
          const body =
            type === MESSAGES.TYPE_NEWS ? previewText : type === MESSAGES.TYPE_IMAGE && !text.length ? filename : text;
          this.$showNotification(title, body, icon, url, id);
        }
      }
    });

    this.$socket.on('chatAccessGranted', (data) => {
      this.chatAccessGranted(data);
    });

    this.$socket.on('chatAccessDenied', (data) => {
      this.chatAccessDenied(data);
    });

    this.$socket.on('sttSuccess', (data) => {
      this.setSttEvent(data);
    });

    this.$socket.on('sttFailed', (data) => {
      this.setSttEvent(data);
    });
  },

  methods: {
    ...mapActions('chat', [
      'getWazzupTemplates',
      'setCurrentChat',
      'getContact',
      'clearMessages',
      'getBalanceByChannel',
      'updateMessageStatus',
      'clearUnanswered',
      'clearAllUnanswered',
      'setMessage',
      'chatAccessGranted',
      'chatAccessDenied',
      'setSttEvent'
    ]),
    ...mapActions('templateTags', ['getWazzupTags', 'getWabaTags']),
    ...mapActions('integrations', ['getIntegration']),

    getBalance() {
      if (this.currentChat.channelId === 'add' || !this.currentChat.channelId) return;
      const channel = this.accessibleChannels.find((channel) => channel.guid === this.currentChat.channelId) || null;
      if (channel?.transport === channelTypes.wapi && channel?.details?.subscription?.guid) {
        this.getBalanceByChannel(this.currentChat.channelId);
      }
    },

    async setChat(chatType, chatId, channelId) {
      this.$nuxt.$emit('before-chat-change');
      this.setCurrentChat({});
      await this.$nextTick();
      const channelName = this.accessibleChannels.find((channel) => channel.guid === channelId)?.name || null;
      let contact = this.contacts.find((contact) => contact.chatType === chatType && contact.chatId === chatId);
      let result;
      let chat = contact ? contact.chats.find((chat) => chat.channelId === channelId) : null;
      if (!contact || (contact.virtual && channelId !== 'add') || (!contact.virtual && !chat)) {
        try {
          result = await this.getContact({ chatType, chatId });
          if (!result) {
            const firstContact = this.contacts[0];
            if (firstContact && firstContact.chats?.[0]) {
              this.$router.push(
                this.$userPath(
                  `/chat/${firstContact.chatType}/${firstContact.chatId}/${firstContact.chats[0].channelId}`
                )
              );
            } else if (firstContact) {
              this.$router.push(this.$userPath(`/chat/${firstContact.chatType}/${firstContact.chatId}/add`));
            } else {
              this.$router.push(this.$userPath('/chat'));
            }

            return;
          }
          contact = this.contacts.find((contact) => contact.chatType === chatType && contact.chatId === chatId);
        } catch (err) {
          console.log(err);
          return this.$notify(this.$t('errors.chatCanNotOpen'), { type: 'error' });
        }
      }
      if (!contact) {
        return this.$notify(this.$t('chat.errors.cannotGetContact'), { type: 'error' });
      }
      if (!contact.chats || !Array.isArray(contact.chats)) {
        return this.$notify(this.$t('errors.chatCanNotOpen'), { type: 'error' });
      }
      chat = contact.chats.find((chat) => chat.channelId === channelId);
      const currentChat = {
        channelId: channelId || null,
        channelName: channelName || chat?.name || this.$t('chat.unknownChannel'),
        chatId,
        userName: contact?.userName || '',
        userPhone: contact?.userPhone || '',
        chatType,
        chats: contact?.chats,
        contactName: contact?.contactName || '',
        unanswered: +chat?.unanswered || 0,
        add: channelId === 'add',
        readOnly: !!chat?.readonly,
        isChannelDeleted: !!chat?.isChannelDeleted,
        advert: chat?.advert,
        isPrivate: chat?.isPrivate,
        virtual: contact?.virtual
      };
      this.setCurrentChat(currentChat);
      // проверка баланса для вапи чатов
      this.getBalance();
    },

    cancel() {
      this.isNotifyIntsallShow = false;
      localStorage.setItem('pwaNotifyInstall', dayjs());
    },

    async confirm() {
      this.isNotifyIntsallShow = false;
      const { userChoice } = await this.deferredPrompt.prompt();
      if (!userChoice) {
        localStorage.setItem('pwaNotifyInstall', dayjs());
      }
    }
  }
};
