//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BaseCopyModal',

  props: {
    value: Boolean,
    text: {
      type: String,
      required: true
    }
  },

  computed: {
    dialog: {
      set(v) {
        this.$emit('input', v);
      },
      get() {
        return this.value;
      }
    }
  }
};
