//
//
//
//
//
//
//

import { mdiOpenInNew } from '@mdi/js';

export default {
  name: 'BaseInfoLink',

  props: {
    href: {
      type: String,
      default: ''
    }
  },

  computed: {
    icons() {
      return {
        mdiOpenInNew
      };
    }
  }
};
